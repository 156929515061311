/* eslint-disable */
import dom from './dom-helper.js';

export default function (base, selectorClass) {
  selectorClass = selectorClass || 'hl';
  return {
    annotateChildren(el, key, hl) {
      const className = `${selectorClass} ${selectorClass}-${key.id} ${key.namespace} ${key.type}`;
      if (el.nodeType == 3) {
        el = dom.wrap(el);
        el.className = className;
        el.dataset.hlKey = key.id;
        if (hl?.created_at) {
          el.dataset.hlDatetime = hl.created_at;
        }
        if (hl?.type) {
          el.dataset.hlContentType = hl.type;
        }
        if (hl?.key) {
          el.dataset.hlContentId = hl.key;
        }
        return el;
      }
      if (el.nodeType !== 1) {
        return el;
      }
      if (el.classList.contains(key.namespace)) {
        el.className = className;
        while (true) {
          const elements = el.getElementsByClassName(key.namespace);
          if (elements.length === 0) {
            break;
          }
          dom.unwrap(elements[0]);
        }
        return el;
      }
      for (let i = 0; i < el.childNodes.length; i++) {
        this.annotateChildren(el.childNodes[i], key, hl);
      }
      return el;
    },
    annotate(r, key, hl) {
      if (r.startContainer == r.endContainer && r.startOffset == r.endOffset) {
        return;
      }

      // When dragging outside of the highlight container
      if (
        r.startContainer !== r.endContainer &&
        (r.startContainer == r.commonAncestorContainer || r.endContainer == r.commonAncestorContainer)
      ) {
        return;
      }

      // make a copy because sometimes selection offset
      // changes during copy/paste phase
      const startOffset = r.startOffset;
      const endOffset = r.endOffset;

      const start = r.startContainer;
      const end = r.endContainer;
      let startRoot = start;
      let current = start;

      // find possition
      while (current != r.commonAncestorContainer) {
        startRoot = current;
        current = current.parentElement;
      }

      // Highlight middle if highlight is on the same element
      if (start == end) {
        const el = dom.split(start, startOffset, endOffset);
        return this.annotateChildren(el, key, hl);
      }

      // more complicated version when highilghting spans between
      // different elements

      let newStart = dom.split(start, startOffset);
      newStart = this.annotateChildren(newStart, key, hl);

      let el = newStart;
      if (!el || el === null || el.data == '') {
        return null;
      }

      while (true) {
        // find first next parent
        while (true) {
          if (!el.nextSibling) {
            el = el.parentElement;
            if (el === r.commonAncestorContainer) {
              el = null;
              break;
            }
          } else {
            el = el.nextSibling;
            break;
          }
        }

        if (!el) {
          break;
        }

        if (el.parentElement == r.commonAncestorContainer) {
          break;
        }

        el = this.annotateChildren(el, key, hl);
      }

      el = dom.split(end, 0, endOffset);
      if (!el || el === null || el.data == '') {
        return null;
      }
      let newEnd = null;
      // if end offset is 0 that means that current element
      // is not actually selectet, but all previous elements
      // so we treat it as already selected
      if (endOffset > 0) {
        el = this.annotateChildren(el, key, hl);
        newEnd = el;
      }

      while (true) {
        if (!el.previousSibling) {
          el = el.parentElement;
          continue;
        }
        el = el.previousSibling;

        if (el == r.commonAncestorContainer || el == newStart || el == startRoot) {
          break;
        }
        el = this.annotateChildren(el, key, hl);
        // Mark first annoteted element (last on the page)
        // as new end element
        newEnd = newEnd || el;
      }
      return newEnd;
    },
    removeAnnotation(key) {
      const elements = document.getElementsByClassName(`${selectorClass}-${key.id}`);
      for (let i = 0; i < elements.length; i++) {
        if (dom.hasChild(base, elements[i])) {
          dom.unwrap(elements[i]);
        }
      }
    },
    inRange(element) {
      return dom.hasChild(base, element);
    },
  };
}
