import Component from './component.vue';
import eventBus from './eventBus.js';

const JwToast = (Vue, globalOptions = {}) => {
  return {
    open(option) {
      const propsData = { ...option, ...globalOptions };
      return new (Vue.extend(Component))({
        el: document.createElement('div'),
        propsData,
      });
    },

    success(message, options = {}) {
      this.open({ message, type: 'success', ...options });
    },

    warning(message, options = {}) {
      this.open({ message, type: 'warning', ...options });
    },

    error(message, options = {}) {
      this.open({ message, type: 'error', ...options });
    },

    info(message, options = {}) {
      this.open({ message, type: 'info', ...options });
    },

    clear() {
      eventBus.$emit('toast-clear');
    },
  };
};

export default JwToast;
