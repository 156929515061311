import types from './types';

export default {
  async fetchClassById({ commit }, classId) {
    const { data } = await this.$api.classes.fetchClassById(classId);
    commit(types.SET_CLASS, data);
    return data;
  },

  async fetchExtensionOptions(_, classId) {
    const { data } = await this.$api.classes.fetchExtensionOptions(classId);
    return data;
  },

  async updateDefaultPaymentMethod(_, paymentMethod) {
    try {
      await this.$api.classes.updateDefaultPaymentMethod(paymentMethod);
    } catch (error) {}
  },

  async postEnroll(_, params) {
    try {
      await this.$api.classes.postEnroll(params);
    } catch (error) {}
  },

  async enrollToClass({ commit, dispatch }, { classId, purchaseType }) {
    try {
      const res = await this.$api.classes.enrollToClass(classId, purchaseType);
      if (res) {
        commit(types.SET_PAYMENT_INTENT, res.payment_intent);
        commit(types.SET_PAYMENT_INTENT_SECRET, res.secret);
      }
      dispatch('classes/fetchClassById', classId);
      return res;
    } catch (error) {
      return error?.response?.data?.payment_intent;
    }
  },

  async cancelClass({ dispatch }, classId) {
    try {
      await this.$api.classes.cancelClass(classId);
      dispatch('student/fetchStudentCourses', {}, { root: true });
    } catch (error) {}
  },

  switchPaymentMethod({ commit }, type) {
    commit(types.SET_PAYMENT_METHOD, type);
  },

  setStudentEnrolled({ commit }, enrolled) {
    commit(types.SET_STUDENT_ENROLLED, enrolled);
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
};
