import { randomFixedInteger } from '../utils/numbers';

export class BaseEntity {
  Class;
  id;
  created_at;
  updated_at;
  deleted_at;

  constructor(json) {
    this.id = json.id || randomFixedInteger();
    this.created_at = json.created_at ?? new Date().toISOString();
    this.updated_at = json.updated_at ?? new Date().toISOString();
    this.deleted_at = json.deleted_at;
  }
}
