import { BaseEntity } from '../base.entity';

export const CardModelType = {
  BASIC: '0',
  CLOZE: '1',
};

export class CardNoteEntity extends BaseEntity {
  Class = 'CardNote';
  user_id;
  deck_id;
  sort_field;
  fields;
  card_model;

  constructor(json) {
    super(json);
    this.user_id = json.user_id ?? 0;
    this.deck_id = json.deck_id ?? 0;
    this.sort_field = json.sort_field ?? '';
    this.fields = json.fields ?? [];
    this.card_model = json.card_model ? new CardModelEntity(json.card_model) : undefined;
  }
}

export class CardEntity extends BaseEntity {
  Class;
  deck_id;
  difficulty;
  is_copyable;
  card_note;
  is_flagged;
  is_suspended;
  learn_more;
  model_tmpl_ord;
  publish_status;
  tags;
  user_id;
  is_blocked;

  constructor(json) {
    super(json);
    this.deck_id = json.deck_id ?? 0;
    this.difficulty = json.difficulty ?? 'unseen';
    this.is_copyable = json.is_copyable ?? 'copyable';
    this.card_note = new CardNoteEntity(json.card_note ?? {});
    this.is_flagged = json.is_flagged ?? false;
    this.is_suspended = json.is_suspended ?? false;
    this.learn_more = this.learn_more ?? undefined;
    this.model_tmpl_ord = json.model_tmpl_ord ?? 0;
    this.publish_status = json.publish_status ?? 'active';
    this.tags = json.tags ?? [];
    this.user_id = json.user_id ?? 0;
    this.is_blocked = json.is_blocked ?? false;
  }
}

export class CardModelEntity extends BaseEntity {
  Class = 'CardModel';
  name;
  user_id;
  deck_id;
  title;
  css;
  type_name;
  sort_field;
  note_count;
  type;
  fields;
  tmpls;

  constructor(json) {
    super(json);
    this.name = json.name ?? '';
    this.title = json.title ?? '';
    this.user_id = json.user_id ?? 0;
    this.deck_id = json.deck_id ?? 0;
    this.css = json.css ?? '';
    this.type_name = json.type_name ?? '';
    this.sort_field = json.sort_field ?? 0;
    this.note_count = json.note_count ?? 0;
    this.type = json.type ?? CardModelType.BASIC;
    this.fields = json.fields ?? [];
    this.tmpls = json.tmpls ?? [];
  }
}
