const BASE_API_PATH = '/api/v1/caap/dashboard';

export default ($axios) => ({
  fetchCourseSubjects(courseId) {
    return $axios.get(`/api/v1/caap/dashboard/courses/${courseId}/subjects`);
  },
  fetchCourseSessions(courseId) {
    return $axios.get(`/api/v1/caap/dashboard/courses/${courseId}/sessions`);
  },

  fetchLandingData() {
    return $axios.get(`${BASE_API_PATH}/landing`);
  },
});
