
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: { CookieBannerWrapper },

  computed: {
    isLearnPage() {
      const routeName = this.$route?.name;
      return routeName === 'courses-slug-learn' || routeName === 'programs-slug-learn';
    },
  },
};
