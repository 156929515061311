
export default {
  props: {
    error: {
      type: [String, null],
      default: null,
    },
    value: {
      type: [String, Boolean, Number, Object, Array, null],
      default: null,
    },
  },
  watch: {
    value() {
      if (this.error) {
        this.$emit('change');
      }
    },
  },
};
