/* eslint-disable no-console */
export default (context, inject) => {
  const { $config } = context;
  const log = (...args) => {
    if ($config.NODE_ENV !== 'production') {
      console.log('** DEVELOPMENT LOG **');
      console.log(...args);
    }
  };
  inject('log', log);
};
