
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: { CookieBannerWrapper },

  layout({ store }) {
    if (store.getters['application/isPWAMode']) {
      return 'empty';
    }
    return 'no-sidebar-full-width';
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
};
