export default ($axios) => ({
  fetchTierById(tierId) {
    return $axios.get(`/api/caap/tiers/${tierId}`);
  },

  updateDefaultPaymentMethod(payment_method) {
    return $axios.put('/api/caap/user/card', { payment_method });
  },

  postEnroll(params) {
    return $axios.post(`/api/caap/tiers/${params.tierId}/enrollments`, {
      is_installment: params.is_installment,
      payment_method: params.payment_method,
    });
  },

  enrollToTier(tierId, purchaseType) {
    return $axios.post(`/api/caap/tiers/${tierId}/charge-saved-card`, { purchase_type: purchaseType });
  },

  extendTier(tierId, paymentMethod) {
    return $axios.post(`/api/caap/tiers/${tierId}/extend`, {
      payment_method: paymentMethod,
    });
  },

  cancelTier(tierId) {
    return $axios.post(`api/caap/tiers/${tierId}/cancel-extension`);
  },
});
