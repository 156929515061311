import moment from 'moment';

/**
 *
 * @param {Array} lessons
 * @returns sum of lessons duration in miliseconds
 */
export const durationSum = (lessons) => {
  const sum = lessons.reduce((total, currentValue) => {
    return total + moment.duration(currentValue.duration);
  }, 0);
  return moment.duration(sum).asHours();
};

/**
 *
 * @param {Number} lessonsDuration
 * @param {Number} timePerWeek
 * @param {String} deadlineDate
 * @returns boolean
 */
export const isMandatoryDurationLessThanSelectedDuration = ({
  lessonsDuration,
  timePerWeek,
  deadlineDate,
  isStudyPlanEnabled = false,
}) => {
  // 100hrs > 40 per weeks * 3 weeks
  const now = moment();
  const deadline = moment(deadlineDate);
  const weeks = deadline.diff(now, 'week');
  const hourPerDay = timePerWeek / 7;

  if (isStudyPlanEnabled) {
    const remainingDaysToDeadline = deadline.diff(now, 'day');
    const hoursThatUserCanStudy = Math.floor(remainingDaysToDeadline * hourPerDay);
    return lessonsDuration - hoursThatUserCanStudy <= 4;
  } else {
    return lessonsDuration <= timePerWeek * weeks;
  }
};

/**
 *
 * @param {Number} lessonsDuration
 * @param {String} deadlineDate
 * @returns required hours/week to finish the lessons
 */

export const hoursPerWeekToFinishLessons = ({ lessonsDuration, deadlineDate }) => {
  const now = moment();
  const deadline = moment(deadlineDate);
  const weeks = deadline.diff(now, 'week');
  const result = Math.ceil(lessonsDuration / weeks);
  return isFinite(result) ? result : 0;
};

/**
 *
 * @param {started date student sets for the study plan} startedDate
 * @returns
 */
export const getNextWeekOfStudyPlan = (studyStartedAt) => {
  const startedDate = moment(studyStartedAt);
  const startedDateDayOfWeek = startedDate.isoWeekday();
  // get day of the week of the study started at
  const dayINeed = moment().local().isoWeekday(startedDateDayOfWeek);
  // if started date day is before current week then add 1 week to date
  if (moment().local().isSameOrAfter(dayINeed)) {
    return moment().local().add(1, 'weeks').isoWeekday(startedDateDayOfWeek).add(-1, 'days');
  }
  return dayINeed.add(-1, 'days');
};
