export default {
  isInstructor: (_state, _commit, rootState) => rootState.auth.user?.is_instructor ?? false,
  isAdmin: (_state, _commit, rootState) => rootState.auth.user?.is_admin ?? false,
  isLoginRequired: (state) => state.isLoginRequired,
  userMeta: (state) => state.meta,
  metaValue: (state) => (meta) => state.meta[meta],
  canUserPublicDeck: (_state, _commit, rootState) => rootState.auth.user?.can_public_deck ?? false,
  trustedSites: (state) => state.trustedSites,
  cookieConfiguration: (state) => state.cookieConfiguration,
  hasCookieConfigurationInitialized: (state) => {
    const { accessCookie, defaultCookie } = state.cookieConfiguration;
    return accessCookie !== null && defaultCookie !== null;
  },
};
