export default class ExamService {
  constructor(context) {
    this.context = context;
  }

  convertServerExtendTimeRationToNumber(exam) {
    if (exam?.status === 'not_started') {
      return 1;
    }
    if (!exam?.with_time) {
      return Infinity;
    }
    const times = {
      with_time_1: exam?.with_time_1,
      with_time_1_25: exam?.with_time_1_25,
      with_time_1_5: exam?.with_time_1_5,
      with_time_2: exam?.with_time_2,
    };
    const activeTime = Object.keys(times).find((key) => times[key]);
    const activeTimeRatioString = activeTime.match(/with_time_(.*)/)[1];
    const activeTimeRatioNumber = Number(activeTimeRatioString.replace('_', '.'));
    return activeTimeRatioNumber;
  }

  calculateSecondsBasedOnExtendRatio(seconds) {
    const extendRatio = this.context.store.getters['exam/extendTimeRatio'];
    const hasExamNotStarted = this.context.store.getters['exam/status'].is.notStarted;

    if (hasExamNotStarted) {
      return Math.floor(seconds * extendRatio);
    }

    return seconds;
  }

  calculateQuestionState(question) {
    const hasNoAnswer = question?.options?.every((answer) => !answer.value);
    if (hasNoAnswer) {
      return;
    }

    if (question.selected_answer) {
      return 'Complete';
    }

    return question.seen ? 'Incomplete' : 'Unseen';
  }
}
