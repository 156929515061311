import { BaseEntity } from '../base.entity';

export class DeckEntity extends BaseEntity {
  Class = 'Deck';
  title;
  slug;
  description;
  photo_url;
  user_id;
  access_status;
  is_unlisted;
  publish_status;
  is_copyable;
  total_tags;
  total_cards;
  active_cards;
  default_daily_goal;
  rapid_rate_percentage;
  status;
  daily_goal;
  insertion_type;
  learning_rate;
  new_cards_count;
  learning_cards_count;
  due_cards_count;
  studied_cards;
  study_progress;
  owner;
  has_study_started;
  role;
  permissions;
  is_blocked;

  constructor(json) {
    super(json);
    this.title = json.title ?? '';
    this.slug = json.slug ?? '';
    this.description = json.description ?? '';
    this.photo_url = json.photo_url ?? '';
    this.user_id = json.user_id ?? 0;
    this.access_status = json.access_status ?? 'public';
    this.is_unlisted = json.is_unlisted ?? false;
    this.publish_status = json.publish_status ?? 'draft';
    this.is_copyable = json.is_copyable ?? 'normal';
    this.total_tags = json.total_tags ?? 0;
    this.total_cards = json.total_cards ?? 0;
    this.active_cards = json.active_cards ?? 0;
    this.default_daily_goal = json.default_daily_goal ?? 0;
    this.rapid_rate_percentage = json.rapid_rate_percentage ?? 0;
    this.status = json.status ?? 'in_progress';
    this.daily_goal = json.daily_goal ?? 0;
    this.insertion_type = json.insertion_type ?? 'normal';
    this.learning_rate = json.learning_rate ?? 'normal';
    this.new_cards_count = json.new_cards_count ?? 0;
    this.learning_cards_count = json.learning_cards_count ?? 0;
    this.studied_cards = json.studied_cards ?? 0;
    this.due_cards_count = json.due_cards_count ?? 0;
    this.study_progress = json.study_progress ?? 0;
    this.owner = json.owner ?? {
      id: 0,
      is_verified: false,
      name: '',
      photo_url: '',
    };
    this.has_study_started = json.has_study_started ?? false;
    this.role = json.role ?? '';
    this.permissions = json.permissions ?? [];
    this.is_blocked = json.is_blocked ?? false;
  }

  get hasUserTeamAccess() {
    return this.permissions.includes('flashcard.add.card');
  }

  get totalCardsBasedOnPermission() {
    const { permissions, total_cards: totalCards, active_cards: activeCards } = this;
    if (permissions.includes('flashcard.add.card')) {
      return totalCards || 0;
    }
    return activeCards || 0;
  }
}
