
export default {
  data() {
    return {
      navs: [
        {
          title: 'Home',
          to: '/',
          icon: 'icon-home',
          gtm_event: 'home__navbar__navigation',
        },
        {
          title: 'Courses',
          to: '/courses',
          icon: 'icon-classes',
          gtm_event: 'courses__navbar__navigation',
        },
        {
          title: 'Live Sessions',
          to: '/sessions',
          icon: 'icon-sessions',
          gtm_event: 'live_sessions__navbar__navigation',
        },
        {
          title: 'Tutoring',
          to: '/services/live-online-mcat-tutoring',
          icon: 'icon-tutoring',
          gtm_event: 'tutoring__navbar__navigation',
        },
        {
          title: 'Admissions',
          to: '/services/medical-school-admissions-consulting',
          icon: 'icon-admissions',
          gtm_event: 'admission__navbar__navigation',
        },
      ],
    };
  },
};
