const API_V3 = '/api/v3';
const BASE_EXAM_API_PATH = `${API_V3}/daily/exams`;

export default class ExamAPI {
  constructor(axios) {
    this.$axios = axios;
  }

  fetchExams() {
    return this.$axios.get(`${BASE_EXAM_API_PATH}`);
  }

  fetchExam(slug) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${slug}`);
  }

  fetchQuizHistory(testId) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${testId}/status`);
  }

  fetchStatisticReports(id, params = {}) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${id}/statistics`, { params });
  }

  fetchSections(id) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${id}/sections`);
  }

  startTest(id, payload) {
    return this.$axios.post(`${BASE_EXAM_API_PATH}/${id}`, payload);
  }

  fetchSectionDetailStatistic(examId, testId, sectionId) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${examId}/statistics/${testId}/sections/${sectionId}`);
  }

  fetchQuestionsStatistic(id, testId, sectionId, params = {}) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${id}/statistics/${testId}/sections/${sectionId}/questions`, {
      params,
    });
  }

  fetchSectionsStatistics(id, testId) {
    return this.$axios.get(`${BASE_EXAM_API_PATH}/${id}/statistics/${testId}`);
  }

  fetchHighlights(highlighParams) {
    return this.$axios.get(
      `${BASE_EXAM_API_PATH}/${highlighParams.examId}/statistics/${highlighParams.testId}/sections/${highlighParams.sectionId}/highlights`,
    );
  }

  resetTest(testId) {
    return this.$axios.post(`/api/v3/daily/tests/${testId}/reset`);
  }

  fetchExamsByField(fieldId) {
    return this.$axios.get(`/api/v1/daily/exams/fields/${fieldId}`);
  }
}
