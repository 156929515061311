
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('ui', ['sidebar']),
  },

  methods: {
    ...mapActions('ui', ['toggleSidebar']),
  },
};
