export default {
  SET_PURCHASES: 'SET_PURCHASES',
  SET_STUDENT_COURSES: 'SET_STUDENT_COURSES',
  SET_STUDENT_COURSE: 'SET_STUDENT_COURSE',
  SET_STUDENT_ENROLLMENTS: 'SET_STUDENT_ENROLLMENTS',
  SET_STUDENT_ENROLLMENTS_CLASS: 'SET_STUDENT_ENROLLMENTS_CLASS',
  SET_UPCOMING_INVOICE: 'SET_UPCOMING_INVOICE',
  SET_STUDENT_CARDS: 'SET_STUDENT_CARDS',
  SET_EXTENSIONS: 'SET_EXTENSIONS',
  SET_USER_WAITING_FOR_NEXT_SESSION: 'SET_USER_WAITING_FOR_NEXT_SESSION',
  SET_PAYMENT_DETAIL: 'SET_PAYMENT_DETAIL',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_HAS_STUDENT_EXTENDED_COURSE: 'SET_HAS_STUDENT_EXTENDED_COURSE',
  SET_STUDENT_TIERS: 'SET_STUDENT_TIERS',
  SET_STUDENT_TIER: 'SET_STUDENT_TIER',
  SET_TIER_CLASS_ENROLLMENTS: 'SET_TIER_CLASS_ENROLLMENTS',
  CHANGE_THANKYOU_PAGE_STATUS: 'CHANGE_THANKYOU_PAGE_STATUS',
  ENABLED_DISCOUNT_CODE: 'ENABLED_DISCOUNT_CODE',
};
