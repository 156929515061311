
export default {
  name: 'Icon',
  props: {
    icon: {
      type: Array,
      default: () => ['fas'],
    },
    size: {
      type: [String, Number],
      default: '1x',
    },
  },
};
