import PassageEntity from '~/core/entities/qbank-quiz/passage.entity';
import QuestionEntity from '~/core/entities/qbank-quiz/question.entity';
import SectionEntity from '~/core/entities/qbank-quiz/section.entity';

export default class QuizInterceptor {
  constructor(quiz) {
    this.quiz = quiz;
    this.modifyDefaultSections();
    this.modifyDefaultQuestions();
    this.modifyDefaultPassages();
  }

  modifyDefaultSections() {
    // sort sections by order
    const orderedSections = [...this.quiz.sections].sort((a, b) => a.order - b.order);

    // Initial section component based on quiz type and section type
    const quizType = this.quiz.test.test_type.toLowerCase();
    this.quiz.sections = orderedSections.map((section) => {
      const sectionType = section.type.toLowerCase();
      return new SectionEntity({
        ...section,
        component: `quiz-${quizType}-${sectionType}-section`,
      });
    });
  }

  modifyDefaultQuestions() {
    // Initial component property of questions that are come from server based on quiz type
    const quizType = this.quiz.test.test_type.toLowerCase();
    this.quiz.questions = (this.quiz.questions ?? []).map((question, index) => {
      return new QuestionEntity({
        ...question,
        received_from_server: true,
        number: index + 1,
        component: `quiz-${quizType}-question-step`,
      });
    });
  }

  modifyDefaultPassages() {
    // Initial passage component based on quiz type
    const quizType = this.quiz.test.test_type.toLowerCase();
    this.quiz.passages = (this.quiz.passages ?? []).map(
      (passage) =>
        new PassageEntity({
          ...passage,
          component: `quiz-${quizType}-passage`,
        }),
    );
  }

  updatePassageTitles() {
    const passages = this.quiz.passages
      .filter((p) => p.passage_type === 'PASSAGE')
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((passage, index) => {
        passage.title = `Passage ${index + 1}`;
        return passage;
      });
    const dicretSets = this.quiz.passages
      .filter((p) => p.passage_type === 'DISCRETESET')
      .sort((a, b) => a.sort_order - b.sort_order);

    let start = 1;
    const allPassages = [...passages, ...dicretSets].sort((a, b) => a.sort_order - b.sort_order);

    allPassages.forEach((passage) => {
      const questions = this.quiz.questions.filter((q) => q.passage_id === passage.id);
      const questionStart = start;
      const questionEnd = start + questions.length - 1;
      start = questionEnd + 1;

      switch (passage.passage_type) {
        case 'PASSAGE':
          passage.title = `${passage.title} (Questions ${questionStart}-${questionEnd})`;
          break;
        case 'DISCRETESET':
          passage.title = `Questions ${questionStart} - ${questionEnd} do not refer to a passage and are independent of each other.`;
          break;
      }
    });

    this.quiz.passages = allPassages;
  }
}
