import types from './types';
import { delay } from '~/core/utils/time';

export default {
  changeReadyStatus({ commit }, status) {
    commit(types.SET_IS_READY, status);
  },

  fetchTest(_, testId) {
    return this.$api.quiz.fetchQuiz(testId);
  },

  async initialQuiz({ commit, dispatch, getters }, { quiz, questionId }) {
    try {
      const [{ data: history }, { data: highlights }] = await Promise.all([
        this.$api.exam.fetchQuizHistory(quiz.test.id),
        this.$api.quiz.fetchHighlights(quiz.test.id),
      ]);

      const modifiedQuiz = this.$services.quiz.getInterceptor(quiz).modifySections().quiz;

      commit(types.SET_QUIZ, modifiedQuiz);
      commit(types.SET_HIGHLIGHTS, highlights);

      const allValuesAreNull = Object?.values(history)?.every((value) => value === null);
      if (allValuesAreNull && !getters.isTestFinished) {
        await this.$services.quiz.finishTest();
        this.$services.quiz.redirectToExamLandingPage();
        return;
      }

      const studyableSection = this.$services.quiz.findStudyableSectionBetweenTwoSections(
        history.parent_section_id,
        history.exam_section_id,
        'section_id',
        'id',
      );

      await dispatch('initialNextSection', {
        questionId: questionId ?? history?.question_id,
        examSectionId: studyableSection?.id,
      });

      if (!getters.isTestFinished) {
        this.$services.quiz.startBackgroundTimer();
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async initialNextSection({ dispatch }, { questionId, examSectionId }) {
    try {
      dispatch('setIsReviewMode', false);
      dispatch('resetFilteredQuestions');
      dispatch('syncSectionFeatures', []);
      dispatch('syncQuestionFeatures', []);
      await this.$services.quiz.initialQuestionsAndPassages(questionId, examSectionId);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchSectionIdByQuestion(_, { testId, questionId }) {
    try {
      const { data } = await this.$api.quiz.fetchSectionIdByQuestion(testId, questionId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async waitForTestDelay({ getters, dispatch }) {
    if (getters.metadata?.delay > 0) {
      dispatch('setDelayLoading', true);
      await delay(getters.metadata?.delay * 1000);
      dispatch('setDelayLoading', false);
    }
  },

  async setNextStep({ getters, commit, dispatch }) {
    await dispatch('waitForTestDelay');

    try {
      const { hasNextQuestion, questionIndex, isReviewMode, quizType } = getters;
      if (hasNextQuestion) {
        return commit(types.SET_QUESTION_INDEX, questionIndex + 1);
      } else if (isReviewMode && quizType === 'exam') {
        return dispatch('resetFiltersAndNavigateToReviewQuestionsStep');
      }
      return dispatch('setNextSection');
    } catch (err) {
      this.$log(err);
      return false;
    }
  },

  async setPreviousStep({ getters, commit, dispatch }) {
    await dispatch('waitForTestDelay');

    const { hasPreviousQuestion, questionIndex } = getters;
    if (hasPreviousQuestion) {
      commit(types.SET_QUESTION_INDEX, questionIndex - 1);
    }
  },

  async setNextSection({ getters, commit, dispatch }) {
    const { hasNextSection, sectionIndex, isTestFinished } = getters;
    if (hasNextSection) {
      dispatch('changeReadyStatus', false);
      if (!isTestFinished) {
        await this.$services.quiz.finishSection();
      }
      commit(types.SET_QUESTION_INDEX, 0);
      commit(types.SET_SECTION_INDEX, sectionIndex + 1);
      await dispatch('initialNextSection', {});
      setTimeout(() => dispatch('changeReadyStatus', true), 500);
    } else {
      dispatch('setFinishingMode', true);
    }
  },

  async finishTest(_, testId) {
    try {
      const { data } = await this.$api.quiz.finishTest(testId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  appendHighlight({ commit }, { type, key, highlight }) {
    commit(types.APPEND_NEW_HIGHLIGHT, { type, key, highlight });
  },

  async saveHighlight({ dispatch }, { app_id, daily_id, value, additional }) {
    try {
      const payload = { type: 'updateExamHighlight', app_id, daily_id, value, additional };
      const { data } = await this.$api.quiz.saveHighlight(daily_id, payload);
      const highlight = data.highlight;
      dispatch('appendHighlight', { type: highlight.type, key: highlight.key, highlight });
    } catch (err) {
      throw new Error(err);
    }
  },

  flagActiveQuestion({ getters, dispatch }) {
    const { received_from_server, marked, answer_key, id } = getters.activeQuestion;
    if (received_from_server) {
      dispatch('setQuestionAnswer', {
        marked: !marked,
        answer: getters.activeQuestion[answer_key],
      });
    }
    dispatch('modifyQuestionProperty', {
      questionId: id,
      key: 'marked',
      value: !marked,
    });
  },

  navigateToReviewQuestionsStep({ getters, dispatch }) {
    const reviewStep = getters.questions.find((question) => question.id === 'review-step');
    dispatch('navigateToQuestion', reviewStep.id);
  },

  resetQuizState({ commit }) {
    commit(types.RESET_STATE);
  },

  setQuestionIndex({ commit }, index) {
    commit(types.SET_QUESTION_INDEX, index);
  },

  async navigateToQuestion({ commit, getters, dispatch }, id) {
    await dispatch('waitForTestDelay');
    const questionIndex = getters.questions.findIndex((question) => question.id === id);
    commit(types.SET_QUESTION_INDEX, questionIndex);
  },

  attachQuestionFeatures({ commit }, features) {
    commit(types.ATTACH_QUESTION_FEATURES, features);
  },

  syncQuestionFeatures({ commit }, features) {
    commit(types.SYNC_SECTION_FEATURES, features);
  },

  syncSectionFeatures({ commit }, features) {
    commit(types.SYNC_QUESTION_FEATURES, features);
  },

  modifyQuestionProperty({ getters, commit }, { questionId, key, value }) {
    const question = getters.questions.find((question) => question.id === questionId);
    commit(types.MODIFY_QUESTION_PROPERTY, { question, key, value });
  },

  async finishSection(_, { testId, sectionId, examSectionId }) {
    try {
      const { data } = await this.$api.quiz.finishSection(testId, sectionId, examSectionId);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  increaseActiveQuestionTimer({ dispatch, getters }) {
    const { activeQuestion } = getters;
    if (!activeQuestion) {
      return;
    }
    const timeOnQuestion = activeQuestion?.time_on_question + 1;
    dispatch('modifyQuestionProperty', {
      questionId: activeQuestion.id,
      key: 'time_on_question',
      value: timeOnQuestion,
    });
  },

  setQuestionCrossedOuts({ getters, dispatch }, { crossedOutItems }) {
    const { activeQuestion } = getters;
    if (!activeQuestion) {
      return;
    }
    dispatch('modifyQuestionProperty', {
      questionId: activeQuestion.id,
      key: 'crossedOuts',
      value: crossedOutItems ?? activeQuestion.crossedOuts,
    });
  },

  async setQuestionAnswer({ getters, dispatch }, { question: activeQuestion, answer, marked }) {
    const question = activeQuestion || getters.activeQuestion;
    if (!question) {
      return;
    }
    try {
      await this.$api.quiz.setQuestionAnswer({
        testId: getters.test.id,
        questionId: question.id,
        timeOnQuestion: question.time_on_question || 1,
        answer: answer,
        marked: marked ?? question.marked,
      });
      dispatch('modifyQuestionProperty', {
        questionId: question.id,
        key: question.answer_key,
        value: answer,
      });
    } catch {}
  },

  setIsReviewMode({ commit }, isReviewMode) {
    commit(types.SET_IS_REVIEW_MODE, isReviewMode);
  },

  filterMarkedQuestions({ commit, dispatch, getters }, callback = () => {}) {
    dispatch('resetFilteredQuestions');
    const markedQuestions = getters.questions.filter((question) => question.received_from_server && question.marked);
    if (markedQuestions.length > 0) {
      dispatch('setIsReviewMode', true);
      commit(types.SET_QUESTION_INDEX, 0);
      commit(types.SET_FILTERED_QUESTIONS, markedQuestions);
    } else {
      callback();
    }
  },

  filterIncompleteQuestions({ commit, dispatch, getters }, callback = () => {}) {
    dispatch('resetFilteredQuestions');
    const incompleteQuestions = getters.questions.filter((question) => {
      return question.received_from_server && question.selected_answer === null;
    });
    if (incompleteQuestions.length > 0) {
      dispatch('setIsReviewMode', true);
      commit(types.SET_QUESTION_INDEX, 0);
      commit(types.SET_FILTERED_QUESTIONS, incompleteQuestions);
    } else {
      callback();
    }
  },

  filterAllQuestions({ dispatch, getters }) {
    dispatch('setIsReviewMode', true);
    dispatch('resetFilteredQuestions');
    const firstQuestion = getters.countableQuestions[0];
    dispatch('navigateToQuestion', firstQuestion.id);
  },

  resetFilteredQuestions({ commit }) {
    commit(types.SET_FILTERED_QUESTIONS, []);
  },

  resetFiltersAndNavigateToReviewQuestionsStep({ dispatch }) {
    dispatch('resetFilteredQuestions');
    dispatch('navigateToReviewQuestionsStep');
  },

  changeTestActiveStatus({ commit }, status) {
    commit(types.SET_TEST_ACTIVE, status);
  },

  setFinishingMode({ commit, getters }, status) {
    const quiz = getters.quiz;
    this.$gtmEvent.dispatch('exam_finish', { exam_title: quiz?.title });
    commit(types.SET_FINISHING_MODE, status);
  },

  async updateTestDuration({ commit, getters }, { testId, sectionId, duration_in_secs }) {
    try {
      const { data } = await this.$api.quiz.updateTestDuration(testId, sectionId, duration_in_secs);
      const activeSection = getters.activeSection;
      commit(types.SET_SECTION_TIME_ALLOTED, { activeSection, duration_in_secs });
      return data;
    } catch (e) {
      throw new Error(e);
    }
  },

  async fetchFeedbacks(_, testId) {
    const { data } = await this.$api.quiz.fetchFeedbacks(testId);
    return data;
  },

  async setDelay({ commit }, payload) {
    const { testId, delay } = payload;
    try {
      await this.$api.quiz.updateMetadataDelay(testId, delay);
      commit(types.SET_DELAY, delay);
    } catch {}
  },

  setDelayLoading({ commit }, payload) {
    commit(types.SET_DELAY_LOADING, payload);
  },

  setActiveRange({ commit }, payload) {
    commit(types.SET_ACTIVE_RANGE, payload);
  },

  setShowSolution({ commit }, status) {
    commit(types.SET_SHOW_SOLUTION, status);
  },
};
