import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import solidIcons from './solidIcons';
import regularIcons from './regularIcons';
import brandIcons from './brandIcons';

config.autoAddCss = false;
library.add(solidIcons);
library.add(brandIcons);
library.add(regularIcons);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
