import Platform from '~/core/services/platform';
import Auth from '~/core/services/auth';
import User from '~/core/services/user';
import Flashcard from '~/core/services/flashcard/index';
import DiscountService from '~/core/services/discount.service';
import PaymentService from '~/core/services/payment.service';
import ExamService from '~/core/services/exam.service';
import QuizService from '~/core/services/qbank-quiz/quiz.service';
import UtmService from '~/core/services/utm.service';

export default (context, inject) => {
  const services = {
    platform: Platform(context),
    auth: Auth(context),
    user: User(context),
    flashcard: Flashcard(context),
    discount: new DiscountService(context),
    payment: new PaymentService(context),
    exam: new ExamService(context),
    quiz: new QuizService(context),
    utm: new UtmService(context),
  };

  inject('services', services);
};
