
export default {
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200,
      buttons: {
        confirm: 'Ok',
        cancel: 'No',
      },
    },
  }),

  computed: {
    styles() {
      switch (this.options.color) {
        case 'primary':
          return 'bg-indigo-700 text-white';
        case 'error':
          return 'bg-red-700 text-white';
        default:
          return 'bg-white border-gray-700';
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.onKeyup);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup);
  },

  methods: {
    open(title, message, options) {
      this.show = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.show = false;
    },
    cancel() {
      this.resolve(false);
      this.show = false;
    },
    onKeyup(event) {
      if (!this.show) {
        return;
      }
      if (event.key === 'Escape') {
        this.cancel();
      }
    },
  },
};
