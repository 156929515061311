import types from './types';
import { TRUSTED_SITES_LOCAL_STORAGE_KEY } from '~/core/utils/constants';

export default {
  [types.SET_AUTH_REQUIRED](state, required) {
    state.isLoginRequired = required;
  },

  [types.SET_USER_META](state, payload) {
    state.meta = payload;
  },

  [types.ADD_TRUSTED_SITE](state, payload) {
    state.trustedSites.push(payload);
    localStorage.setItem(TRUSTED_SITES_LOCAL_STORAGE_KEY, JSON.stringify(state.trustedSites));
  },

  [types.REMOVE_TRUSTED_SITE](state, payload) {
    state.trustedSites = state.trustedSites.filter((site) => site !== payload);
    localStorage.setItem(TRUSTED_SITES_LOCAL_STORAGE_KEY, JSON.stringify(state.trustedSites));
  },

  [types.MODIFY_USER_META](state, { key, value }) {
    state.meta[key] = value;
  },

  [types.SET_COOKIE_CONFIGURATION](state, payload) {
    state.cookieConfiguration = payload;
  },
};
