import Vue from 'vue';
import types from './types';
import getters from './getters';
import { RECENT_SEARCH_LOCAL_STORAGE_KEY } from '~/core/utils/constants';
import { randomFixedInteger } from '~/core/utils/numbers';
import { DeckEntity } from '~/core/entities/flashcard/deck.entity';

export default {
  setSearchMode(state, isActive) {
    state.isInSearchMode = isActive;
  },
  setPageLoadingMode(state, payload) {
    state.pageIsLoading = payload;
  },
  resetSections(state) {
    state.browseSections = {};
  },
  resetData(state) {
    state.browseSections = {};
    state.initialData = null;
  },
  [types.SET_RECENT_SEARCHES](state, items) {
    state.recentSearches = items;
  },
  [types.SET_BROWSE_SECTION](state, { section, response }) {
    if (state.browseSections[section] === undefined) {
      state.browseSections[section] = {
        data: [],
        meta: null,
      };
    }
    response.data.forEach((item) => {
      state.browseSections[section].data.push(new DeckEntity(item));
    });
    state.browseSections[section].meta = response.meta;
  },
  [types.SET_INITIAL_DATA](state, data) {
    state.initialData = data;
  },

  initializeRecentSearches(state) {
    const storeRecentSearches = getters.recentSearches(state);
    if (storeRecentSearches.length) {
      return;
    }

    const localStorageRecentSearches = localStorage.getItem(RECENT_SEARCH_LOCAL_STORAGE_KEY);
    const recentSearches = localStorageRecentSearches ? JSON.parse(localStorageRecentSearches) : [];
    state.recentSearches = recentSearches;
  },

  addSearchToRecentlySearchQueue(state, query) {
    const recentSearches = [...getters.recentSearches(state)];
    const CurrentRecentlySearchQueryIndexInQueue = recentSearches.findIndex(
      (item) => item.query.toLowerCase() === query.toLowerCase(),
    );

    if (CurrentRecentlySearchQueryIndexInQueue !== -1) {
      recentSearches.unshift(recentSearches[CurrentRecentlySearchQueryIndexInQueue]);
      recentSearches.splice(CurrentRecentlySearchQueryIndexInQueue + 1, 1);
    } else {
      if (recentSearches.length === 5) {
        recentSearches.pop();
      }
      recentSearches.unshift({ id: randomFixedInteger(8), query });
    }

    state.recentSearches = recentSearches;
    localStorage.setItem(RECENT_SEARCH_LOCAL_STORAGE_KEY, JSON.stringify(recentSearches));
  },

  removeRecentlySearchItem(state, id) {
    const recentSearches = getters.recentSearches(state);
    const newRecentSearches = recentSearches.filter((item) => item.id !== id);

    state.recentSearches = newRecentSearches;
    localStorage.setItem(RECENT_SEARCH_LOCAL_STORAGE_KEY, JSON.stringify(newRecentSearches));
  },

  unsubscribeDeck(state, deck) {
    deck.has_study_started = false;
    const sections = getters.browseSections(state);

    Object.keys(sections).forEach((sectionName) => {
      const deckInSection = sections[sectionName].data.find((deckInSection) => deckInSection.id === deck.id);

      if (deckInSection) {
        Vue.set(deckInSection, 'has_study_started', false);
        Vue.set(deckInSection, 'study_progress', 0);
      }
    });
  },
};
