
export default {
  props: {
    isComponentActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      email: '',
      errors: {
        email: '',
      },
    };
  },

  watch: {
    isComponentActive: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.$refs?.emailInput?.focus();
          }, 100);
        }
      },
    },
  },

  methods: {
    async login() {
      try {
        this.loading = true;
        await this.$auth.loginWith('sanctum', {
          data: {
            email: this.email,
          },
        });
        this.loading = false;
        this.$emit('verify', this.email);
      } catch (e) {
        this.loading = false;
        this.errors = this.$getErrors(e) || this.errors;
      }
    },
  },
};
