import { BREAK_POINTS } from '~/core/utils/constants';

const imageBlacklist = ['btn', 'jw-expanded-successful'];

let observer = null;

// Utility function to create a close button element
const createCloseButton = () => {
  const closeElement = document.createElement('div');
  closeElement.id = 'close-element';
  closeElement.className =
    'w-4 h-4 border-2 absolute top-3 right-3 text-white cursor-pointer flex justify-center items-center';
  closeElement.textContent = 'X';
  closeElement.addEventListener('click', () => {
    const background = document.getElementById('jw-expandable-background');
    if (background) {
      background.style.visibility = 'hidden';
      background.style.opacity = '0';
    }
  });
  return closeElement;
};

// Event handler to enlarge image
const zoomImg = function (event) {
  const lb = document.getElementById('jw-expandable-wrapper');
  lb.innerHTML = ''; // Clear existing content

  const originalImage = this;
  const clone = originalImage.cloneNode();

  const { width, maxHeight } = calculateImageDimensions(event, originalImage);
  const scale = Math.min(width / originalImage.naturalWidth, maxHeight / originalImage.naturalHeight);

  const newWidth = originalImage.naturalWidth * scale;
  const newHeight = originalImage.naturalHeight * scale;

  clone.style.width = `${newWidth}px`;
  clone.style.height = `${newHeight}px`;
  clone.style.maxWidth = isMobileView() ? '100%' : `${width}px`;
  clone.style.maxHeight = `${maxHeight}px`;
  clone.classList.add('jw-expanded-successful');
  clone.style.cursor = 'initial';
  clone.style.background = 'white';

  lb.appendChild(clone);
  lb.appendChild(createCloseButton());

  const background = document.getElementById('jw-expandable-background');
  background.style.visibility = 'visible';
  background.style.opacity = '1';
};

// Utility function to calculate image dimensions
const calculateImageDimensions = (event, originalImage) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const maxWidth = (windowWidth * 80) / 100;
  const maxHeight = windowHeight - (windowHeight * 20) / 100;

  const width = isMobileView() ? windowWidth - 40 : maxWidth;
  const height = isMobileView() ? windowHeight - 40 : maxHeight;

  // Calculate the aspect ratio of the image
  const aspectRatio = originalImage.naturalWidth / originalImage.naturalHeight;

  // Determine the dimensions based on the aspect ratio and maximum dimensions
  if (aspectRatio > 1) {
    // Landscape orientation
    const newWidth = Math.min(originalImage.naturalWidth * 0.7, width);
    const newHeight = newWidth / aspectRatio;
    return { width: newWidth, maxHeight: newHeight };
  } else {
    // Portrait or square orientation
    const newHeight = Math.min(originalImage.naturalHeight, height);
    const newWidth = newHeight * aspectRatio;
    return { width: newWidth, maxHeight: newHeight };
  }
};

// Check if the current view is mobile
const isMobileView = () => window.innerWidth <= BREAK_POINTS.MD;

// Function to make an image expandable
const makeImageExpandable = (img) => {
  if (imageBlacklist.every((className) => !img.classList.contains(className))) {
    img.style.cursor = 'zoom-in';
    img.addEventListener('click', zoomImg);
  }
};

// Observer function to watch for changes in child nodes
const observeChildNodes = (el) => {
  observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((addedNode) => {
        if (addedNode instanceof HTMLElement) {
          addedNode.querySelectorAll('img').forEach((img) => {
            makeImageExpandable(img);
          });
        }
      });
    });
  });
  observer.observe(el, { childList: true, subtree: true });
};

// Function to load background overlay element
const loadBackground = () => {
  if (!document.getElementById('jw-expandable-background')) {
    const backgroundDiv = document.createElement('div');
    backgroundDiv.id = 'jw-expandable-background';
    backgroundDiv.className =
      'fixed flex justify-center items-center top-0 left-0 w-screen h-screen z-[999] bg-black/50';
    backgroundDiv.style.cssText = 'visibility: hidden; opacity: 0; transition: all ease-in-out 0.4s;';
    backgroundDiv.addEventListener('click', (event) => {
      if (event.target.id === 'jw-expandable-background') {
        backgroundDiv.style.visibility = 'hidden';
        backgroundDiv.style.opacity = '0';
      }
    });

    const imageDiv = document.createElement('div');
    imageDiv.id = 'jw-expandable-wrapper';
    imageDiv.className = 'relative max-h-screen overflow-y-auto pt-11 px-4 pb-4 bg-[#006DAA]';
    backgroundDiv.appendChild(imageDiv);
    document.body.appendChild(backgroundDiv);
  }
};

// Directive definition
export default (Vue) => {
  Vue.directive('enlarge-images', {
    inserted(el, binding) {
      if (binding.value) {
        setTimeout(() => {
          loadBackground();
          observeChildNodes(el);
          el.querySelectorAll('img').forEach((img) => {
            makeImageExpandable(img);
          });
        }, 500);
      }
    },
    unbind() {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    },
  });
};
