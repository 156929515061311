import { isString, isObject } from 'lodash';

export default (app, gtmData) => {
  return {
    inserted(el, { value }) {
      if (!value) {
        return;
      }
      let event;
      if (isString(value)) {
        // v-gtm="'event_name'"
        event = gtmData.clickEvents.find((event) => event.name === value);
      } else if (isObject(value)) {
        // v-gtm="{ name: 'event_name', params: {} }"
        event = gtmData.clickEvents.find((event) => event.name === value.name) ?? {};
        event = { ...event, ...value };
      }
      if (!event) {
        return app.$bugsnag.notify(`Event not found! ${event.name}`);
      }

      el.setAttribute('data-tracking', 'gtm');
      for (const param in event.params) {
        const paramName = param.replace('_', '-');
        const paramValue = event.params[param];
        el.setAttribute(`data-gtm-${paramName}`, paramValue);
      }
    },
  };
};
