export default {
  SET_COURSES: 'SET_COURSES',
  SET_COURSE: 'SET_COURSE',
  SET_CLASSES: 'SET_CLASSES',
  SET_TIERS: 'SET_TIERS',
  RESET_TIERS: 'RESET_TIERS',
  TOGGLE_LESSON_COMPLETED: 'TOGGLE_LESSON_COMPLETED',
  TOGGLE_WEEKLY_LESSON_COMPLETED: 'TOGGLE_WEEKLY_LESSON_COMPLETED',
  SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE: 'SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE',
  SET_COMPONENT_LOADING_SENDY: 'SET_COMPONENT_LOADING_SENDY',
  SET_COURSE_LESSONS: 'SET_COURSE_LESSONS',
  SET_WEEK_MANDATORY_LESSONS: 'SET_WEEK_MANDATORY_LESSONS',
  SET_CLASSIFY_ASSIGNMENTS: 'SET_CLASSIFY_ASSIGNMENTS',
  TOGGLE_CLASSIFY_ASSIGNMENTS_LESSON_COMPLETED: 'TOGGLE_CLASSIFY_ASSIGNMENTS_LESSON_COMPLETED',
  SET_TOPICS: 'SET_TOPICS',
  SET_SENDY_STATUS: 'SET_SENDY_STATUS',
  RESET_STATE: 'RESET_STATE',
  SET_LATEST_VISITED_LESSON: 'SET_LATEST_VISITED_LESSON',
  MODIFY_LESSON_PROPERTY: 'MODIFY_LESSON_PROPERTY',
};
