import { paginationModel } from '~/core/entities/paginationModel';

export default () => ({
  sessions: [],
  liveSessions: [],
  studentSessions: [],
  session: null,
  params: {
    page: null,
    course: null,
    class: null,
    type: 'free',
    sort: 'start_date',
    order: 'asc',
    past: false,
  },
  pagination: { ...paginationModel },
  isComponentLoadingSessionList: false,
});
