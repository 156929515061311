export default {
  params: (state) => state.params,
  sessions: (state) => state.sessions,
  liveSessions: (state) => state.liveSessions,
  studentSessions: (state) => state.studentSessions,
  session: (state) => state.session,
  freeSessions: (state) => state.sessions.filter((session) => session.type.toLowerCase() === 'free'),
  firstFreeAvailableSession: (_, getters) => {
    return getters.freeSessions.find((item) => item.is_user_able_to_enroll);
  },
  paidSessions: (state) => state.sessions.filter((session) => ['paid', 'premium'].includes(session.type)),
  hasSessions: (state) => {
    return state.sessions.length > 0;
  },
  hasFreeSessions: (_, getters) => {
    return getters.freeSessions.length > 0;
  },
  hasFreeSessionsNotEncore: (state) =>
    state.sessions.filter((session) => {
      return (
        session.type.toLowerCase() === 'free' &&
        session.has_recording === false &&
        session.has_student_enrolled === false &&
        session.has_finished === false
      );
    })?.length > 0 ?? false,
  pagination: (state) => {
    return {
      ...state.pagination,
      isLastPage: state.pagination.current_page >= state.pagination.last_page,
    };
  },
  pager: (state) => {
    return {
      isLastPage: state.pagination.current_page >= state.pagination.last_page,
    };
  },
  isButtonMoreVisible: (_, getters) => {
    if (getters.pagination.last_page === 1) {
      return false;
    }
    return true;
  },
  isComponentLoadingSessionList: (state) => state.isComponentLoadingSessionList,
  firstSixSessions: (state) => {
    return [...state.sessions]
      .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
      .slice(0, 6);
  },
};
