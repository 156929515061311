/* eslint-disable no-throw-literal */
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { otpErrors } from '../otpDTOs';

export class FirebaseOTPProver {
  auth;
  initializedButtons;
  disableRecaptcha;

  constructor() {
    this.initializedButtons = {};

    const URLparams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this.disableRecaptcha = process.env.NODE_ENV !== 'production' && URLparams.disable_recaptcha === 'true';
  }

  initial(nuxtContext, elementId) {
    if (this.initializedButtons && this.initializedButtons[elementId]) {
      return;
    }
    const app = initializeApp(nuxtContext.$config.FIREBASE);
    this.auth = getAuth(app);
    this.auth.settings.appVerificationDisabledForTesting = this.disableRecaptcha;
    window.recaptchaVerifier = new RecaptchaVerifier(elementId, { size: 'invisible' }, this.auth);
    this.initializedButtons[elementId] = true;
  }

  async send(phoneNumber) {
    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(this.auth, phoneNumber, appVerifier);
      window.confirmationResult = confirmationResult;
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-phone-number':
          throw { OTP_ERROR: otpErrors.INVALID_PHONE_NUMBER.code };
        default:
          throw err;
      }
    }
  }

  async verify(activationCode) {
    try {
      const { _tokenResponse, user } = await window.confirmationResult.confirm(activationCode);
      return { token: _tokenResponse.idToken, phoneNumber: user.phoneNumber };
    } catch (err) {
      switch (err.code) {
        case 'auth/invalid-verification-code':
          throw { OTP_ERROR: otpErrors.INVALID_VERIFICATION_CODE.code };
        case 'auth/invalid-phone-number':
          throw { OTP_ERROR: otpErrors.INVALID_PHONE_NUMBER.code };
        default:
          throw err;
      }
    }
  }
}
