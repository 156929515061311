export default {
  async setActiveCourse({ commit, dispatch }, course) {
    commit('SET_ACTIVE_COURSE', course);
    try {
      await dispatch('getCourseSubjects', course.id);
      await dispatch('getCourseSessions', course.id);
    } catch (error) {}
  },
  async getCourseSubjects({ commit }, courseId) {
    const { data } = await this.$api.dashboard.fetchCourseSubjects(courseId);
    commit('SET_COURSE_SUBJECTS', data);
    return data;
  },
  async getCourseSessions({ commit }, courseId) {
    const { data } = await this.$api.dashboard.fetchCourseSessions(courseId);
    commit('SET_COURSE_SESSIONS', data);
    return data;
  },
  updateCourseSessions({ commit }, sessions) {
    commit('SET_COURSE_SESSIONS', sessions);
  },

  fetchLandingData() {
    return this.$api.dashboard.fetchLandingData();
  },
};
