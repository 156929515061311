export default (Vue) => {
  // samples:
  // v-can.flashcard="'permission1'"
  // v-can.flashcard="['permission1', 'permission2']"
  // v-can.flashcard.some="['permission3', 'permission2']"
  Vue.directive('can', {
    inserted(el, binding, vnode) {
      let canDisplayElement = false;
      const userPermissions = prepareUserPermissions(binding.modifiers, vnode);
      if (typeof binding.value === 'string') {
        canDisplayElement = canDisplayElementByStringValue(userPermissions, binding.value);
      } else if (Array.isArray(binding.value)) {
        if (binding.modifiers.some) {
          canDisplayElement = canDisplayElementByArraySomeValue(userPermissions, binding.value);
        } else {
          canDisplayElement = canDisplayElementByArrayEveryValue(userPermissions, binding.value);
        }
      }
      if (!canDisplayElement && el.parentNode !== null) {
        el.parentNode.removeChild(el);
      }
    },
  });
};

const prepareUserPermissions = (modifiers, vnode) => {
  let userPermissions = [];
  if (modifiers.flashcard) {
    userPermissions = vnode.context.$store.getters['flashcard/permissions'];
  } else {
    console.error('You should pass scop permission to directive. for example: v-can.flashcard="..."');
  }
  return userPermissions;
};

const canDisplayElementByStringValue = (userPermissions, requiredPermission) => {
  return userPermissions.includes(requiredPermission);
};

const canDisplayElementByArrayEveryValue = (userPermissions, requiredPermissions) => {
  return requiredPermissions.every((requiredPermission) => userPermissions.includes(requiredPermission));
};

const canDisplayElementByArraySomeValue = (userPermissions, requiredPermissions) => {
  for (const requiredPermission of requiredPermissions) {
    if (userPermissions.includes(requiredPermission)) {
      return true;
    }
  }
  return false;
};
