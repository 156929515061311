import Vue from 'vue';
import Component from './component.vue';
import JwToast from './JwToast.js';

const Plugin = (Vue, options = {}) => {
  const methods = JwToast(Vue, options);
  Vue.$jwToast = methods;
  Vue.prototype.$jwToast = methods;
};

Component.install = Plugin;

Vue.use(Component, {
  // duration: 5000,
  maxNumberToShow: 4,
});

export default (_, inject) => {
  inject('jwToast', Vue.prototype.$jwToast);
};
