
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    bodyClass: {
      type: String,
      default: '',
    },
    zIndex: {
      type: [String, Number],
      default: 501,
    },
  },

  watch: {
    show(status) {
      document.querySelector('body').style.overflowY = status ? 'hidden' : 'visible';
    },
  },

  beforeDestroy() {
    document.querySelector('body').style.overflowY = 'visible';
  },
};
