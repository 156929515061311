import types from './types';
import { paginationModel } from '~/core/entities/paginationModel';

export default {
  [types.SET_TEST](state, test) {
    state.test = test;
  },

  [types.SET_TESTS](state, tests) {
    state.tests = tests;
  },

  [types.SET_PAGINATION](state, payload) {
    state.pagination = Object.assign(paginationModel, payload);
  },

  [types.SET_APPS](state, payload) {
    state.apps = [...payload];
  },

  [types.SET_SECTIONS](state, payload) {
    state.sections = [...payload];
  },

  [types.SET_FOUNDATIONS](state, payload) {
    state.foundations = [...payload];
  },

  [types.SET_TEST_ANALYSIS](state, payload) {
    state.testAnalysis = payload;
  },

  [types.SET_TEST_RESULT](state, payload) {
    state.testResult = payload;
  },

  // save test preview list query params like sort, order
  [types.SET_CACHE](state, { key, payload }) {
    state.cache[key] = payload;
  },

  [types.SET_BREAKDOWN_BY_SUBJECT](state, payload) {
    state.breakdownBySubject = [...payload];
  },

  [types.SET_BREAKDOWN_BY_DIFFICULTY](state, payload) {
    state.breakdownByDifficulty = [...payload];
  },

  [types.SET_COMPONENT_LOADING_TEST_DETAIL](state, isLoading) {
    state.isComponentLoadingTestDetail = isLoading;
  },

  [types.SET_COMPONENT_LOADING_TEST_RESULT](state, isLoading) {
    state.isComponentLoadingTestResult = isLoading;
  },

  [types.SET_COMPONENT_LOADING_TEST_ANALYSIS](state, isLoading) {
    state.isComponentLoadingTestAnalysis = isLoading;
  },
};
