export default (Vue) => {
  const directive = {
    inserted: (el, binding) => {
      el.scrollCallback = () => {
        if (window.pageYOffset > binding.value) {
          binding.modifiers?.reverse ? el.classList.remove(binding.arg) : el.classList.add(binding.arg);
        } else {
          binding.modifiers?.reverse ? el.classList.add(binding.arg) : el.classList.remove(binding.arg);
        }
      };

      window.addEventListener('scroll', el.scrollCallback);
      el.scrollCallback();
    },
    unbind(el) {
      el.removeEventListener('scroll', el.scrollCallback);
    },
  };

  Vue.directive('scroll-passed', directive);
};
