import moment from 'moment';
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const tomorrow = () => {
  const today = moment();
  return moment(today).add(1, 'days').toDate();
};

export const stringToDate = (date) => {
  return moment(date).toDate();
};

export const isAfter = ({ start, end }) => {
  return moment(start).isAfter(end);
};
