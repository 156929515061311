import types from './types';
import { ACCESS_COOKIE_VALUES, DEFAULT_COOKIE_VALUES } from '~/core/utils/constants';

export default {
  setAuthRequired({ commit }, required) {
    commit(types.SET_AUTH_REQUIRED, required);
  },
  setUserMeta({ commit }, meta) {
    commit(types.SET_USER_META, meta);
  },
  addTrustedSite({ commit }, site) {
    commit(types.ADD_TRUSTED_SITE, site);
  },
  removeTrustedSite({ commit }, site) {
    commit(types.REMOVE_TRUSTED_SITE, site);
  },
  modifyUserMeta({ commit }, { key, value }) {
    commit(types.MODIFY_USER_META, { key, value });
  },
  async initialCookieConfiguration({ commit }) {
    try {
      const response = await this.$api.ping.fetch();
      const accessCookie = parseInt(response.headers['x-access-cookie']) ?? null;
      const defaultCookie = parseInt(response.headers['x-default-cookie']) ?? null;

      commit(types.SET_COOKIE_CONFIGURATION, { accessCookie, defaultCookie });
    } catch (error) {
      console.error('Error while fetching access cookies:', error);
      commit(types.SET_COOKIE_CONFIGURATION, {
        accessCookie: ACCESS_COOKIE_VALUES.NOT_FROM_USA,
        defaultCookie: DEFAULT_COOKIE_VALUES.SHOULD_NOT_TRACK,
      });
    }
  },
};
