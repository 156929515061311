import { paginationModel } from '~/core/entities/paginationModel';

export default () => ({
  test: null,
  tests: [],
  apps: [],
  sections: [],
  foundations: [],
  testResult: null,
  testAnalysis: null,
  breakdownBySubject: [],
  breakdownByDifficulty: [],
  pagination: { ...paginationModel },
  isComponentLoadingTestDetail: false,
  isComponentLoadingTestResult: false,
  isComponentLoadingTestAnalysis: false,
});
