export default () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.Stripe) {
        resolve();
        clearInterval(interval);
      }
    }, 50);
  });
};
