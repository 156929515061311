const LOGIN_REDIRECT_PATH__LOCAL_STORAGE_KEY = 'auth.login-redirect';

export default ({ store }) => ({
  setLoginRedirectPath(loginPath) {
    localStorage.setItem(LOGIN_REDIRECT_PATH__LOCAL_STORAGE_KEY, loginPath);
  },

  removeLoginRedirectPath() {
    localStorage.removeItem(LOGIN_REDIRECT_PATH__LOCAL_STORAGE_KEY);
  },

  getLoginRedirectPath() {
    return localStorage.getItem(LOGIN_REDIRECT_PATH__LOCAL_STORAGE_KEY);
  },

  setLoginRequired(isLoginRequired, path) {
    store.dispatch('user/setAuthRequired', isLoginRequired);

    if (isLoginRequired && path) {
      this.setLoginRedirectPath(path);
    } else {
      this.removeLoginRedirectPath();
    }
  },
});
