export default function ({ route, $services }) {
  const utmObject = {};
  for (const [key, value] of Object.entries(route.query)) {
    if (key.startsWith('utm_')) {
      utmObject[key] = value;
    }
  }

  if (Object.keys(utmObject).length > 0) {
    $services.utm.setUrlUtmCookie(utmObject);
  }
}
