
import { mapGetters } from 'vuex';
import styles from './navbar-topbar-col.css?raw';
import styleInjector from '~/core/mixins/styleInjector';

export default {
  mixins: [styleInjector(styles)],
  data() {
    return {
      showHubSideBar: false,
      navs: [
        {
          title: 'Home',
          to: '/dashboard',
          icon: 'icon-home',
          gtm_event: 'home__sidebar__navigation',
        },
        {
          title: 'Question Bank',
          to: '/qbank',
          icon: 'icon-question-bank',
          gtm_event: 'mcat_qbank__sidebar__navigation',
        },
        {
          title: 'Practice Exams',
          to: '/mcat-cars-practice-exams',
          icon: 'icon-practice-exams',
          gtm_event: null,
        },
        {
          title: 'Flashcards',
          to: '/flashcards/browse',
          icon: 'icon-flashcards',
          gtm_event: 'flashcard__sidebar__navigation',
        },
        {
          title: 'Analytics',
          to: '/qbank/analysis',
          icon: 'icon-analytics',
          gtm_event: null,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('ui', ['device']),
  },

  methods: {
    toggleHubSideBar() {
      this.showHubSideBar = !this.showHubSideBar;
      this.$emit('toggleHubSidebar', this.showHubSideBar);
    },
  },
};
