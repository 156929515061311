import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import has from 'lodash/has';

let lessonOrder = 0;
const flattenLessonsTitle = [];
const onLessonsDeepClone = (lessons) => {
  return lessons.map((lesson) => {
    lessonOrder++;
    flattenLessonsTitle.push(lesson.title);
    return {
      ...lesson,
    };
  });
};

const onSectionsDeepClone = (sections) => {
  return sections.map((section) => {
    if (has(section, 'lessons')) {
      return {
        ...cloneDeep(section),
        lessons: [...onLessonsDeepClone(section.lessons)],
      };
    }
    return section;
  });
};

const onCourseDeepClone = (course) => {
  lessonOrder = 0;
  if (has(course, 'sections')) {
    return {
      ...cloneDeep(course),
      sections: [...onSectionsDeepClone(course.sections)],
    };
  }
  return course;
};

export default (course) => {
  return {
    course: cloneDeepWith(course, onCourseDeepClone),
    lessonsOrderSum: lessonOrder,
    flattenLessonsTitle,
  };
};
