
export default {
  data() {
    return {
      loadingGuestableMe: true,
    };
  },

  watch: {
    $auth: {
      immediate: true,
      handler(auth) {
        if (auth.user || !auth.loggedIn) {
          this.loadingGuestableMe = false;
          return;
        }
        this.loadingGuestableMe = true;
      },
    },
  },
};
