export class UseMobileOTPService {
  /**
   * loggedInUser is filled with verified user after successful verify
   * this static property of user has available in another components and file.
   */
  static loggedInUser;

  /**
   * @param {OTPProviderClass} provider provider is a class that should have an 'initial' (optional), 'send' (required), and 'verify' (required) methods.
   */
  constructor(provider) {
    // eslint-disable-next-line new-cap
    this.provider = new provider();
  }

  /**
   * Initialize provider such as configs, instance and etc.
   * @param {NuxtContext} nuxtContext
   * @param {any} payload
   */
  initialProvider(nuxtContext, payload) {
    UseMobileOTPService.loggedInUser = null;
    return this.provider.initial ? this.provider.initial(nuxtContext, payload) : null;
  }

  /**
   * Send Verification code to user phone number
   * @param {String} phoneNumber The user phone number
   * @returns Promise
   */
  sendMobileVerificationCode(phoneNumber) {
    return this.provider.send(phoneNumber);
  }

  /**
   * Verify the verification code sent to the user
   * @param {String|Number} activationCode The activation code sent to the user
   * @returns Promise<{ token, phoneNumber }>
   */
  async verifyMobileOTP(activationCode) {
    const user = await this.provider.verify(activationCode);
    UseMobileOTPService.loggedInUser = user;
    return user;
  }
}
