export default ($axios) => ({
  fetchClassById(classId) {
    return $axios.get(`/api/caap/classes/${classId}`);
  },

  updateDefaultPaymentMethod(payment_method) {
    return $axios.put('/api/caap/user/card', { payment_method });
  },

  postEnroll(params) {
    return $axios.post(`/api/caap/classes/${params.classId}/enrollments`, {
      is_installment: params.is_installment,
      payment_method: params.payment_method,
    });
  },

  fetchExtensionOptions(classId) {
    return $axios.get(`/api/caap/classes/${classId}/extension/options`);
  },

  enrollToClass(classId, purchaseType) {
    return $axios.post(`/api/caap/classes/${classId}/charge-via-saved-card`, {
      purchase_type: purchaseType,
    });
  },

  extendClass(classId, paymentMethod) {
    return $axios.post(`/api/caap/classes/${classId}/extend`, {
      payment_method: paymentMethod,
    });
  },

  cancelClass(classId) {
    return $axios.post(`api/caap/classes/${classId}/cancel-extension`);
  },
});
