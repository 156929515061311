export default () => ({
  deck: null,
  tags: {
    flatted: [],
    nested: [],
    forceRerender: 1,
  },
  cards: null,
  newCardMode: false,
  isLoadingMode: false,
  isImportDeckMode: false,
  cardTypes: null,
  activeCardModel: null,
  cardFilterObject: {},
});
