export default function ({ $hub }) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if ($hub.fetched) {
        clearInterval(interval);
        resolve();
      }
    }, 50);
  });
}
