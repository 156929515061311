
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      if (this.type === 'success') {
        return 'bg-green-600 hover:bg-green-700 focus:border-green-700 text-white';
      }
      if (this.type === 'primary' || this.type === 'submit') {
        return 'bg-purple-300 hover:bg-purple-400 focus:border-purple-400 text-white';
      }
      return 'bg-white border-gray-300 text-gray-700';
    },
    cursor() {
      return this.loading ? 'cursor-not-allowed' : 'cursor-pointer';
    },
  },
};
