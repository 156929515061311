import types from './types';

export default {
  async fetchTierById({ commit }, tierId) {
    const { data } = await this.$api.tiers.fetchTierById(tierId);
    commit(types.SET_TIER, data);
    return data;
  },

  async updateDefaultPaymentMethod(_, paymentMethod) {
    try {
      await this.$api.tiers.updateDefaultPaymentMethod(paymentMethod);
    } catch (error) {}
  },

  async postEnroll(_, params) {
    try {
      await this.$api.tiers.postEnroll(params);
    } catch (error) {}
  },

  async enrollToTier({ commit, dispatch }, { tierId, purchaseType }) {
    try {
      const res = await this.$api.tiers.enrollToTier(tierId, purchaseType);
      if (res) {
        commit(types.SET_PAYMENT_INTENT, res.payment_intent);
        commit(types.SET_PAYMENT_INTENT_SECRET, res);
      }
      dispatch('tiers/fetchTierById', tierId);
      return res;
    } catch (error) {
      return error?.response?.data?.payment_intent;
    }
  },

  async cancelTier({ dispatch }, tierId) {
    try {
      await this.$api.tiers.cancelTier(tierId);
      dispatch('student/fetchStudentCourses', {}, { root: true });
    } catch (error) {}
  },

  switchPaymentMethod({ commit }, type) {
    commit(types.SET_PAYMENT_METHOD, type);
  },

  setStudentEnrolled({ commit }, enrolled) {
    commit(types.SET_STUDENT_ENROLLED, enrolled);
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
};
