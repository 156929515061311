
export default {
  name: 'TopBanner',
  props: {
    banner: {
      type: Object,
      default: null,
    },
  },

  computed: {
    text() {
      return this.banner?.text;
    },
    button() {
      return {
        text: this.banner?.button,
        link: this.banner?.link,
      };
    },
  },
};
