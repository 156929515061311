export const getErrors = (e) => {
  const errors = e.response?.data?.errors ?? [];
  const kvErrors = {};
  Object.keys(errors).forEach((key) => {
    kvErrors[key] = errors[key][0];
  });
  return kvErrors;
};

/**
 * Remove all characters that used for tag html and script, Also trim string to remove spacing from start and end of it
 * @param {String} inputValue get
 * @returns string
 */
export const sanitizeString = (inputValue) => {
  const outputValue = inputValue.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '');
  return outputValue.trim();
};

/**
 * checks param has not illegal characters
 * @param {String} inputValue get
 * @returns string
 */
export const escapeParam = (inputValue) => {
  if (!inputValue) {
    return;
  }

  // Validate and Sanitize Input
  const isValidInput = /^[a-zA-Z0-9-_]+$/.test(inputValue);
  if (!isValidInput) {
    throw new Error('The input is invalid.');
  }

  // URL Encoding
  const encodedValue = encodeURIComponent(inputValue);

  // Input Length Limitations
  const maxLength = 50;
  if (inputValue.length > maxLength) {
    throw new Error('The input length is bigger than 50.');
  }

  // Output Encoding
  return sanitizeString(encodedValue);
};

export const getQueryParams = (url) => {
  const parsedUrl = new URL(url);

  const searchParams = new URLSearchParams(parsedUrl.search);

  const queryParams = {};

  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
};
