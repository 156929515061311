import types from './types';

export default {
  [types.SET_DAILY_PASSAGES](state, daily_passages) {
    state.daily_passages = [...daily_passages];
  },
  [types.SET_DAILY_PASSAGES_META](state, meta) {
    state.daily_passages_meta = meta;
  },
  [types.SET_DAILY_PASSAGES_LOADING](state, isLoading) {
    state.isLoadingDailyPassages = isLoading;
  },
  [types.SET_TODAY_PASSAGES_LOADING](state, isLoading) {
    state.isLoadingTodayPassages = isLoading;
  },
  [types.SET_DAILY_SECTIONS](state, daily_sections) {
    state.daily_sections = [...daily_sections];
  },
  [types.SET_TODAY_PASSAGES](state, today_passages) {
    state.today_passages = [...today_passages];
  },
  [types.SET_PASSAGES_STREAK](state, streak) {
    state.streak = streak;
  },
  [types.SET_SUBSCRIBE_BOX_STATUS](state, status) {
    state.isSubscribeBoxAvailable = status;
  },
  [types.RESET_DAILY_PASSAGE](state, passageId) {
    // reseting in daily list
    const theIndexInDaily = state.daily_passages.findIndex((passage) => passage.id === passageId);
    if (theIndexInDaily > -1) {
      state.daily_passages[theIndexInDaily].completed = false;
      state.daily_passages[theIndexInDaily].resettable = false;
    }
    // reseting in today list
    const theIndexInToday = state.today_passages.findIndex((passage) => passage.id === passageId);
    if (theIndexInToday > -1) {
      state.today_passages[theIndexInToday].completed = false;
      state.today_passages[theIndexInToday].resettable = false;
    }
  },
};
