import types from './types';

export default {
  [types.SET_SESSIONS](state, sessions) {
    state.sessions = [...sessions];
  },

  [types.SET_LIVE_SESSIONS](state, sessions) {
    state.liveSessions = [...sessions];
  },

  [types.SET_STUDENT_SESSIONS](state, studentSessions) {
    state.studentSessions = [...studentSessions];
  },

  [types.SET_SESSION](state, session) {
    state.session = { ...session };
  },

  [types.SET_LIVE_SESSION](state, session) {
    state.liveSession = { ...session };
  },

  [types.SET_STUDENT_ENROLLED](state, { sessionId, status }) {
    const index = state.sessions.findIndex((sessionItem) => sessionItem.id === sessionId);
    if (index >= 0) {
      state.sessions[index].has_student_enrolled = status;
    }
    const liveIndex = state.liveSessions.findIndex((sessionItem) => sessionItem.id === sessionId);
    if (liveIndex >= 0) {
      state.liveSessions[liveIndex].has_student_enrolled = status;
    }
  },

  [types.SET_STARTED](state, { sessionId, status }) {
    if (state.sessions.length <= 0) {
      return;
    }
    const index = state.sessions.findIndex((sessionItem) => sessionItem.id === sessionId);
    if (index < 0) {
      return;
    }
    state.sessions[index].has_started = status;
  },

  [types.SET_SESSION_STARTED](state, status) {
    if (state.session) {
      state.session.has_started = status;
    }
  },

  [types.RESET_SESSIONS](state) {
    state.sessions = [];
  },

  [types.SET_COMPONENT_LOADING_SESSION_LIST](state, isLoading) {
    state.isComponentLoadingSessionList = isLoading;
  },
};
