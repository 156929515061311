import types from './types';

export default {
  setLoading({ commit }, loading) {
    commit(types.SET_LOADING, loading);
  },

  setSidebar({ commit }, sidebar) {
    commit(types.SET_SIDEBAR, sidebar);
  },

  toggleSidebar({ commit }) {
    commit(types.TOGGLE_SIDEBAR);
  },

  resetSidebar({ commit }) {
    commit(types.RESET_SIDEBAR);
  },

  setInnerWidth({ commit }, innerWidth) {
    commit(types.SET_INNER_WIDTH, innerWidth);
  },

  setOffsetTop({ commit }, offset) {
    commit(types.SET_OFFSET_TOP, offset);
  },

  setOffsetBottom({ commit }, offset) {
    commit(types.SET_OFFSET_BOTTOM, offset);
  },
};
