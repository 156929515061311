import { randomFixedInteger } from '~/core/utils/numbers';

export default class SectionEntity {
  constructor(json) {
    this.id = json.id ?? randomFixedInteger(10);
    this.section_id = json.section_id ?? null;
    this.parent_id = json.parent_id ?? null;
    this.title = json.title ?? '';
    this.combined_title = json.combined_title ?? '';
    this.slug = json.slug ?? '';
    this.periodic_table = json.periodic_table ?? 0;
    this.calculator = json.calculator ?? 0;
    this.reference_sheet = json.reference_sheet ?? 0;
    this.annotate = json.annotate ?? 0;
    this.time_allotted = json.time_allotted ?? 0;
    this.duration_in_secs = json.duration_in_secs ?? 0;
    this.number_of_questions = json.number_of_questions ?? 0;
    this.type = json.type ?? null;
    this.order = json.order ?? 0;
    this.description = json.description ?? null;
    this.can_study = json.can_study ?? true;

    /** Custom Properties */
    this.component = json.component ?? null;
  }
}
