import { defaultState } from './state';
import types from './types';

export default {
  [types.SET_CLASS](state, cls) {
    state.fetchedClass = { ...cls };
  },

  [types.SET_PAYMENT_METHOD](state, paymentType) {
    state.paymentMethod = paymentType;
  },

  [types.SET_PAYMENT_INTENT](state, intent) {
    state.paymentIntent = intent;
  },

  [types.SET_PAYMENT_INTENT_SECRET](state, secret) {
    state.paymentIntentSecret = secret;
  },

  [types.SET_STUDENT_ENROLLED](state, enrolled) {
    state.studentEnrolled = enrolled;
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },
};
