export const findTagById = (id, tags) => {
  let tagToFind;

  const findTag = (id, tags) => {
    tags.forEach((tag) => {
      if (tag.id === id) {
        tagToFind = tag;
      }
      if (tag.children.length) {
        findTag(id, tag.children);
      }
    });
  };

  findTag(id, tags);

  return tagToFind;
};

export default {
  deck: (state) => state.deck,
  flattedTags: (state, getters) => {
    return (withNoSubdeckWithNoCard = false) => {
      if (getters.hasNoSubdeckAnyActiveCard || withNoSubdeckWithNoCard) {
        return state.tags.flatted;
      }
      return state.tags.flatted.filter((tag) => {
        if (tag.title === 'No Subdeck') {
          return false;
        } else {
          return !tag.title?.startsWith('No Subdeck -> ');
        }
      });
    };
  },
  deckTags: (state, getters) => {
    if (getters.hasNoSubdeckAnyActiveCard || !getters.flattedTags().length) {
      return state.tags.nested;
    }
    return state.tags.nested.filter((tag) => tag.flatten_title !== 'No Subdeck');
  },
  hasNoSubdeckAnyActiveCard: (state) => {
    return !!state.tags.flatted.find((tag) => tag.title === 'No Subdeck' && tag.totalCardsBasedOnPermission > 0);
  },
  forceRerender: (state) => state.tags.forceRerender,
  totalTagsLength: (_, getters) => getters.flattedTags().length,
  totalCards: (state) => state.cards?.meta?.total || 0,
  cardCurrentPage: (state) => state.cards?.meta?.current_page || 0,
  cardMeta: (state) => state.cards?.meta,
  cardPerPage: (state) => state.cards?.meta?.per_page || 0,
  lastCardPage: (state) => state.cards?.meta?.last_page || 0,
  deckCards: (state) => state.cards?.data || [],
  getCardById: (state) => (cardId) => state.cards?.data?.find((card) => card.id === cardId) ?? null,
  getTagById: (state) => (tagId) => findTagById(tagId, state.tags.nested),
  isUnlimitedStudyMode: (state) => state.isUnlimitedStudyMode,
  isLoadingMode: (state) => state.isLoadingMode,
  hasUserStartedDeckStudy: (state) => !!(state.deck?.daily_goal ?? 0),
  permissions: (state) => state.deck?.permissions || [],
  hasPermission: (state) => (permission) => (state.deck?.permissions || []).includes(permission),
  isNewCardMode: (state) => state.newCardMode,
  isImportDeckMode: (state) => state.isImportDeckMode,
  cardTypes: (state) => state.cardTypes,
  cardModels: (state) => state.cardTypes,
  isCardsInitialized: (state) => !!state.cards,
  cardsFilter: (state) => state.cardsFilter,
  cardFilterObject: (state) => state.cardFilterObject ?? {},
  activeCardModel: (state) => state.activeCardModel,
  cardFilterApiQuery: (_, getters) => {
    if (Object.keys(getters.cardFilterObject).length === 0) {
      return null;
    }
    const params = getters.cardFilterObject;
    const query = {};

    Object.keys(params).forEach((filterKey) => {
      if (filterKey === 'card') {
        return;
      }

      if (params[filterKey]) {
        if (!['page', 'card', 'query'].includes(filterKey)) {
          // Replace Key Names
          if (filterKey === 'status') {
            // publish status
            if (params[filterKey].includes('draft')) {
              query.publish_status = 'draft';
            } else if (params[filterKey].includes('active')) {
              query.publish_status = 'active';
            } else {
              delete query.publish_status;
            }

            // Suspended
            if (params[filterKey].includes('suspended')) {
              query.suspended = 1;
            } else if (params[filterKey].includes('not-suspended')) {
              query.suspended = 0;
            } else {
              delete query.suspended;
            }

            // Flagged
            if (params[filterKey].includes('flagged')) {
              query.flagged = 1;
            } else {
              delete query.flagged;
            }

            // Blocked
            if (params[filterKey].includes('blocked')) {
              query.blocked = 1;
            } else {
              delete query.blocked;
            }
          } else {
            query[filterKey] = params[filterKey].join(',');
          }
        } else {
          query[filterKey] = params[filterKey];
        }
      }
    });
    return query;
  },
  cardFieldsOrdered: (state) => (typeName) => {
    const fields = state.cardTypes.find((type) => type.name === typeName)?.fields || [];
    return [...fields].sort((a, b) => a.ord - b.ord);
  },
};
