
export default {
  data: () => ({
    show: false,
    dynamicComponentName: null,
    dynamicProps: {},
    wrapperClasses: null,
  }),

  mounted() {
    window.addEventListener('keyup', this.onKeyup);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup);
  },

  methods: {
    open(componentName, props = {}) {
      this.show = true;
      this.dynamicComponentName = componentName;
      this.dynamicProps = Object.assign({}, props);
      this.wrapperClasses = props?.wrapperClasses ?? null;
    },

    close() {
      this.show = false;
    },

    onKeyup(event) {
      if (!this.show) {
        return;
      }
      if (event.key === 'Escape') {
        this.close();
      }
    },
  },
};
