import Vue from 'vue';
import JwPlugin from '@jackwestin/uikit';

export default ({ $axios, $auth }) => {
  Vue.use(JwPlugin);

  setTimeout(() => {
    JwPlugin.provideConfigs({
      axios: $axios,
      user: $auth?.user,
    });
  }, 400);
};
