
import { getOtpErrorMessage } from '~/core/services/mobile-otp/otpDTOs';

const TIMER_DEFAULT_VALUE = 30;

export default {
  provide() {
    return {
      sendOtpCode: this.sendOtpCode,
    };
  },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    activeStepIndex: 0,
    lastPhoneNumberUsed: '',
    form: {
      phone_number: '',
      test_date: '',
      activationCode: '',
      termsAreAccepted: false,
    },
    timer: TIMER_DEFAULT_VALUE,
    loading: false,
    domObserver: null,
    recaptchaCanceledCount: 0,
  }),

  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex] ?? null;
    },
    timerIsAvailable() {
      return this.timer > 0 && this.timer < TIMER_DEFAULT_VALUE;
    },
    phoneNumberIsChanged() {
      return this.form.phone_number && this.lastPhoneNumberUsed === this.form.phone_number;
    },
    isTimerAvailableAndPhoneNumberNotChanged() {
      return this.timerIsAvailable && this.phoneNumberIsChanged && this.activeStepIndex === 0;
    },
    isFirstStepAndRecaptchaCanceled() {
      return this.activeStepIndex === 0 && this.recaptchaCanceledCount > 0;
    },
  },

  mounted() {
    this.form = {
      ...this.form,
      phone_number: this.$auth?.user?.unverified_phone_number || '',
      test_date: this.$auth?.user?.test_date || '',
    };
    this.$services.user.initialOtpService('auth-btn');
    this.domObserver = new MutationObserver(() => {
      const iframe = document.querySelector('iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]');
      if (iframe) {
        const captchaBackground = iframe.parentNode?.parentNode?.firstChild;
        captchaBackground?.addEventListener('click', this.captchaBackgroundClickHandler);
      }
    });
    this.domObserver.observe(document.documentElement || document.body, { childList: true, subtree: true });
  },

  destroyed() {
    this.domObserver?.disconnect();
  },

  methods: {
    captchaBackgroundClickHandler() {
      this.recaptchaCanceledCount++;
      this.loading = false;
      this.$nuxt.$emit('recaptcha_canceled');
    },
    async sendOtpCode() {
      const phoneNumberExists = await this.$services.user.phoneNumberExists(this.form.phone_number);

      if (phoneNumberExists !== false) {
        this.$jwToast.error(
          phoneNumberExists === null ? 'Unexpected error.' : 'The given phone number already exists.',
        );

        return Promise.reject(new Error('Failed to lookup the given phone number'));
      }

      return new Promise((resolve, reject) => {
        this.$services.user
          .sendOtpCode(this.form.phone_number)
          .then(() => {
            this.resetTimer();
            resolve();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
            const errorMessage = getOtpErrorMessage(err.OTP_ERROR) || 'Something went wrong';
            this.$jwToast.error(errorMessage);
            reject(err);
          });
      });
    },
    async setNext() {
      if (this.isTimerAvailableAndPhoneNumberNotChanged) {
        this.activeStepIndex++;
      } else {
        this.lastPhoneNumberUsed = this.form.phone_number;
        try {
          this.loading = true;
          await this.$refs.component.$refs.stepComponent.handler();
          if (this.isFirstStepAndRecaptchaCanceled) {
            this.recaptchaCanceledCount--;
            return;
          }
          this.resetTimer();
          this.activeStepIndex++;
          this.recaptchaCanceledCount = 0;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
    },
    setBack() {
      this.activeStepIndex = 0;
    },
    resetTimer() {
      this.timer = TIMER_DEFAULT_VALUE;
    },
  },
};
