const API_V2 = '/api/v2';
const BASE_QUIZ_API_PATH_V2 = `${API_V2}/daily/qbank/test`;
const API_V3 = '/api/v3';
const BASE_QUIZ_API_PATH = `${API_V3}/daily/tests`;

export default class QuizApi {
  constructor(axios) {
    this.$axios = axios;
  }

  fetchQuiz(testId) {
    return this.$axios.get(`${BASE_QUIZ_API_PATH}/${testId}`);
  }

  saveHighlight(dailyId, payload) {
    return this.$axios.post(`${BASE_QUIZ_API_PATH}/${dailyId}/highlights`, payload);
  }

  fetchPassagesAndQuestions({ testId, sectionId }) {
    return this.$axios.get(`${BASE_QUIZ_API_PATH}/${testId}/sections/${sectionId}/study`);
  }

  fetchSectionIdByQuestion(testId, questionId) {
    return this.$axios.get(`${BASE_QUIZ_API_PATH}/${testId}/questions/${questionId}`);
  }

  fetchHighlights(testId) {
    return this.$axios.get(`/api/v3/daily/tests/${testId}/highlights`);
  }

  syncBackgroundTimer(testId, sectionId) {
    return this.$axios.post(
      `${BASE_QUIZ_API_PATH}/${testId}`,
      {
        section_id: sectionId,
      },
      {
        skipLoadingIndicator: true,
      },
    );
  }

  finishTest(testId) {
    return this.$axios.post(`/api/v3/daily/tests/${testId}/finish`);
  }

  setQuestionAnswer({ testId, questionId, timeOnQuestion, answer, marked }) {
    return this.$axios.post(`${BASE_QUIZ_API_PATH}/${testId}/question/${questionId}/answer`, {
      time_on_question: timeOnQuestion,
      answer,
      marked,
    });
  }

  finishSection(testId, sectionId, examSectionId) {
    return this.$axios.post(`/api/v3/daily/tests/${testId}/sections/${sectionId}/finish`, {
      exam_section_id: examSectionId,
    });
  }

  fetchAnalysis(testId) {
    return this.$axios.get(`${BASE_QUIZ_API_PATH_V2}/${testId}/analysis`);
  }

  updateTestDuration(testId, sectionId, duration_in_secs) {
    return this.$axios.put(`${BASE_QUIZ_API_PATH}/${testId}`, {
      sections: {
        [sectionId]: { duration_in_secs },
      },
    });
  }

  fetchFeedbacks(testId) {
    return this.$axios.get(`${BASE_QUIZ_API_PATH}/${testId}/feedback`);
  }

  updateMetadataDelay(testId, delay) {
    return this.$axios.put(`${BASE_QUIZ_API_PATH}/${testId}`, {
      delay,
    });
  }
}
