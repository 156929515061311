import { render, staticRenderFns } from "./modal-confirm.vue?vue&type=template&id=3fd8c4b7"
import script from "./modal-confirm.vue?vue&type=script&lang=js"
export * from "./modal-confirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardPanel: require('/app/components/ui/card/card-panel.vue').default})
