export default {
  fetchedTier: (state) => state.fetchedTier,
  isStudentEnrolled: (state) => state.fetchedTier.has_student_enrolled,
  studentEnrolled: (state) => state.studentEnrolled,
  hasInstallment: (state) => state.fetchedTier.has_installment,
  paymentIntent: (state) => state.paymentIntent,
  paymentIntentSecret: (state) => state.paymentIntentSecret,
  paymentMethodType: (state) => {
    return state.fetchedTier.has_installment ? 'TIER_INSTALLMENT' : 'TIER_FULL_PURCHASE';
  },
  paymentMethod: (state) => state.paymentMethod,
  installmentPrice: (state) => {
    if (!state.fetchedTier.installment_quantity || !state.fetchedTier.pricing) {
      return 0;
    }
    return state.fetchedTier.pricing / state.fetchedTier.installment_quantity;
  },
  amountToPay: (state) => {
    return state.paymentMethod === 'TIER_FULL_PURCHASE'
      ? state.fetchedTier.pricing
      : state.fetchedTier.pricing / state.fetchedTier.installment_quantity;
  },
};
