import moment from 'moment';
import { DISCOUNT_TYPES } from '../utils/constants';

export default class DiscountService {
  constructor(context) {
    this.context = context;
  }

  async enabled(campaignableType, campaignableId) {
    const { data } = await this.context.$api.discount.enabled(campaignableType, campaignableId);
    return data;
  }

  async check(paymentIntent, code, campaignableType, campaignableId) {
    const { data } = await this.context.$api.discount.check(paymentIntent, code, campaignableType, campaignableId);
    return data;
  }

  apply(paymentIntent, code) {
    return this.context.$api.discount.apply(paymentIntent, code);
  }

  enroll(payload) {
    const utmQueryObject = this.context.$services.utm.getUtmQueryObject();
    return this.context.$api.discount.enroll(payload, utmQueryObject);
  }

  applyDiscountOnPrice(discountType, discountValue, _price) {
    const price = (+_price).toFixed(2);
    if (discountType === DISCOUNT_TYPES.FIXED) {
      return +price >= (+discountValue).toFixed(2) ? +price - (+discountValue).toFixed(2) : 0;
    }
    const amount = +price - (+price * discountValue) / 100;
    return amount || 0;
  }

  getSavePrice(discountType, discountValue, price) {
    return discountType === DISCOUNT_TYPES.FIXED ? discountValue : ((+price).toFixed(2) * discountValue) / 100;
  }

  updateTablePrice(tablePrice, discount, fetchedClass) {
    if (!discount.enabled) {
      return tablePrice;
    }
    const totalPrice = fetchedClass.pricing.toFixed(2);
    const installmentQuantity = fetchedClass.installment_quantity;
    const full = {
      ...tablePrice.full,
      discountEnabled: true,
      finalAmount: this.applyDiscountOnPrice(discount.type, discount.value, totalPrice),
    };

    const dates = [];
    for (let index = 1; index <= installmentQuantity; index++) {
      dates.push(
        moment()
          .add((index - 1) * 7, 'days')
          .format('MMM DD, YYYY'),
      );
    }

    const installments = tablePrice.installments.map((installment, index) => {
      const amount = this.everyInstallmentPrice(totalPrice, discount, installmentQuantity);
      return {
        ...installment,
        date: dates[index],
        discountEnabled: true,
        finalAmount: amount,
      };
    });

    return {
      full,
      installments,
    };
  }

  everyInstallmentPrice(totalPrice, discount, installmentQuantity) {
    let amount;
    if (discount.type === DISCOUNT_TYPES.FIXED) {
      amount = (totalPrice - discount.value.toFixed(2)) / installmentQuantity;
    } else {
      amount = (totalPrice - (totalPrice * discount.value) / 100) / installmentQuantity;
    }
    amount = amount > 0 ? amount.toFixed(2) : 0;
    return amount;
  }

  cancel(paymentIntent) {
    return this.context.$api.discount.cancel(paymentIntent);
  }
}
