import { randomFixedInteger } from '~/core/utils/numbers';

export const passageType = {
  PASSAGE: 'PASSAGE',
  DISCRETESET: 'DISCRETESET',
};

export default class PassageEntity {
  constructor(json) {
    this.id = json.id ?? randomFixedInteger(10);
    this.title = json.title ?? null;
    this.passage = json.passage ?? null;
    this.passage_type = json.passage_type ?? null;
    this.sort_order = json.sort_order ?? 0;

    /** Custom Properties */
    this.component = json.component ?? null;
    this.received_from_server = json.received_from_server ?? true;
  }

  get isEmpty() {
    const pureText = this.passage.replace(/(<([^>]+)>)/gi, '');
    return ['', '[HIDDEN_PASSAGE]'].includes(pureText);
  }

  get isDiscreteSet() {
    return this.passage_type === passageType.DISCRETESET;
  }

  get hasContent() {
    return !this.isEmpty && !this.isDiscreteSet;
  }
}
