
import moment from 'moment';
import Calendar from 'v-calendar';
import { randomFixedInteger } from '~/core/utils/numbers';

export default {
  name: 'JWDateInput',
  components: {
    DatePicker: Calendar.DatePicker,
  },
  inheritAttrs: false,
  props: {
    minDate: {
      type: [String, Date, null],
      default: null,
    },
    error: {
      type: [String, null],
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: [String, null],
      default: null,
    },
  },

  computed: {
    inputId() {
      return `input-${randomFixedInteger()}`;
    },
  },

  methods: {
    isTestDateValid() {
      return moment(this.value, 'MM/DD/YYYY', true).isValid();
    },
  },
};
