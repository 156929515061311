const defaultClickEvent = (elementName, location, eventCategory) => {
  const locations = Array.isArray(location) ? location : [location];
  return locations.map((elementLocation) => {
    return {
      name: `${elementName}__${elementLocation}__${eventCategory}`,
      params: {
        element_name: elementName,
        element_location: elementLocation,
        event_category: eventCategory,
      },
    };
  });
};

export default {
  clickEvents: [
    ...defaultClickEvent('logo', 'navbar', 'navigation'),
    ...defaultClickEvent('exam_type', 'navbar', 'navigation'),
    ...defaultClickEvent('home', ['sidebar', 'navbar'], 'navigation'),
    ...defaultClickEvent('courses', ['sidebar', 'navbar'], 'navigation'),
    ...defaultClickEvent('live_sessions', ['sidebar', 'navbar', 'learn_page'], 'navigation'),
    ...defaultClickEvent('tutoring', ['sidebar', 'navbar'], 'navigation'),
    ...defaultClickEvent('admission', ['sidebar', 'navbar'], 'navigation'),
    ...defaultClickEvent('mcat_qbank', 'sidebar', 'navigation'),
    ...defaultClickEvent('aamc_chrome_extension', 'sidebar', 'navigation'),
    ...defaultClickEvent('flashcard', ['sidebar', 'main_page'], 'navigation'),
    ...defaultClickEvent('about_jackwestin', ['sidebar', 'main_page'], 'navigation'),
    ...defaultClickEvent('see_more_toggle', 'sidebar', 'navigation'),
    ...defaultClickEvent('upcoming', 'live_sessions_page', 'navigation'),
    ...defaultClickEvent('counted_in', 'live_sessions_page', 'navigation'),
    ...defaultClickEvent('dates', 'live_sessions_page', 'navigation'),
    ...defaultClickEvent('upcoming_classes', 'course_page', 'navigation'),
    ...defaultClickEvent('faq', ['sidebar', 'course_page'], 'navigation'),
    ...defaultClickEvent('team', 'course_page', 'navigation'),
    ...defaultClickEvent('see_services', ['tutoring_page', 'admission_page'], 'navigation'),
    ...defaultClickEvent('upgrade_option', ['learn_page_sidebar', 'learn_page_tab'], 'navigation'),
    ...defaultClickEvent('knowledge_base', 'learn_page_tab', 'navigation'),
    ...defaultClickEvent(
      'lessons',
      ['learn_page_sidebar', 'learn_page_tab', 'learn_page_back_forward_arrow'],
      'navigation',
    ),
    ...defaultClickEvent('full_screen', 'learn_page_content', 'usability'),
    ...defaultClickEvent('close_sidebar', ['learn_page_content', 'learn_page_sidebar'], 'usability'),
    ...defaultClickEvent('highlight', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('strikethrough', 'mcat_exam_test_ui', 'usability'),
    ...defaultClickEvent('flag', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('setting', 'dat_exam_test_ui', 'usability'),
    ...defaultClickEvent('show_comments', 'exam_test_ui', 'usability'),
    ...defaultClickEvent('next', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('previous', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('pause', 'mcat_exam_test_ui', 'usability'),
    ...defaultClickEvent('timer', 'mcat_exam_test_ui', 'usability'),
    ...defaultClickEvent('navigation', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('exhibit', 'dat_exam_test_ui', 'usability'),
    ...defaultClickEvent('periodic_table', 'mcat_exam_test_ui', 'usability'),
    ...defaultClickEvent('end', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('end_test', ['dat_exam_test_ui', 'mcat_exam_test_ui'], 'usability'),
    ...defaultClickEvent('daily_passage_card_comment', 'daily_passage_card', 'usability'),
  ],
  customEvents: [
    {
      name: 'config',
      params: { user_id: '%' },
    },
    {
      name: 'deck_keep_study',
      params: { deck: '%' },
    },
    {
      name: 'flashcard_deck_unsubscribe',
      params: { deck: '%' },
    },
    {
      name: 'flashcard_deck_subscribe',
      params: { deck: '%' },
    },
    {
      name: 'flashcard_create_deck',
      params: {},
    },
    {
      name: 'flashcard_create_subdeck',
      params: {
        state: '%',
      },
    },
    {
      name: 'flashcard_import_deck',
      params: {},
    },
    {
      name: 'flashcard_study',
      params: {
        deck_title: '%',
        daily_goal: '%',
        study_order: '%',
      },
    },
    {
      name: 'flashcard_create_card',
      params: {},
    },
    {
      name: 'view_item',
      params: {
        ecommerce: {
          currency: '%',
          value: '%',
          items: [
            {
              item_id: '%',
              item_name: '%',
              affiliation: '%',
              item_category: '%',
              item_category2: '%',
              item_status: '%',
              price: '%',
              index: '%',
            },
          ],
        },
      },
    },
    {
      name: 'begin_checkout',
      params: {
        ecommerce: {
          currency: '%',
          value: '%',
          items: [
            {
              item_id: '%',
              item_name: '%',
              affiliation: '%',
              item_category: '%',
              item_category2: '%',
              price: '%',
              index: 0,
            },
          ],
        },
      },
    },
    {
      name: 'purchase',
      params: {
        ecommerce: {
          currency: '%',
          transaction_id: '%',
          value: '%',
          coupon: '%',
          items: [
            {
              item_id: '%',
              item_name: '%',
              affiliation: '%',
              item_category: '%',
              item_category2: '%',
              price: '%',
              discount: '%',
              coupon: '%',
              index: 0,
            },
          ],
        },
      },
    },
    {
      name: 'sign_up',
      params: { sign_up_method: '%', successful: '%' },
    },
    {
      name: 'sign_in',
      params: { sign_in_method: '%', successful: '%' },
    },
    {
      name: 'verify_phone_number',
      params: { target: '%' },
    },
    {
      name: 'live_session_enrollment',
      params: { live_session_type: '%', user_status: '%' },
    },
    {
      name: 'call_or_text',
      params: { user_status: '%' },
    },
    {
      name: 'book_trial_session',
      params: {},
    },
    {
      name: 'tutoring_usage',
      params: {},
    },
    {
      name: 'admission_consulting_usage',
      params: {},
    },
    {
      name: 'profile_set_notification',
      params: { notification_title: '%', notification_status: '%' },
    },
    {
      name: 'learn_page_edit_study_schedule',
      params: {},
    },
    {
      name: 'learn_page_generate_study_schedule',
      params: {},
    },
    {
      name: 'learn_page_disable_study_schedule',
      params: {},
    },
    {
      name: 'learn_page_study_schedule_usage',
      params: {},
    },
    {
      name: 'subscribe_daily_email',
      params: {},
    },
    {
      name: 'score_calculator_usage',
      params: {},
    },
    {
      name: 'exam_view_solution',
      params: { user_status: '%' },
    },
    {
      name: 'qbank_create_test',
      params: {
        question_type: '%',
        question_pool: '%',
        subject: '%',
        number_of_questions: '%',
        'test_setting.solution': '%',
        'test_setting.time': '%',
        user_status: '%',
        successful: '%',
      },
    },
    {
      name: 'qbank_diagnostic_usage',
      params: { subject: '%', successful: '%', user_status: '%' },
    },
    {
      name: 'daily_passage_usage',
      params: { today_daily_passage: '%', user_status: '%', passage_section: '%', passage_type: '%' },
    },
    {
      name: 'daily_passage_filter_usage',
      params: {
        section: '%',
        topics: '%',
        month: '%',
        saved: '%',
      },
    },
    {
      name: 'cars_practice_exam',
      params: { exam_title: '%' },
    },
    {
      name: 'khan_academy_usage',
      params: { test_title: '%' },
    },
    {
      name: 'daily_passage_finish',
      params: { today_daily_passage: '%', user_status: '%' },
    },
    {
      name: 'daily_passage_timer',
      params: { time: '%', today_daily_passage: '%', user_status: '%' },
    },
    {
      name: 'test_review',
      params: { test_title: '%' },
    },
    {
      name: 'delete_test',
      params: { test_title: '%' },
    },
    {
      name: 'exam_usage',
      params: { exam_title: '%' },
    },
    {
      name: 'exam_finish',
      params: { exam_title: '%' },
    },
    {
      name: 'daily_passage_watch_video_analaysis',
      params: {},
    },
    {
      name: 'wait_list_subscribe',
      params: {},
    },
  ],
};
