
export default {
  name: 'NavbarSidebarShowMore',
  props: {
    enrolled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowMore: false,
    };
  },

  methods: {
    toggleSeeMore() {
      this.isShowMore = !this.isShowMore;
    },
  },
};
