export default {
  SET_SESSIONS: 'SET_SESSIONS',
  SET_LIVE_SESSIONS: 'SET_LIVE_SESSIONS',
  SET_STUDENT_SESSIONS: 'SET_STUDENT_SESSIONS',
  SET_SESSION: 'SET_SESSION',
  SET_LIVE_SESSION: 'SET_LIVE_SESSION',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_MORE_SESSIONS: 'SET_MORE_SESSIONS',
  SET_LESS_SESSIONS: 'SET_LESS_SESSIONS',
  SET_STUDENT_ENROLLED: 'SET_STUDENT_ENROLLED',
  SET_STARTED: 'SET_STARTED',
  SET_SESSION_STARTED: 'SET_SESSION_STARTED',
  RESET_SESSIONS: 'RESET_SESSIONS',
  SET_COMPONENT_LOADING_SESSION_LIST: 'SET_COMPONENT_LOADING_SESSION_LIST',
};
