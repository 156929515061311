
import eventBus from './eventBus';
import Timer from './timer';
import './styles.css';

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    icon: {
      type: [String, null],
      default: null,
    },
    duration: {
      type: Number,
      default: 4000,
    },
    maxNumberToShow: {
      type: Number,
      default: 6,
    },
    closeOnMouseLeave: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    parentTopContainer: null,
    isActive: false,
    isHovered: false,
  }),
  computed: {
    iconToShow() {
      if (this.icon) {
        return this.icon;
      }
      switch (this.type) {
        case 'success':
          return require('~/plugins/jw-toast/icons/success.svg');
        case 'error':
          return require('~/plugins/jw-toast/icons/error.svg');
        case 'warning':
          return require('~/plugins/jw-toast/icons/warning.svg');
        default:
          return null;
      }
    },
  },
  beforeMount() {
    this.setupContainer();
  },
  mounted() {
    this.showNotice();
    eventBus.$on('toast-clear', this.dismiss);
  },
  beforeDestroy() {
    eventBus.$off('toast-clear', this.dismiss);
  },
  methods: {
    setupContainer() {
      this.parentTopContainer = document.querySelector('.jw-toast');
      if (this.parentTopContainer) {
        return;
      }
      if (!this.parentTopContainer) {
        this.parentTopContainer = document.createElement('div');
        this.parentTopContainer.className = 'jw-toast';
      }
      const container = document.body;
      container.appendChild(this.parentTopContainer);
    },
    showNotice() {
      if (this.maxNumberToShow === this.parentTopContainer.childElementCount) {
        this.parentTopContainer.removeChild(this.parentTopContainer.lastChild);
      }
      this.parentTopContainer.insertAdjacentElement('afterbegin', this.$el);
      this.isActive = true;
      this.timer = new Timer(this.dismiss, this.duration);
    },
    dismiss() {
      if (this.timer) {
        this.timer.stop();
      }
      this.isActive = false;
      setTimeout(() => {
        this.$destroy();
        this.removeElement(this.$el);
      }, 150);
    },
    removeElement(el) {
      if (typeof el.remove !== 'undefined') {
        el.remove();
      } else {
        el.parentNode.removeChild(el);
      }
    },
    toggleTimer(newVal) {
      if (!this.timer || !this.closeOnMouseLeave) {
        return;
      }
      newVal ? this.timer.pause() : this.timer.resume();
    },
    onToastItemClicked() {
      if (this.closable) {
        this.dismiss();
      }
    },
  },
};
