
import me from '~/middleware/me';

export default {
  data() {
    return {
      verifyTarget: null,
      isModalLoginShow: false,
      isAccountVerifyModalVisible: false,
      pipeLineIdForVerify: null,
      isModalTypeRegister: false,
      verifyAdditionalInfoDescription: null,
    };
  },

  watch: {
    isModalLoginShow(value) {
      if (!value) {
        me({ $axios: this.$axios, $auth: this.$auth });
      }
    },
  },

  created() {
    this.listenToShowDialogRequest();
  },

  methods: {
    onAccountVerifyModalClosed() {
      this.isAccountVerifyModalVisible = false;
      this.$nuxt.$off(this.$config.event.chain_succeeded);
    },
    onLoginModalClosed() {
      this.isModalLoginShow = false;
      this.$nuxt.$off(this.$config.event.chain_succeeded);
    },
    async listenToShowDialogRequest() {
      await this.$nuxt.$on(this.$config.event.show_login, (param) => {
        this.isModalTypeRegister = param.mode === 'signup';
        this.isModalLoginShow = param.value;
      });

      this.$nuxt.$on(
        this.$config.event.show_verify_mobile_modal,
        ({ show, target = 'sign_up', additionalInfoDescription = null }) => {
          this.isAccountVerifyModalVisible = show;
          this.verifyTarget = target;
          this.verifyAdditionalInfoDescription = additionalInfoDescription;
        },
      );
    },

    onLoginSuccess() {
      this.isModalLoginShow = false;
      this.$nuxt.$emit(this.$config.event.chain_succeeded, true);
    },

    onVerifySuccess() {
      this.isAccountVerifyModalVisible = false;
      this.$gtmEvent.dispatch('verify_phone_number', {
        target: this.verifyTarget,
      });
      this.$nuxt.$emit(this.$config.event.chain_succeeded, true);
    },
  },
};
