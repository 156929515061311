export default {
  SET_DECKS: 'SET_DECKS',
  SET_DECK: 'SET_DECK',
  SET_DECK_CARDS: 'SET_DECK_CARDS',
  IS_LOADING_MODE: 'IS_LOADING_MODE',
  IS_IMPORT_MODE: 'IS_IMPORT_MODE',
  CHANGE_CARD_SUSPENDED_STATUS: 'CHANGE_CARD_SUSPENDED_STATUS',
  CHANGE_CARD_FLAGGED_STATUS: 'CHANGE_CARD_FLAGGED_STATUS',
  UPDATE_CARD: 'UPDATE_CARD',
  DELETE_CARD: 'DELETE_CARD',
  UPDATE_TAG: 'UPDATE_TAG',
  ADD_EMPTY_NEW_CARD: 'ADD_EMPTY_NEW_CARD',
  REMOVE_CREATE_CARD: 'REMOVE_CREATE_CARD',
  SET_FLATTED_TAGS: 'SET_FLATTED_TAGS',
  SET_NESTED_TAGS: 'SET_NESTED_TAGS',
  SET_NEW_CARD_MODE: 'SET_NEW_CARD_MODE',
  UPDATE_BULK_TAGS: 'UPDATE_BULK_TAGS',
  UPDATE_CARD_MODEL: 'UPDATE_CARD_MODEL',
  SET_CARD_FILTER_OBJECT: 'SET_CARD_FILTER_OBJECT',
  SET_CARD_FILTER_API_QUERY: 'SET_CARD_FILTER_API_QUERY',
  SET_ACTIVE_CARD_MODEL: 'SET_ACTIVE_CARD_MODEL',
};
