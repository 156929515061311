export default {
  studentPurchases: (state) => state.studentPurchases,
  studentPurchasesTotal: (state) => {
    if (state.studentPurchases.length <= 0) {
      return 0;
    }
    let sum = 0;
    state.studentPurchases.forEach((purchase) => {
      sum += purchase.amount;
    });
    return sum;
  },
  studentCourses: (state) => state.studentCourses,
  studentCourse: (state) => state.studentCourse,
  studentEnrollment: (state) => state.studentEnrollment,
  studentEnrolledToClass: (state) => state.studentEnrollmentClass.length > 0,
  studentCards: (state) => state.studentCards,
  studentPaymentReminds: (state) => {
    return state.studentPaymentDetail?.payment_remind ?? 0;
  },
  studentExtensions: (state) => state.studentExtensions,
  installmentLeft: (state) => {
    return state.studentPaymentDetail?.installments_left ?? 0;
  },
  paymentsDone: (state) => {
    return state.studentPaymentDetail?.payments_done ?? 0;
  },
  studentDefaultCard: (state) => {
    if (state.studentCards.length === 0) {
      return null;
    }
    return state.studentCards.find((card) => card.is_default === true);
  },
  studentDefaultCardPaymentMethod: (_, getters) => {
    return getters?.studentDefaultCard?.payment_method_id ?? null;
  },
  upcomingInvoice: (state) => state.upcomingInvoice,
  studentInfo: (state) => state.user,
  hasStudentExtendedCourse: (state) => state.hasStudentExtendedCourse,
  // Tiers
  studentTiers: (state) => state.studentTiers,
  studentTier: (state) => state.studentTier,
  tierClassEnrollments: (state) => state.tierClassEnrollments,
  isThankyouPageAvailable: (state) => state.isThankyouPageAvailable,
  enabledDiscountCode: (state) => state.enabledDiscountCode,
};
