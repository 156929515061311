
export default {
  data: () => ({
    isProfileOpen: false,
  }),

  computed: {
    isAdmin() {
      return this.$auth.user?.is_admin ?? false;
    },
    isImpersonated() {
      return this.$auth?.user?.impersonate ?? false;
    },
  },

  methods: {
    async logout() {
      this.$nuxt.$loading.start();
      await this.$auth.logout();
      if (process.client) {
        window.location.replace('/');
      }
    },
    open() {
      this.isProfileOpen = !this.isProfileOpen;
    },
    outside() {
      this.isProfileOpen = false;
    },
  },
};
