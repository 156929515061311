
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: {
    CookieBannerWrapper,
  },

  computed: {
    shouldBannerBeHide() {
      const blackListRoutes = ['student-verify'];
      return blackListRoutes.includes(this.$route.name);
    },
  },
};
