import types from './types';

export default {
  setDailyPassageLoading({ commit }, isLoading) {
    commit(types.SET_DAILY_PASSAGES_LOADING, isLoading);
  },

  async fetchDailyPassages({ commit }, params) {
    try {
      commit(types.SET_DAILY_PASSAGES_LOADING, true);
      const {
        data,
        app: { sections },
        meta,
      } = await this.$api.passage.fetchDailyPassages(params);
      commit(types.SET_DAILY_PASSAGES, data);
      commit(types.SET_DAILY_SECTIONS, sections);
      commit(types.SET_DAILY_PASSAGES_META, meta);
      commit(types.SET_DAILY_PASSAGES_LOADING, false);
    } catch (error) {}
  },

  async fetchTodayPassages({ commit }) {
    try {
      commit(types.SET_TODAY_PASSAGES_LOADING, true);
      const { data, streak, is_subscribed } = await this.$api.passage.fetchTodayPassages();
      commit(types.SET_TODAY_PASSAGES, data);
      commit(types.SET_PASSAGES_STREAK, streak);
      commit(types.SET_SUBSCRIBE_BOX_STATUS, !is_subscribed);
      commit(types.SET_TODAY_PASSAGES_LOADING, false);
    } catch (error) {}
  },

  async resetDailyPassage({ commit }, id) {
    try {
      const { data } = await this.$api.passage.resetDailyPassage(id);
      commit(types.RESET_DAILY_PASSAGE, data.id);
    } catch (error) {}
  },

  async subscribe({ commit }, params) {
    try {
      await this.$api.passage.subscribe(params);
      commit(types.SET_SUBSCRIBE_BOX_STATUS, false);
    } catch (error) {}
  },

  async openLesson(_, id) {
    const res = await this.$api.passage.openLesson(id);
    return res;
  },
};
