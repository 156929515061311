
import { mapGetters } from 'vuex';
import styles from './navbar-topbar-col.css?raw';
import styleInjector from '~/core/mixins/styleInjector';

export default {
  mixins: [styleInjector(styles)],
  data() {
    return {
      showHubSideBar: false,
      navs: [
        {
          title: 'Home',
          to: '/',
          icon: 'icon-home',
          gtm_event: 'home__sidebar__navigation',
        },
        {
          title: 'Courses',
          to: '/courses',
          icon: 'icon-classes',
          gtm_event: 'courses__sidebar__navigation',
        },
        {
          title: 'Live Sessions',
          to: '/sessions',
          icon: 'icon-sessions',
          gtm_event: 'live_sessions__sidebar__navigation',
        },
        {
          title: 'Tutoring',
          to: '/services/live-online-mcat-tutoring',
          icon: 'icon-tutoring',
          gtm_event: 'tutoring__sidebar__navigation',
        },
        {
          title: 'Admissions',
          to: '/services/medical-school-admissions-consulting',
          icon: 'icon-admissions',
          gtm_event: 'admission__sidebar__navigation',
        },
        {
          title: 'Analytics',
          to: '/qbank/analysis',
          icon: 'icon-analytics',
          gtm_event: null,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('ui', ['device']),
  },

  methods: {
    toggleHubSideBar() {
      this.showHubSideBar = !this.showHubSideBar;
      this.$emit('toggleHubSidebar', this.showHubSideBar);
    },
  },
};
