export const inRange = (x, min, max) => {
  if (!x) {
    return false;
  }

  return (x - min) * (x - max) <= 0;
};

export const randomInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const randomFixedInteger = function (length = 10) {
  return Math.floor(10 ** (length - 1) + Math.random() * (10 ** length - 10 ** (length - 1) - 1));
};

/**
 * convert number to words, example: 1 ==> 'one'
 * @returns converted string
 * @param {number} number
 */
export const numberToWord = (number) => {
  const words = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
    17: 'seventeen',
    18: 'eighteen',
    19: 'nineteen',
    20: 'twenty',
    30: 'thirty',
    40: 'forty',
    50: 'fifty',
    60: 'sixty',
    70: 'seventy',
    80: 'eighty',
    90: 'ninety',
  };

  if (number < 20) {
    return words[number];
  }

  const digits = number.toString().split('').map(Number);

  if (digits.length === 2) {
    return words[digits[0] * 10] + (digits[1] !== 0 ? '-' + words[digits[1]] : '');
  }

  if (digits.length === 3) {
    const hundreds = words[digits[0]] + ' hundred';
    const tens = numberToWord(digits.slice(1).join(''));

    return tens ? hundreds + ' ' + tens : hundreds;
  }

  if (digits.length === 4) {
    const thousands = words[digits[0]] + ' thousand';
    const hundreds = numberToWord(digits.slice(1).join(''));

    return hundreds ? thousands + ' ' + hundreds : thousands;
  }

  return '';
};
