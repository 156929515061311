
import { mapGetters } from 'vuex';
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('application', ['isPWAMode']),
    headerTitle() {
      return this.error?.customData?.headerTitle ?? "This page isn't available";
    },
  },
  methods: {
    openLink(link) {
      if (this.isPWAMode) {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
  },
};
