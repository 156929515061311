import { randomFixedInteger } from '~/core/utils/numbers';

export default class CourseEntity {
  constructor(json) {
    const requiredKeys = ['id', 'subTitle', 'link', 'nextClass', 'theme'];

    this.id = json.id ?? randomFixedInteger(10);
    this.subTitle = json.subtitle;
    this.link = `/${json.type}s/${json.slug}`;
    this.nextClass = json.classes[0];
    this.theme = {
      startBgColor: json.settings?.theme?.header?.startBgColor,
      endBgColor: json.settings?.theme?.header?.endBgColor,
      buttonBgColor: json.settings?.theme?.header?.buttonBgColor,
    };

    Object.keys(json).forEach((key) => {
      if (!requiredKeys.includes(key)) {
        this[key] = json[key];
      }
    });
  }
}
