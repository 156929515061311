import Vue from 'vue';
import types from './types';
import { sidebarModel } from '~/core/entities/sidebarModel';

export default {
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },

  [types.SET_SIDEBAR](state, payload) {
    state.sidebar = { ...sidebarModel, ...payload };
  },

  [types.RESET_SIDEBAR](state) {
    state.sidebar = Object.assign({}, sidebarModel);
  },

  [types.TOGGLE_SIDEBAR](state) {
    state.sidebar.show = !state.sidebar.show;
  },

  [types.SET_INNER_WIDTH](state, payload) {
    Vue.set(state, 'innerWidth', payload);
  },

  [types.SET_OFFSET_TOP](state, payload) {
    state.offsetTop = payload;
  },

  [types.SET_OFFSET_BOTTOM](state, payload) {
    state.offsetBottom = payload;
  },
};
