import Vue from 'vue';

import clickOutside from './click-outside';
import scrollTo from './scroll-to';
import ripple from './ripple';
import scrollPassed from './scroll-passed';
import permission from './permission';
import shortkey from './shortkey';
import enlargeImages from './enlarge-images';

clickOutside(Vue);
scrollTo(Vue);
ripple(Vue);
scrollPassed(Vue);
permission(Vue);
shortkey(Vue);
enlargeImages(Vue);
