export default function ({ redirect, $auth, route }) {
  if (!$auth.user) {
    return redirect({
      name: 'login',
      query: {
        redirect: route.fullPath,
      },
    });
  }
}
