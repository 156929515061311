import types from './types';
import { defaultState } from './state';
import cloneCourse from '~/core/management/cloneCourse';

export default {
  [types.SET_COURSES](state, payload) {
    state.courses = [...payload];
  },

  [types.SET_COURSE](state, payload) {
    const clonedCourse = cloneCourse(payload);
    state.course = clonedCourse.course;
    state.lessonsOrderSum = clonedCourse.lessonsOrderSum;
    state.flattenLessonsTitle = clonedCourse.flattenLessonsTitle;
  },

  [types.TOGGLE_LESSON_COMPLETED](state, lessonId) {
    for (let i = 0; i < state.course.sections.length; i++) {
      for (let j = 0; j < state.course.sections[i].lessons.length; j++) {
        if (state.course.sections[i].lessons[j].id === lessonId) {
          state.course.sections[i].lessons[j].completed = !state.course.sections[i].lessons[j].completed;
          return;
        }
      }
    }
  },

  [types.TOGGLE_WEEKLY_LESSON_COMPLETED](state, lessonId) {
    const lessonIndex = state.weekMandatoryLessons.findIndex((lesson) => lesson.id === lessonId);
    if (lessonIndex >= 0) {
      state.weekMandatoryLessons[lessonIndex].completed = !state.weekMandatoryLessons[lessonIndex].completed;
    }
  },

  [types.TOGGLE_CLASSIFY_ASSIGNMENTS_LESSON_COMPLETED](state, lessonId) {
    state.liveClassifyAssignments.map((assignment) => {
      return assignment.lessons.map((lesson) => {
        if (lesson.id === lessonId) {
          lesson.completed = !lesson.completed;
        }
        return lesson;
      });
    });
  },

  [types.SET_CLASSES](state, classes) {
    state.classes = [...classes];
  },

  [types.SET_TIERS](state, tiers) {
    state.tiers = [...tiers];
  },

  [types.RESET_TIERS](state) {
    state.tiers = [];
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE](state, isLoading) {
    state.isComponentLoadingLearnPrivate = isLoading;
  },

  [types.SET_COMPONENT_LOADING_SENDY](state, isLoading) {
    state.isComponentLoadingSendy = isLoading;
  },

  [types.SET_COURSE_LESSONS](state, payload) {
    state.courseLessons = payload || [];
  },

  [types.SET_WEEK_MANDATORY_LESSONS](state, payload) {
    state.weekMandatoryLessons = payload || [];
  },

  [types.SET_CLASSIFY_ASSIGNMENTS](state, payload) {
    state.liveClassifyAssignments = payload || [];
  },

  [types.SET_TOPICS](state, payload) {
    state.topics = payload || [];
  },

  [types.SET_SENDY_STATUS](state, isSubscribed) {
    state.isSubscribedToSendy = isSubscribed;
  },

  [types.SET_LATEST_VISITED_LESSON](state, id) {
    state.course.latest_opened_lesson_id = id;
  },

  [types.MODIFY_LESSON_PROPERTY](state, { lessonId, key, value }) {
    for (let i = 0; i < state.course.sections.length; i++) {
      for (let j = 0; j < state.course.sections[i].lessons.length; j++) {
        if (state.course.sections[i].lessons[j].id === lessonId) {
          state.course.sections[i].lessons[j][key] = value;
          return;
        }
      }
    }
  },
};
