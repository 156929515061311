import QuestionEntity from '~/core/entities/qbank-quiz/question.entity';
import QuizInterceptor from './quiz.interceptor';

export class DATInterceptor extends QuizInterceptor {
  constructor(quiz, context) {
    super(quiz);
    this.context = context;
  }

  modifySections() {
    return this;
  }

  modifyQuestions() {
    const activeSection = this.context.store.getters['quiz/activeSection'];

    const infoQuestions = [];
    const tutorialQuestions = [];
    const voidQuestions = [];
    const breakQuestions = [];

    if (activeSection.type === 'info') {
      // initial info section questions
      for (let i = 1; i <= 2; i++) {
        infoQuestions.push(
          new QuestionEntity({
            component: `dat-info-step-${i}`,
            navigable: false,
          }),
        );
      }
    } else if (activeSection.type === 'tutorial') {
      // initial tutorial section questions
      for (let i = 1; i <= 19; i++) {
        tutorialQuestions.push(
          new QuestionEntity({
            component: `dat-tutorial-step-${i}`,
            navigable: false,
          }),
        );
      }
    } else if (activeSection.type === 'break') {
      // initial break section step
      breakQuestions.push(
        new QuestionEntity({
          component: 'dat-break-step',
          navigable: false,
        }),
      );
    } else if (activeSection.type === 'question') {
      const questions = [];

      // add welcome step before starting each question base sections
      // we should add it for the sections that they haven't any child or only for sub-sections
      const isActiveSectionHasChildren = this.quiz.sections.find((section) => section.parent_id === activeSection.id);
      if (isActiveSectionHasChildren) {
        const uniqueSectionIds = {};

        const welcomeStepGenerator = (sectionId) => {
          return new QuestionEntity({
            component: 'dat-welcome-step',
            countable: false,
            navigable: false,
            section_id: sectionId,
          });
        };

        for (let i = 0; i < this.quiz.questions.length; i++) {
          const question = this.quiz.questions[i];
          const sectionId = question.section_id;

          if (!uniqueSectionIds[sectionId]) {
            uniqueSectionIds[sectionId] = true;
            this.quiz.questions.splice(i, 0, welcomeStepGenerator(question.section_id));
            i++;
          }
        }
      } else {
        questions.push(
          new QuestionEntity({
            component: 'dat-welcome-step',
            countable: false,
            navigable: false,
          }),
        );
      }

      // add description step after welcome step when the active section has description
      if (activeSection.description) {
        questions.push(
          new QuestionEntity({
            component: 'dat-section-description-step',
            countable: false,
            navigable: false,
          }),
        );
      }

      // add reading step before starting main questions when the passage has content
      const firstQuestion = this.quiz.questions[0];
      const passage = this.quiz.passages.find((passage) => passage.id === firstQuestion?.passage_id);
      if (passage && passage.hasContent) {
        questions.push(
          new QuestionEntity({
            component: 'dat-reading-passage-step',
            countable: false,
            navigable: false,
            passage_id: firstQuestion.passage_id,
          }),
        );
      }

      // add review questions step after all questions
      questions.push(
        ...this.quiz.questions,
        new QuestionEntity({
          id: 'review-step',
          component: 'dat-review-questions-step',
          countable: false,
          navigable: false,
        }),
      );

      this.quiz = {
        ...this.quiz,
        questions,
      };
    }

    this.quiz = {
      ...this.quiz,
      questions: [...this.quiz.questions, ...infoQuestions, ...tutorialQuestions, ...voidQuestions, ...breakQuestions],
    };

    return this;
  }

  modifyPassages() {
    return this;
  }
}
