export const defaultState = () => ({
  courses: [],
  course: null,
  courseLessons: [],
  weekMandatoryLessons: [],
  classes: [],
  tiers: [],
  testimonials: [],
  lessonsOrderSum: 0,
  flattenLessonsTitle: [],
  topics: [],
  isComponentLoadingCourseLandingPublic: false,
  isComponentLoadingLearnPrivate: false,
  isComponentLoadingSendy: false,
  isSubscribedToSendy: false,
  liveClassifyAssignments: [],
});

export default () => defaultState();
