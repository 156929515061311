
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      blackList: {
        regexes: [/^flashcard/, /^flashcards/, /^exams-tests/],
      },
    };
  },

  computed: {
    ...mapGetters('user', ['userMeta', 'cookieConfiguration']),
    shouldIncludeBanner() {
      if (this.$route.query.ref === 'popup') {
        return false;
      }
      return !this.blackList.regexes.some((blackRegex) => {
        return blackRegex.test(this.$route.name);
      });
    },
    accessCookie() {
      return this.cookieConfiguration.accessCookie;
    },
  },

  methods: {
    ...mapActions('user', ['modifyUserMeta']),
    onUserRespondToBanner(value) {
      this.modifyUserMeta({ key: 'is_trackable', value });
    },
    changeBannerVisibility(status) {
      this.$refs.cookieBanner?.changeBannerVisibility(status);
    },
  },
};
