import QuestionEntity from '~/core/entities/qbank-quiz/question.entity';
import QuizInterceptor from './quiz.interceptor';

export class MCATInterceptor extends QuizInterceptor {
  constructor(quiz, context) {
    super(quiz);
    this.context = context;
  }

  modifySections() {
    return this;
  }

  modifyQuestions() {
    const sectionType = this.context.store.getters['quiz/activeSection'].type;

    const infoQuestions = [];
    const tutorialQuestions = [];
    const voidQuestions = [];

    if (sectionType === 'info') {
      // initial info section questions
      for (let i = 1; i < 4; i++) {
        infoQuestions.push(
          new QuestionEntity({
            component: `quiz-mcat-info-step-${i}`,
            navigable: false,
          }),
        );
      }
    } else if (sectionType === 'tutorial') {
      // initial tutorial section questions
      for (let i = 1; i < 11; i++) {
        const questionContents = {
          2: 'Navigate the Question 2',
          3: 'Select an Answer 3',
          4: 'Use the Scroll Feature 4',
          5: 'Use the Highlight Feature 5',
          6: 'Use the Strikethrough Feature 6',
          7: 'Refer to the Periodic Table 7',
          8: 'Use the Flag for Review Feature 8',
          9: 'Use the Section Review Feature 9',
        };
        tutorialQuestions.push(
          new QuestionEntity({
            component: `quiz-mcat-tutorial-step-${i}`,
            question: questionContents[i] ?? '',
            navigable: [2, 3, 4, 5, 6, 7, 8, 9].includes(i), // set navigable to false for the first and the last steps
          }),
        );
      }
    } else if (sectionType === 'void') {
      // initial void section question
      voidQuestions.push(
        new QuestionEntity({
          component: 'quiz-mcat-void-step',
          navigable: false,
        }),
      );
    } else if (sectionType === 'question') {
      // add a welcome-step to each question-section
      this.quiz = {
        ...this.quiz,
        questions: [
          new QuestionEntity({
            component: 'quiz-mcat-welcome-step',
            countable: false,
            navigable: false,
          }),
          ...this.quiz.questions,
          new QuestionEntity({
            id: 'review-step',
            component: 'quiz-mcat-review-questions-step',
            countable: false,
            navigable: false,
          }),
        ],
      };
    }

    this.quiz = {
      ...this.quiz,
      questions: [...this.quiz.questions, ...infoQuestions, ...tutorialQuestions, ...voidQuestions],
    };
    return this;
  }

  modifyPassages() {
    this.updatePassageTitles();
    return this;
  }
}
