export default function ({ redirect, $auth, route, from, $services }) {
  if (!$auth.user) {
    $services.auth.setLoginRequired(true, route.path);
    if (siteHasBeenLoadedWithThisPageForFirstTime(from, route)) {
      redirect({ name: 'flashcards-browse' });
    } else {
      redirect(from.path);
    }
  }
}

const siteHasBeenLoadedWithThisPageForFirstTime = (from, route) => {
  return from.name === route.name;
};
