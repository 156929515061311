export default {
  SET_TESTS: 'SET_TESTS',
  SET_TEST: 'SET_TEST',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_APPS: 'SET_APPS',
  SET_SECTIONS: 'SET_SECTIONS',
  SET_FOUNDATIONS: 'SET_FOUNDATIONS',
  SET_CACHE: 'SET_CACHE',
  SET_TEST_ANALYSIS: 'SET_TEST_ANALYSIS',
  SET_TEST_RESULT: 'SET_TEST_RESULT',
  SET_BREAKDOWN_BY_SUBJECT: 'SET_BREAKDOWN_BY_SUBJECT',
  SET_BREAKDOWN_BY_DIFFICULTY: 'SET_BREAKDOWN_BY_DIFFICULTY',
  SET_COMPONENT_LOADING_TEST_DETAIL: 'SET_COMPONENT_LOADING_TEST_DETAIL',
  SET_COMPONENT_LOADING_TEST_RESULT: 'SET_COMPONENT_LOADING_TEST_RESULT',
  SET_COMPONENT_LOADING_TEST_ANALYSIS: 'SET_COMPONENT_LOADING_TEST_ANALYSIS',
};
