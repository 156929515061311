export const otpErrors = {
  INVALID_VERIFICATION_CODE: {
    code: 'INVALID_VERIFICATION_CODE',
    message: 'Invalid verification code',
  },
  INVALID_PHONE_NUMBER: {
    code: 'INVALID_PHONE_NUMBER',
    message: 'Invalid phone number',
  },
};

export const getOtpErrorMessage = (errorCode) => {
  return otpErrors[errorCode]?.message || null;
};
