export class PaginateEntity {
  Class = 'Paginate';
  data;
  links;
  meta = {
    current_page: 1,
    from: null,
    last_page: 1,
    path: '/',
    per_page: 15,
    to: null,
    total: 0,
  };

  constructor(json) {
    this.data = json.data;
    this.links = json.links;
    this.meta = json.meta;
  }
}
