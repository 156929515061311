import types from './types';

const cache = {
  fetchTestsParams: {},
};

export default {
  async createTest(_, params) {
    const res = await this.$api.qBank.createTest(params);
    return res;
  },

  createCustomTest(_, hashId) {
    return this.$api.qBank.createCustomTest(hashId);
  },

  async fetchTestBySlug({ commit }, slug) {
    commit(types.SET_COMPONENT_LOADING_TEST_DETAIL, true);
    const { data } = await this.$api.qBank.fetchTestBySlug(slug);
    commit(types.SET_COMPONENT_LOADING_TEST_DETAIL, false);
    return data;
  },

  async fetchTests({ commit }, params) {
    const { data, meta } = await this.$api.qBank.fetchTests(params);
    cache.fetchTestsParams = params;
    commit(types.SET_TESTS, data || []);
    if (meta) {
      commit(types.SET_PAGINATION, meta);
    }
  },

  async fetchApps({ commit }) {
    const { data } = await this.$api.qBank.fetchApps();
    commit(types.SET_APPS, data || []);
  },

  async fetchSections({ commit }, { appId, params }) {
    const { data } = await this.$api.qBank.fetchSections({ appId, params });
    commit(types.SET_SECTIONS, data || []);
  },

  async fetchFoundations({ commit }, { sectionId, params }) {
    const { data } = await this.$api.qBank.fetchFoundations({
      sectionId,
      params,
    });
    commit(types.SET_FOUNDATIONS, data || []);
  },

  async fetchTestAnalysis({ commit }, hashId) {
    commit(types.SET_COMPONENT_LOADING_TEST_ANALYSIS, true);
    const { data } = await this.$api.qBank.fetchTestAnalysis(hashId);
    commit(types.SET_TEST_ANALYSIS, data);
    commit(types.SET_COMPONENT_LOADING_TEST_ANALYSIS, false);
  },

  async fetchTestResult({ commit }, testId) {
    commit(types.SET_COMPONENT_LOADING_TEST_RESULT, true);
    const { data } = await this.$api.qBank.fetchTestResult(testId);
    commit(types.SET_TEST_RESULT, data);
    commit(types.SET_COMPONENT_LOADING_TEST_RESULT, false);
  },

  async fetchBreakdownBuySubject({ commit }, testId) {
    const { data } = await this.$api.qBank.fetchBreakdownBuySubject(testId);
    commit(types.SET_BREAKDOWN_BY_SUBJECT, data);
  },

  async fetchBreakdownBuyDifficulty({ commit }, testId) {
    const { data } = await this.$api.qBank.fetchBreakdownBuyDifficulty(testId);
    const mappedBreakdown = data.map((item) => ({
      ...item,
      name: item.difficulty,
    }));
    commit(types.SET_BREAKDOWN_BY_DIFFICULTY, mappedBreakdown);
  },

  async updateTest({ dispatch }, { testId, params }) {
    await this.$api.qBank.updateTest({ testId, params });
    dispatch('fetchTests', cache.fetchTestsParams);
  },

  resetSections({ commit }) {
    commit(types.SET_SECTIONS, []);
  },

  resetFoundations({ commit }) {
    commit(types.SET_FOUNDATIONS, []);
  },

  resetTestDetail({ commit }) {
    commit(types.SET_TEST, null);
  },

  resetTestResult({ commit }) {
    commit(types.SET_TEST_RESULT, null);
  },

  resetTestAnalysis({ commit }) {
    commit(types.SET_TEST_ANALYSIS, null);
  },

  deleteSingleTest(_, hashId) {
    return this.$api.qBank.deleteSingleTest(hashId);
  },

  deleteSubjectTests(_, subjectId) {
    return this.$api.qBank.deleteSubjectTests(subjectId);
  },

  deleteUserTests() {
    return this.$api.qBank.deleteUserTests();
  },
};
