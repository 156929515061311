/* eslint-disable */
export default {
  hasChild(base, child) {
    let el = child;
    while (el) {
      if (el === base) {
        return true;
      }
      el = el.parentNode;
    }
    return false;
  },
  wrap(element) {
    const newSpan = document.createElement('span');
    newSpan.appendChild(document.createTextNode(element.nodeValue));
    element.parentElement.replaceChild(newSpan, element);
    return newSpan;
  },
  unwrap(element) {
    const childNodes = element.childNodes;
    if (childNodes.length === 0) {
      element.remove();
    }
    let first = true;
    let before;
    while (childNodes.length > 0) {
      if (first) {
        before = childNodes[childNodes.length - 1];
        element.parentNode.replaceChild(before, element);
        first = false;
      } else {
        before.parentNode.insertBefore(childNodes[0], before);
      }
    }
    if (before && before.parentNode.normalize) {
      before.parentNode.normalize();
    }
  },
  split(el, index, endIndex) {
    // No need to split if not text node
    if (el.nodeType != 3) {
      return el;
    }

    const start = el.nodeValue.substr(0, index);
    const startElement = start === '' ? null : document.createTextNode(start);

    const mid = el.nodeValue.substr(index, endIndex ? endIndex - index : undefined);
    const midElement = document.createTextNode(mid);

    const end = el.nodeValue.substr(endIndex);
    const endElement = endIndex ? document.createTextNode(end) : undefined;

    // Replace split elements instead of old text
    const newElms = [startElement, midElement, endElement].filter((x) => x).reverse();

    // Replace text node with 2 or 3 split text nodes
    const parentEl = el.parentElement;
    newElms.forEach((newEl, ind) => {
      if (ind === 0) {
        parentEl.replaceChild(newEl, el);
      } else {
        parentEl.insertBefore(newEl, newElms[ind - 1]);
      }
    });

    return midElement;
  },
  getOffset(elem) {
    let offsetLeft = 0;
    let offsetTop = 0;
    do {
      if (!isNaN(elem.offsetLeft)) {
        offsetLeft += elem.offsetLeft - elem.scrollLeft;
        offsetTop += elem.offsetTop - elem.scrollTop;
      }
    } while ((elem = elem.offsetParent));
    return { left: offsetLeft, top: offsetTop };
  },
};
