import { BaseEntity } from '../base.entity';

export class SubdeckEntity extends BaseEntity {
  Class = 'Subdeck';
  title;
  flatten_title;
  user_id;
  deck_id;
  parent_id;
  is_suspended;
  studied_cards;
  active_cards;
  active_cards_self;
  flagged_cards;
  suspended_cards;
  unsuspended_cards;
  publish_status;
  total_cards;
  total_cards_self;
  new_cards;
  learning_cards;
  due_cards;
  children;

  constructor(json) {
    super(json);
    this.title = json.title ?? '';
    this.flatten_title = json.flatten_title ?? '';
    this.user_id = json.user_id ?? 0;
    this.deck_id = json.deck_id ?? 0;
    this.parent_id = json.parent_id ?? undefined;
    this.is_suspended = json.is_suspended ?? false;
    this.studied_cards = json.studied_cards ?? 0;
    this.active_cards = json.active_cards ?? 0;
    this.active_cards_self = json.active_cards_self ?? 0;
    this.flagged_cards = json.flagged_cards ?? 0;
    this.suspended_cards = json.suspended_cards ?? 0;
    this.unsuspended_cards = json.unsuspended_cards ?? 0;
    this.publish_status = json.publish_status ?? 'draft';
    this.total_cards = json.total_cards ?? 0;
    this.total_cards_self = json.total_cards_self ?? 0;
    this.new_cards = json.new_cards ?? 0;
    this.learning_cards = json.learning_cards ?? 0;
    this.due_cards = json.due_cards ?? 0;
    this.children = json.children ?? [];
  }

  get hasAnyNotStudiedCard() {
    const hasNoCardToStudy = this.studied_cards >= this.active_cards;
    const hasNoCardInCategories = [this.new_cards, this.learning_cards, this.due_cards].every((number) => number === 0);
    return hasNoCardToStudy && hasNoCardInCategories;
  }

  get deck() {
    return window.$nuxt.$store.getters['flashcard/deck'];
  }

  get totalCardsBasedOnPermission() {
    return _totalCardsBasedOnPermission(this.deck, this);
  }

  totalSubdeckSelfCardsBasedOnPermission(deck) {
    const { total_cards_self: totalCardsSelf, active_cards_self: activeCardsSelf } = this;
    if (deck.permissions.includes('flashcard.add.card')) {
      return totalCardsSelf || 0;
    }
    return activeCardsSelf || 0;
  }
}

export class FlattenSubdeckEntity extends BaseEntity {
  Class = 'FlattenSubdeck';
  title;
  active_cards;
  active_cards_self;
  total_cards;
  total_cards_self;

  constructor(json) {
    super(json);
    this.title = json.title ?? '';
    this.active_cards = json.active_cards ?? 0;
    this.active_cards_self = json.active_cards_self ?? 0;
    this.total_cards = json.total_cards ?? 0;
    this.total_cards_self = json.total_cards_self ?? 0;
  }

  get deck() {
    return window.$nuxt.$store.getters['flashcard/deck'];
  }

  get totalCardsBasedOnPermission() {
    return _totalCardsBasedOnPermission(this.deck, this);
  }
}

const _totalCardsBasedOnPermission = (deck, subdeck) => {
  const { total_cards: totalCards, active_cards: activeCards } = subdeck;
  if (deck.permissions.includes('flashcard.add.card')) {
    return totalCards || 0;
  }
  return activeCards || 0;
};
