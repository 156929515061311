import { render, staticRenderFns } from "./VerifyPhoneNumberModal.vue?vue&type=template&id=1029a6e1"
import script from "./VerifyPhoneNumberModal.vue?vue&type=script&lang=js"
export * from "./VerifyPhoneNumberModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileOtpSteps: require('/app/components/profile/otp/otpSteps/ProfileOtpSteps.vue').default,BottomSheetModal: require('/app/components/ui/modal/BottomSheetModal.vue').default})
