import { render, staticRenderFns } from "./default-full.vue?vue&type=template&id=55aa451a"
import script from "./default-full.vue?vue&type=script&lang=js"
export * from "./default-full.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBanner: require('/app/components/ui/banner/top-banner.vue').default,NavbarMenu: require('/app/components/navbar/navbar-menu.vue').default,NavbarSidebarMcatPrep: require('/app/components/navbar/navbar-sidebar-mcat-prep.vue').default,NavbarSidebarSessions: require('/app/components/navbar/navbar-sidebar-sessions.vue').default,NavbarSidebar: require('/app/components/navbar/navbar-sidebar.vue').default,BaseLayout: require('/app/components/ui/layout/base-layout.vue').default})
