
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      selectedTab: 0,
      hovered: null,
    };
  },
  computed: {
    ...mapGetters('session', ['sessions', 'studentSessions']),
    navs() {
      return [
        {
          title: 'Upcoming',
          icon: 'icon-upcoming',
          slug: 'upcoming',
          enable: true,
          count: this.sessions.length,
          gtm_event: 'upcoming__live_sessions_page__navigation',
        },
        {
          title: 'Counted in',
          icon: 'icon-counted-in',
          slug: 'counted',
          enable: true,
          count: this.studentSessions.length,
          gtm_event: 'counted_in__live_sessions_page__navigation',
        },
      ];
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
      this.$nuxt.$emit(this.$config.event.session.sessions_page_tab_change, this.navs[index]);
    },
    getIcon(icon) {
      return require(`~/assets/icons/fb/sidebar/${icon}.svg`);
    },
  },
};
