
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    courses: {
      type: Array,
      default: () => [],
    },
    isLearning: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    componentCalculator(course) {
      return course.landing_url && !this.isLearning ? 'a' : 'nuxt-link';
    },
    attributeCalculator(course) {
      if (this.isLearning) {
        return {
          to: { name: 'courses-slug-learn', params: { slug: course.slug } },
        };
      } else if (course.landing_url) {
        return {
          target: '_blank',
          href: course.landing_url,
        };
      } else {
        return {
          to: { name: 'courses-slug', params: { slug: course.slug } },
        };
      }
    },
  },
};
