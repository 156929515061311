
export default {
  props: {
    size: {
      type: Number,
      default: 36,
    },
    percentage: {
      type: Number,
      default: 0,
      validator: (val) => {
        return val <= 100;
      },
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    ringColor: {
      type: String,
      default: '#E9EBEE',
    },
    progressColor: {
      type: String,
      default: '#08D7B8',
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    r() {
      return this.size / 2 - this.strokeWidth / 2;
    },
    dasharray() {
      return 2 * Math.PI * this.r;
    },
    dashoffset() {
      return this.dasharray * (1 - this.percentage / 100);
    },
    progressNumber() {
      return this.showNumber && parseInt(this.percentage);
    },
  },
  mounted() {},
};
