import types from './types';

export default {
  changePWAMode({ commit }, state) {
    commit(types.CHANGE_PWA_MODE, state);
  },
  setForceShowHomePage({ commit }, payload) {
    commit(types.SET_FORCE_SHOW_HOME, payload);
  },
};
