export default {
  SET_IS_READY: 'SET_IS_READY',
  SET_QUIZ: 'SET_QUIZ',
  RESET_STATE: 'RESET_STATE',
  SET_SECTION_INDEX: 'SET_SECTION_INDEX',
  SET_QUESTION_INDEX: 'SET_QUESTION_INDEX',
  SET_HIGHLIGHTS: 'SET_HIGHLIGHTS',
  APPEND_NEW_HIGHLIGHT: 'APPEND_NEW_HIGHLIGHT',
  MODIFY_QUESTION_PROPERTY: 'MODIFY_QUESTION_PROPERTY',
  ATTACH_QUESTION_FEATURES: 'ATTACH_QUESTION_FEATURES',
  SYNC_SECTION_FEATURES: 'SYNC_SECTION_FEATURES',
  SYNC_QUESTION_FEATURES: 'SYNC_QUESTION_FEATURES',
  SET_IS_REVIEW_MODE: 'SET_IS_REVIEW_MODE',
  SET_FILTERED_QUESTIONS: 'SET_FILTERED_QUESTIONS',
  SET_TEST_ACTIVE: 'SET_TEST_ACTIVE',
  SET_FINISHING_MODE: 'SET_FINISHING_MODE',
  SET_SECTION_TIME_ALLOTED: 'SET_SECTION_TIME_ALLOTED',
  SET_DELAY: 'SET_DELAY',
  SET_DELAY_LOADING: 'SET_DELAY_LOADING',
  SET_ACTIVE_RANGE: 'SET_ACTIVE_RANGE',
  SET_SHOW_SOLUTION: 'SET_SHOW_SOLUTION',
};
