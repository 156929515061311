
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      hoveredTabId: null,
      navs: [
        {
          title: 'MCAT Question Bank',
          icon: 'icon-cars-daily-passages',
          url: '/#daily-passages',
          isExternal: false,
          isNuxt: true,
          gtm_event: 'mcat_qbank__sidebar__navigation',
        },
        {
          title: 'Practice Exams',
          icon: 'icon-cars-practice-exams',
          url: '/mcat-cars-practice-exams',
          isExternal: false,
          isNuxt: true,
          gtm_event: null,
        },
        {
          title: 'Khan Academy',
          icon: 'icon-khan-academy',
          url: '/khan-academy',
          isExternal: false,
          isNuxt: false,
          gtm_event: null,
        },
        {
          title: 'AAMC Outline',
          icon: 'icon-science-outline',
          url: '/resources/mcat-content/aamc-mcat-science-outline',
          isExternal: false,
          isNuxt: false,
          gtm_event: null,
        },
        {
          title: 'Retake Calculator',
          icon: 'icon-retake-calculator',
          url: '/mcat-retake-calculator',
          isExternal: false,
          isNuxt: false,
          gtm_event: null,
        },
        {
          title: 'AAMC Chrome Extension',
          icon: 'icon-chrome-extension',
          url: 'https://chrome.google.com/webstore/detail/aamc-mcat-interface-by-ja/jgglfdpjpddcdaeapbcfgckfheabbdpi',
          isExternal: true,
          isNuxt: false,
          gtm_event: 'aamc_chrome_extension__sidebar__navigation',
        },
        {
          title: 'Reviews',
          icon: 'icon-testimonials',
          url: '/reviews',
          isExternal: false,
          isNuxt: false,
          gtm_event: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('session', ['sessions']),
    ...mapGetters('passage', ['dailyPassages']),
    ...mapGetters('user', ['metaValue']),
    extraNavs() {
      return [
        {
          title: 'About Jack Westin',
          url: '/about',
          image: 'icon-about',
          gtm_event: 'about_jackwestin__sidebar__navigation',
        },
        {
          title: 'Faq',
          url: '/faq',
          image: 'icon-faq',
          gtm_event: 'faq__sidebar__navigation',
        },
        {
          title: 'Blog',
          url: '/resources/blog',
          image: 'icon-blog',
          gtm_event: null,
        },
        {
          title: 'Contact',
          url: '/contact-jack',
          image: 'icon-contact',
          gtm_event: null,
        },
        {
          title: 'Support',
          url: '/support',
          image: 'icon-support',
          gtm_event: null,
        },
      ];
    },
  },
  beforeMount() {
    this.checkFlashcardIsActive();
  },
  methods: {
    checkFlashcardIsActive() {
      if (this.$auth.user?.flashcard_is_active || this.metaValue('flashcard_visibility') === 'public') {
        this.navs.unshift({
          title: 'Flashcards',
          exteraTitle: 'Beta',
          icon: 'flashcards',
          url: '/flashcards/browse',
          isExternal: false,
          isNuxt: true,
          gtm_event: 'flashcard__sidebar__navigation',
        });
      }
    },
    getAboutItemNavImage(itemNavImage) {
      return require(`~/assets/icons/about/${itemNavImage}.svg`);
    },
    getIcon(icon) {
      return require(`~/assets/icons/fb/sidebar/${icon}.svg`);
    },
  },
};
