export default ({ app }, inject) => {
  app.store.dispatch('router/init', app.router);
  if (window) {
    app.store.dispatch('ui/setInnerWidth', window.innerWidth);
    window.addEventListener('resize', () => {
      app.store.dispatch('ui/setInnerWidth', window.innerWidth);
    });
  }
  inject('routerUtil', app.store.state.router);
};
