import types from './types';

export default {
  [types.SET_ACTIVE_COURSE](state, course) {
    state.activeCourse = course;
  },
  [types.SET_COURSE_SUBJECTS](state, subjects) {
    state.courseSubjects = subjects;
  },
  [types.SET_COURSE_SESSIONS](state, sessions) {
    state.courseSessions = sessions;
  },
};
