
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    navHeight: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      hasShowContent: false,
      baseWrapper: null,
    };
  },

  computed: {
    ...mapGetters('ui', ['offsetTop']),
  },

  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.hasShowContent = value;
        if (value && this.offsetTop == 0) {
          this.$scrollTo('body');
        }

        document.body.style.overflowY = value ? 'hidden' : 'visible';

        if (value) {
          this.onSidebarVisible();
        } else {
          this.onSidebarDisappear();
        }
      },
    },
  },

  beforeDestroy() {
    this.hasShowContent = false;
    this.onSidebarDisappear();
  },

  methods: {
    setupContainer() {
      this.baseWrapper = document.querySelector('.hub-sidebar');
      if (!this.baseWrapper) {
        this.baseWrapper = document.createElement('div');
        this.baseWrapper.className = 'hub-sidebar';
        document.body.appendChild(this.baseWrapper);
      }
    },
    removeElement(el) {
      if (typeof el.remove !== 'undefined') {
        el.remove();
      } else {
        el.parentNode.removeChild(el);
      }
    },
    onSidebarVisible() {
      this.setupContainer();
      this.baseWrapper.appendChild(this.$el);
    },
    onSidebarDisappear() {
      this.$emit('close');
      this.$nextTick(() => {
        this.removeElement(this.$el);
      });
    },
  },
};
