import { inRange } from '@/core/utils/numbers';

export const postApiError = (app, $bugsnag, error, code, $config) => {
  const message = error?.response?.data?.message ?? 'Unknown error';
  $bugsnag.notify(new Error(message), (event) => {
    // set releaseStage
    event.app.releaseStage = $config.NODE_ENV;
    // set response error
    event.addMetadata('error', {
      code,
      response: Object.assign({}, error?.response),
    });
    // set user info
    if (app.$auth.user) {
      event.setUser(app.$auth.user.id, app.$auth.user.email, app.$auth.user.name);
    }
  });
};

export default ({ $axios, redirect, $toast, store, app, $bugsnag, $config }, inject) => {
  const isDev = $config.NODE_ENV === 'development';

  const applyAxiosInterceptors = (axios) => {
    axios.interceptors.response.use(
      (response) => {
        store.dispatch('ui/setLoading', false);
        const {
          data: { message },
        } = response;
        if (message) {
          $toast.info(message);
        }
        return response.data;
      },
      (error) => {
        const { response } = error;
        let code;

        try {
          code = parseInt(response.status);
        } catch {}

        if (!isDev && !inRange(code, 400, 499)) {
          postApiError(app, $bugsnag, error, code, $config);
        }

        if (response?.data?.message) {
          $toast.error(response.data.message);
        }

        if (code === 401) {
          redirect('/login');
        }

        throw error;
      },
    );
    axios.interceptors.request.use((req) => {
      const { skipLoadingIndicator } = req;
      if (!skipLoadingIndicator) {
        store.dispatch('ui/setLoading', true);
      }
      return req;
    });
  };

  const axiosRaw = $axios.create();

  const mockAxios = $axios.create({
    baseURL: $config.MOCK_URL ?? $config.API_URL,
    withCredentials: false,
  });

  applyAxiosInterceptors($axios);
  applyAxiosInterceptors(mockAxios);

  inject('axiosRaw', axiosRaw);
  inject('mockAxios', mockAxios);
};
