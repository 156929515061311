export const defaultState = () => ({
  isReady: true,
  isTestActive: true,
  quiz: {},
  questionIndex: 0,
  sectionIndex: 0,
  highlights: {},
  questionFeatures: [],
  sectionFeatures: [],
  isReviewMode: false,
  filteredQuestions: [],
  finishingMode: false,
  delayLoading: false,
  activeRange: null,
  showSolution: false,
});

export default () => defaultState();
