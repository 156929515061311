export default {
  fetchedClass: (state) => state.fetchedClass,
  isStudentEnrolled: (state) => state.fetchedClass.has_student_enrolled,
  studentEnrolled: (state) => state.studentEnrolled,
  hasInstallment: (state) => state.fetchedClass.has_installment,
  paymentIntent: (state) => state.paymentIntent,
  paymentIntentSecret: (state) => state.paymentIntentSecret,
  paymentMethodType: (state) => {
    return state.fetchedClass.has_installment ? 'course_installment' : 'course_full_purchase';
  },
  paymentMethod: (state) => state.paymentMethod,
  installmentPrice: (state) => {
    if (!state.fetchedClass.installment_quantity || !state.fetchedClass.pricing) {
      return 0;
    }
    return state.fetchedClass.pricing / state.fetchedClass.installment_quantity;
  },
  amountToPay: (state) => {
    return state.paymentMethod === 'course_full_purchase'
      ? state.fetchedClass.pricing
      : state.fetchedClass.pricing / state.fetchedClass.installment_quantity;
  },
};
