import types from './types';

export default {
  [types.SET_PURCHASES](state, payload) {
    state.studentPurchases = [...payload];
  },

  [types.SET_STUDENT_COURSES](state, payload) {
    state.studentCourses = [...payload];
  },

  [types.SET_STUDENT_COURSE](state, payload) {
    state.studentCourse = payload;
  },

  [types.SET_STUDENT_ENROLLMENTS](state, payload) {
    state.studentEnrollment = [...payload];
  },

  [types.SET_STUDENT_ENROLLMENTS_CLASS](state, payload) {
    state.studentEnrollmentClass = [...payload];
  },

  [types.SET_UPCOMING_INVOICE](state, payload) {
    state.upcomingInvoice = payload;
  },

  [types.SET_STUDENT_CARDS](state, payload) {
    state.studentCards = [...payload];
  },

  [types.SET_EXTENSIONS](state, payload) {
    state.studentExtensions = payload;
  },

  [types.SET_USER_WAITING_FOR_NEXT_SESSION](state, isWaiting) {
    state.isStudentInWaitingForNextSession = isWaiting;
  },

  [types.SET_PAYMENT_DETAIL](state, payload) {
    state.studentPaymentDetail = payload;
  },

  [types.SET_USER_INFO](state, payload) {
    state.user = Object.assign({}, payload);
  },

  [types.SET_HAS_STUDENT_EXTENDED_COURSE](state, payload) {
    state.hasStudentExtendedCourse = payload;
  },

  [types.SET_STUDENT_TIERS](state, payload) {
    state.studentTiers = payload;
  },

  [types.SET_STUDENT_TIER](state, payload) {
    state.studentTier = Object.assign({}, payload);
  },

  [types.SET_TIER_CLASS_ENROLLMENTS](state, payload) {
    state.tierClassEnrollments = payload;
  },

  [types.CHANGE_THANKYOU_PAGE_STATUS](state, status) {
    state.isThankyouPageAvailable = status;
  },

  [types.ENABLED_DISCOUNT_CODE](state, value) {
    state.enabledDiscountCode = value;
  },
};
