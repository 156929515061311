export default ($axios) => ({
  fetchTickets(params = {}) {
    return $axios.get('/api/v1/support/tickets', {
      params,
    });
  },
  fetchTicketDetails(ticket_id) {
    return $axios.get(`/api/v1/support/tickets/${ticket_id}`);
  },
  createTicket({ type, title, question, section, sectionId, enrollmentId }) {
    return $axios.post('/api/v1/support/tickets', {
      ticket_type: type,
      title,
      content: question,
      section,
      section_id: sectionId,
      enrollment_id: enrollmentId,
    });
  },
  deleteMessage(ticketId, messageId) {
    return $axios.delete(`/api/v1/support/tickets/${ticketId}/messages/${messageId}`);
  },
  createMessage(ticketId, content) {
    return $axios.post(`/api/v1/support/tickets/${ticketId}/messages`, {
      content,
    });
  },
});
