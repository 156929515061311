import types from './types';

export default {
  [types.CHANGE_PWA_MODE](state, status) {
    state.isPWAMode = status;
  },
  [types.SET_FORCE_SHOW_HOME](state, payload) {
    state.forceShowHome = payload;
  },
};
