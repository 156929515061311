
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hiddenYOverflow: {
      type: Boolean,
      default: false,
    },
    hiddenOverflow: {
      type: Boolean,
      default: true,
    },
    actionSheetBodyClass: {
      type: [String, Object, Array, null],
      default: null,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    hasDefaultPadding: {
      type: Boolean,
      default: true,
    },
    isPositionFixed: {
      type: Boolean,
      default: false,
    },
    widthClasses: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      innerWidth: 1000,
    };
  },
  computed: {
    isModalView() {
      return this.innerWidth > 768;
    },
  },
  mounted() {
    this.calcInnerWidth();
    window.addEventListener('resize', this.calcInnerWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcInnerWidth);
  },
  methods: {
    calcInnerWidth() {
      this.innerWidth = window.innerWidth;
    },
  },
};
