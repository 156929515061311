import types from './types';

export default {
  async fetchSessions({ commit }, params) {
    try {
      const loadingMode = !params.noLoadingMode;
      delete params.noLoadingMode;
      const commitMode = !params.noCommitMode;
      delete params.noCommitMode;

      if (loadingMode) {
        commit(types.SET_COMPONENT_LOADING_SESSION_LIST, true);
      }
      const response = await this.$api.session.fetchSessions(params);
      if (commitMode) {
        commit(types.SET_SESSIONS, response.data);
      }
      if (loadingMode) {
        commit(types.SET_COMPONENT_LOADING_SESSION_LIST, false);
      }

      return response;
    } catch (error) {}
  },

  async fetchLiveSessions({ commit, getters }, params) {
    try {
      const response = await this.$api.session.fetchSessions(params);
      response.data = response.data.map((session) => ({
        ...session,
        $has_watch_button: session.has_finished && params.past,
      }));
      const sessions = params.past && params.page > 1 ? [...getters.liveSessions, ...response.data] : response.data;
      commit(types.SET_LIVE_SESSIONS, sessions);
      return response;
    } catch (error) {}
  },

  updateLiveSessions({ commit }, liveSessions) {
    commit(types.SET_LIVE_SESSIONS, liveSessions);
  },

  async fetchStudentSessions({ commit }, params) {
    try {
      const { data } = await this.$api.session.fetchStudentSessions(params);
      commit(types.SET_STUDENT_SESSIONS, data);
    } catch (error) {}
  },

  async fetchSession({ commit }, id) {
    const { data } = await this.$api.session.fetchSession(id);
    commit(types.SET_SESSION, data);
  },

  async cancelSession({ commit }, id) {
    try {
      await this.$api.session.cancelSession(id);
      commit(types.SET_STUDENT_ENROLLED, { sessionId: id, status: false });
      this.$toast.show('You have counted out!');
    } catch (error) {
      throw new Error(error);
    }
  },

  async enrollSession({ commit }, id) {
    try {
      const utmQueryObject = this.$services.utm.getUtmQueryObject();
      const { data } = await this.$api.session.enrollSession(id, utmQueryObject);
      const isLiveSession = data.courses.length > 0 && data.classes?.length > 0;
      if (isLiveSession) {
        commit(types.SET_LIVE_SESSION, data);
      } else {
        commit(types.SET_SESSION, data);
      }
      commit(types.SET_STUDENT_ENROLLED, { sessionId: id, status: true });
      this.$toast.success('You have counted in!');
    } catch (error) {
      throw new Error(error);
    }
  },

  async joinSession(_, { id, logout = false }) {
    const res = await this.$api.session.joinSession(id, logout);
    this.$toast.success('You have joined in!');
    return res;
  },

  setSessions({ commit }, data) {
    commit(types.SET_SESSIONS, data);
  },

  setStarted({ commit }, id) {
    commit(types.SET_STARTED, { sessionId: id, status: true });
    commit(types.SET_SESSION_STARTED, true);
  },

  showLess({ commit }) {
    commit(types.SET_LESS_SESSIONS);
  },

  resetSessions({ commit }) {
    commit(types.RESET_SESSIONS);
  },

  async playRecord(_, { recordUrl, isEncore, startFrom }) {
    const res = await this.$api.session.playRecord(recordUrl, isEncore, startFrom);
    return res;
  },

  async removeFromSessionWaitingList(_, sessionId) {
    const res = await this.$api.session.removeFromSessionWaitingList(sessionId);
    return res;
  },
};
