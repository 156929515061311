export default {
  dailyPassages: (state) => state.daily_passages,
  todayPassages: (state) => state.today_passages,
  passagesStreak: (state) => state.streak,
  dailySections: (state) => state.daily_sections,
  dailySectionsGroupByApp: (state) => {
    const outObject = state.daily_sections.reduce((a, e) => {
      const theKey = e.app.slug;
      if (!a[theKey]) {
        a[theKey] = {};
        a[theKey].title = e.app.title;
      }
      if (!a[theKey].items) {
        a[theKey].items = [];
      }
      a[theKey].items.push(e);
      return a;
    }, {});
    return outObject;
  },
  totalPages: (state) => state.daily_passages_meta?.last_page ?? 0,
  totalPassages: (state) => state.daily_passages_meta?.total ?? 0,
  isLoadingDailyPassages: (state) => state.isLoadingDailyPassages,
  isLoadingTodayPassages: (state) => state.isLoadingTodayPassages,
  isSubscribeBoxAvailable: (state) => state.isSubscribeBoxAvailable,
};
