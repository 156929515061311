import demHelper from './dom-helper';
import range from './range';
import highlighter from './highlighter';

export class HighlightService {
  constructor(ctx) {
    this.context = ctx;
    this.highlightDetails = {};
    this.contents = [];
  }

  demHelper(...args) {
    return demHelper(...args);
  }

  range(...args) {
    return range(...args);
  }

  hl(...args) {
    return highlighter(...args);
  }

  prepareHighlightDetail(highlightType, highlightAction) {
    const targetElement = getSelection()?.anchorNode?.parentElement?.closest('[data-highlight-type]');
    if (!targetElement) {
      throw new Error('No selection found!');
    }
    const contentType = targetElement.dataset.highlightType;
    return { contentType, highlightType, highlightAction };
  }

  getHighlightClassName({ highlightType, highlightAction }) {
    let className;
    if (highlightType === 'highlighting') {
      className = highlightAction === 'add' ? 'hl-yellow' : 'hl-blank';
    } else {
      className = highlightAction === 'add' ? 'hl-strike' : 'hl-un-strike';
    }
    return className;
  }

  calculateActionForSelection(className) {
    const range = getSelection().getRangeAt(0);
    if (!range) {
      return;
    }

    return range.startContainer.parentElement && range.startContainer.parentElement.classList.contains(className)
      ? 'remove'
      : 'add';
  }

  getHighlightDataBasedOnSection() {
    const selection = getSelection();
    return {
      start: selection.baseOffset,
      end: selection.extentOffset,
      selectedText: selection.getRangeAt(0).toString(),
      textContainer: selection.getRangeAt(0).commonAncestorContainer.textContent,
    };
  }

  contentHighlights({ contentType, contentTypeId, highlights }) {
    const highlightState = highlights ?? this.context.store.getters['quiz/highlights'];
    return (highlightState[contentType] ?? [])[contentTypeId] || [];
  }

  initialContents(contents) {
    return new Promise((resolve) => {
      if (!contents) {
        return resolve([]);
      }
      const result = [];
      for (const passage of contents?.passages ?? []) {
        const question = contents.questions.find((question) => question.passage_id === passage.id);
        result.push(this.contentEntity(passage, 'passage', question.id));
      }
      for (const question of contents?.questions ?? []) {
        result.push(this.contentEntity(question, 'question', question.id));
        for (const answer of question.options) {
          result.push(this.contentEntity(question, answer.key, question.id));
        }
      }
      resolve(result);
    });
  }

  contentEntity(highlightObject, highlightType, questionId) {
    if (!highlightObject) {
      return;
    }
    const obj = {
      content: this.clearMediaSources(highlightObject[highlightType]),
      ref: `${highlightType}_${highlightObject.id}`,
      id: highlightObject.id,
      questionId,
      type: highlightType,
    };
    return obj;
  }

  findAllHighlightContents(contents) {
    this.contents = contents;
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (document.querySelector('.quiz-highlight')) {
          clearInterval(interval);
          const highlightElements = document.querySelectorAll('.hl.highlighting.hl-yellow');
          for (const el of highlightElements) {
            this.getNodeType(el);
          }
          const highlightResult = this.highlightDetails;
          resolve(highlightResult);
        }
      }, 50);
    });
  }

  getNodeType(node) {
    const parentClassList = node.parentElement.classList;
    const parentClasses = [];
    if (parentClassList && parentClassList.length) {
      for (const style of parentClassList) {
        parentClasses.push(style);
      }
    }
    const nodeClassList = node.classList;
    const nodeClasses = [];
    if (nodeClassList && nodeClassList.length) {
      for (const style of nodeClassList) {
        nodeClasses.push(style);
      }
    }

    // If the node is an element node, the nodeType property will return 1.
    if (node.nodeType === 1) {
      this.checkChildNode(node);
    }

    // If the node is a text node, the nodeType property will return 3.
    if (node.nodeType === 3) {
      if (parentClasses.includes('hl-yellow') && !nodeClasses.includes('hl-blank')) {
        const { id, type, date, questionId } = this.getDataAttributes(node.parentElement);
        if (!this.highlightDetails[date]) {
          this.highlightDetails[date] = [];
        }
        if (node.data.trim() !== '') {
          this.highlightDetails[date].push({
            id,
            type,
            title: node.data,
            date,
            questionId,
          });
        }
      }
    }
  }

  checkChildNode(node) {
    if (node.childNodes && node.childNodes.length) {
      for (const el of node.childNodes) {
        this.getNodeType(el);
      }
    }
  }

  getDataAttributes(node) {
    const contentId = Number(node.dataset.hlContentId);
    const contentType = node.dataset.hlContentType;
    const datetime = this.context.$moment(node.dataset.hlDatetime).format('MMMM Do, YYYY');
    const content = this.contents.find((content) => content.id === contentId);
    return {
      date: datetime,
      type: contentType,
      id: Number(contentId),
      questionId: content.questionId,
    };
  }

  clearMediaSources(content) {
    let newContent = content.replace(/<img([^>]*)\ssrc=(['"])(?:[^\2/]*\/)*([^\2]+)\2/gi, '<img$1 src=$2$2');
    newContent = newContent.replace(/<video([^>]*)\ssrc=(['"])(?:[^\2/]*\/)*([^\2]+)\2/gi, '<video$1 src=$2$2');
    newContent = newContent.replace(/<audio([^>]*)\ssrc=(['"])(?:[^\2/]*\/)*([^\2]+)\2/gi, '<audio$1 src=$2$2');
    newContent = newContent.replace(/<source([^>]*)\ssrc=(['"])(?:[^\2/]*\/)*([^\2]+)\2/gi, '<source$1 src=$2$2');
    return newContent;
  }
}
