export default class UtmService {
  constructor(context) {
    this.context = context;
  }

  getUtmQueryObject() {
    const { $auth, $services, $cookiz } = this.context;
    const urlUtmCookie = $cookiz.get('urlUtm') ?? {};

    if ($auth.loggedIn && Object.keys(urlUtmCookie).length >= 1 && $services.user.isTrackableActive()) {
      return this.convertKeyStyle(urlUtmCookie);
    } else {
      return {};
    }
  }

  setUrlUtmCookie(value) {
    const { $cookiz } = this.context;
    $cookiz.set('urlUtm', value, {
      maxAge: 60 * 60 * 24 * 365, // one year
    });
  }

  /**
   * Converts the keys of the given URL UTM object from underline format to dash format.
   *
   * @param {object} pureObject - The URL UTM object to be converted.
   * @return {object} The converted object with keys in dash format.
   */
  convertKeyStyle(pureObject) {
    const convertedObject = {};
    Object.entries(pureObject).forEach(([key, value]) => {
      const convertedKey = `X-${key.replaceAll('_', '-')}`;
      convertedObject[convertedKey] = value;
    });
    return convertedObject;
  }
}
