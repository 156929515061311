import { UseMobileOTPService } from '~/core/services/mobile-otp/UseMobileOTPService';
import { FirebaseOTPProver } from '~/core/services/mobile-otp/providers/FirebaseProvider';
import { DEFAULT_COOKIE_VALUES, USER_COOKIE_CHOICES } from '~/core/utils/constants';
const otpService = new UseMobileOTPService(FirebaseOTPProver);

export default (context) => ({
  initialOtpService(payload) {
    otpService.initialProvider(context, payload);
  },

  sendOtpCode(phoneNumber) {
    return otpService.sendMobileVerificationCode(phoneNumber);
  },

  verifyOtpCode(verifyCode) {
    return otpService.verifyMobileOTP(verifyCode);
  },

  async updatePhoneNumber(phoneNumber) {
    const { $api } = context;
    await $api.user.updateProfileInfo({ phone_number: phoneNumber });
    this.updateUserInfo({ phone_number: phoneNumber });
  },

  async updateTestDate(testDate) {
    const { $api } = context;
    await $api.user.updateProfileInfo({ test_date: testDate });
    this.updateUserInfo({ test_date: testDate });
  },

  updateUserInfo(info, replace = false) {
    const { $auth } = context;

    const isLoggedIn = $auth.loggedIn;
    const newData = replace
      ? info
      : {
          ...$auth.user,
          ...info,
        };
    $auth.setUser(newData);
    $auth.$storage.setUniversal('loggedIn', isLoggedIn);
  },

  async verifyPhoneNumber(token, phoneNumber) {
    const { $api } = context;
    await $api.user.verifyPhoneNumber(token);
    this.updateUserInfo({
      is_phone_number_verified: true,
      need_verify_number: false,
      phone_number: phoneNumber,
    });
  },

  async phoneNumberExists(phoneNumber) {
    const { $api } = context;

    try {
      await $api.user.lookupPhoneNumber(phoneNumber);
      return false;
    } catch (err) {
      if (err.response.status === 422) {
        return true;
      }

      return null;
    }
  },

  choiceTrackingCookie(choice) {
    const { store, $cookiz, $auth } = context;
    if ($auth.loggedIn) {
      store.dispatch('student/updateProfile', {
        is_trackable: choice,
      });
    } else {
      $cookiz.set('is_trackable', choice);
    }
    store.dispatch('user/modifyUserMeta', {
      key: 'is_trackable',
      value: choice,
    });
  },

  trackableValue() {
    const { store, $cookiz, $auth } = context;

    if ($auth.loggedIn) {
      return store.getters['user/userMeta'].is_trackable;
    }
    const cookieValue = $cookiz.get('is_trackable');
    if (cookieValue !== undefined) {
      return Number(cookieValue);
    }
    return USER_COOKIE_CHOICES.NOT_CHOICE;
  },

  isTrackableActive() {
    const { defaultCookie } = context.store.getters['user/cookieConfiguration'];
    const userChoice = this.trackableValue();

    const userHasNotChoiceYet = userChoice === USER_COOKIE_CHOICES.NOT_CHOICE;
    const shouldTrackUserByDefault = defaultCookie === DEFAULT_COOKIE_VALUES.SHOULD_TRACK;

    if (userHasNotChoiceYet && shouldTrackUserByDefault) {
      return true;
    }
    return userChoice === USER_COOKIE_CHOICES.ACCEPTED;
  },
});
