const BASE_API_PATH_V1 = '/api/v1/caap/payments/discounts';

export default class DiscountAPI {
  constructor($axios) {
    this.$axios = $axios;
  }

  enabled(campaignableType, campaignableId) {
    return this.$axios.post(`${BASE_API_PATH_V1}`, {
      campaignable_type: campaignableType,
      campaignable_id: Number(campaignableId),
    });
  }

  check(paymentIntent, code, campaignableType, campaignableId) {
    return this.$axios.post(`${BASE_API_PATH_V1}/check`, {
      payment_intent_id: paymentIntent,
      code,
      campaignable_type: campaignableType,
      campaignable_id: Number(campaignableId),
    });
  }

  apply(paymentIntent, code) {
    return this.$axios.post(`${BASE_API_PATH_V1}/apply`, {
      payment_intent_id: paymentIntent,
      code,
    });
  }

  enroll(payload, headers) {
    const { paymentIntent, code, campaignableId, campaignableType } = payload;
    return this.$axios.post(
      `${BASE_API_PATH_V1}/enroll`,
      {
        payment_intent_id: paymentIntent,
        code,
        campaignable_id: campaignableId,
        campaignable_type: campaignableType,
      },
      {
        headers,
      },
    );
  }

  cancel(paymentIntent) {
    return this.$axios.post(`${BASE_API_PATH_V1}/cancel`, {
      payment_intent_id: paymentIntent,
    });
  }
}
