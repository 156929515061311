import QuestionEntity from '~/core/entities/qbank-quiz/question.entity';
import QuizInterceptor from './quiz.interceptor';

export class SATInterceptor extends QuizInterceptor {
  constructor(quiz, context) {
    super(quiz);
    this.context = context;
  }

  modifySections() {
    return this;
  }

  modifyQuestions() {
    const isTestFinished = this.context.store.getters['quiz/isTestFinished'];
    const studyableSections = this.context.store.getters['quiz/studyableSections'];
    const actualSection = this.context.store.getters['quiz/actualSection'];
    const isActiveSectionFirstSectionOfExam = actualSection.id === studyableSections[0].id;

    if (isActiveSectionFirstSectionOfExam && !isTestFinished) {
      this.quiz.questions = [
        new QuestionEntity({
          component: 'quiz-sat-welcome-step',
          countable: false,
          navigable: false,
          section_id: actualSection.section_id,
        }),
        ...this.quiz.questions,
      ];
    }

    this.quiz.questions = [
      ...this.quiz.questions,
      new QuestionEntity({
        id: 'review-step',
        component: 'quiz-sat-review-step',
        countable: false,
        navigable: false,
        section_id: actualSection.section_id,
      }),
    ];

    return this;
  }

  modifyPassages() {
    return this;
  }
}
