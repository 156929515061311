
export default {
  layout: null,
  loading: {
    continuous: true,
  },

  updated() {
    this.$nextTick(() => {
      // global confirm dialog
      this.$root.$confirm = this.$refs.confirm;
      // global modal
      this.$root.$modal = this.$refs.modal;
    });
  },
};
