export default {
  test: (state) => state.test ?? null,
  tests: (state) => state.tests,
  testAnalysis: (state) => state.testAnalysis,
  testResult: (state) => state.testResult,
  pagination: (state) => state.pagination,
  apps: (state) => state.apps ?? [],
  sections: (state) => state.sections,
  foundations: (state) => state.foundations,
  isThereAnyTest: (state) => state.tests.length > 0 ?? false,
  isComponentLoadingTestDetail: (state) => state.isComponentLoadingTestDetail,
  isComponentLoadingTestResult: (state) => state.isComponentLoadingTestResult,
  isComponentLoadingTestAnalysis: (state) => state.isComponentLoadingTestAnalysis,
};
