import types from './types';

export default {
  [types.SET_INSTRUCTOR_MODE](state, isInstructorMode) {
    state.isInstructorMode = isInstructorMode;
  },
  [types.SET_REMOVE_FROM_WAITLIST](state, isRemoveFromWaitlist) {
    state.isRemoveFromWaitlist = isRemoveFromWaitlist;
  },
};
