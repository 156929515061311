
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Boolean,
      default: false,
    },

    headerTextSize: {
      type: String,
      default: 'lg',
    },

    border: {
      type: Boolean,
      default: true,
    },

    bg: {
      type: Boolean,
      default: true,
    },
  },
};
