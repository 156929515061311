import { durationSum } from '~/core/provider/studyProvider';
export default {
  course: (state) => state.course,
  courseSections: (state) => state.course?.sections?.length > 0 ?? false,
  courseAttributes: (state) => state.course?.settings?.attributes.filter((attr) => attr.visibility) ?? [],
  courses: (state) => state.courses,
  courseTeammates: (state) => state.course?.teammates ?? [],
  classes: (state) => {
    let classLimit = 3;
    if (state?.course?.field?.name === 'DAT') {
      classLimit = 1;
    }
    if (state.classes.length > classLimit) {
      return state.classes.slice(0, classLimit);
    }
    return state.classes;
  },
  tiers: (state) => {
    const sortedTiers = [...state.tiers].sort((a, b) => a.order_column - b.order_column);
    if (sortedTiers.length > 4) {
      return sortedTiers.slice(0, 4);
    }
    return sortedTiers;
  },
  notEnrolledTiers: (state) => state.tiers?.filter((tier) => tier.has_student_enrolled === false) ?? [],
  tiersLowestPrice: (state) => {
    const tiers = [...state.tiers];
    if (tiers.length === 0) {
      return 0;
    }
    if (tiers.length === 1) {
      return tiers[0]?.pricing ?? 0;
    } else {
      const tiersSortedByPricingAsc = tiers.sort((a, b) => a.pricing - b.pricing);
      return tiersSortedByPricingAsc[0].pricing;
    }
  },
  hasCourseContent: (_, getters) => {
    return getters.course?.sections?.length > 0 ?? false;
  },
  lessonsOrderSum: (state) => state.lessonsOrderSum,
  flattenLessonsTitle: (state) => state.flattenLessonsTitle,
  hasAnyClass: (state) => state.classes.length > 0,
  firstAvailableClass: (state, getters) => {
    const status = ['full', 'closed'];
    if (getters.onlineClasses.length > 0) {
      return (
        getters.onlineClasses.find((item) => {
          return !status.includes(item.availability.toLowerCase());
        }) ?? null
      );
    }
    return (
      state.classes.find((item) => {
        return !status.includes(item.availability.toLowerCase());
      }) ?? null
    );
  },
  coursePrice: (state, getters) => {
    if (!getters.hasAnyClass) {
      return 0;
    }
    const status = ['full', 'closed'];

    // if the course has an online class, it should return the first available online class price
    if (getters.onlineClasses.length > 0) {
      return (
        getters.onlineClasses.find((item) => {
          return !status.includes(item.availability.toLowerCase());
        })?.pricing ?? 0
      );
    }

    return (
      state.classes.find((item) => {
        return !status.includes(item.availability.toLowerCase());
      })?.pricing ?? 0
    );
  },
  hasWaitingList: (state) => state.course?.has_session_waiting_list,
  hasUserAnyEnrollment: (state) => state.classes.some((item) => item.has_student_enrolled === true),
  hasCourseAlumni: (state) => state.course?.enrollment?.status === 'alumni' ?? false,
  hasCourseAlumniStatus: (state) =>
    (state.course?.enrollment?.status === 'alumni' ?? false) &&
    (state.course?.enrollment?.class?.has_extension === true ?? false),
  hasCourseAlumniStatusButNoExtension: (state) =>
    (state.course?.enrollment?.status === 'alumni' ?? false) &&
    (state.course?.enrollment?.class?.has_extension === false ?? false),
  alumniEndDate: (state) => {
    return state.course?.enrollment?.end_at ?? null;
  },
  hasUserActiveErnollment: (state) => {
    return state.course?.enrollment?.status === 'active' ?? false;
  },
  hasUserSuspendedErnollment: (state) => {
    return state.course?.enrollment?.status === 'suspended' ?? false;
  },
  isLearnPageAvailableToUser: (state) => {
    return state.course?.enrollment?.class.is_learn_page_visible_to_user ?? false;
  },
  isEnrolledClassStarted: (state) => state.course?.enrollment.class.has_started ?? false,
  enrollmentStartedAt: (state) => state.course?.enrollment?.class?.start_date ?? null,
  enrollmentId: (state) => state.course?.enrollment?.id ?? null,
  hasFreeTrialEnded: (state) => state.course?.enrollment?.free_trial_ended,
  hasUserActiveSuspendedEnrollment: (_, getters) => {
    // console.log({ getters })
    return (
      (getters.hasUserActiveErnollment || getters.hasUserSuspendedErnollment || getters.hasCourseAlumni) &&
      getters.isLearnPageAvailableToUser &&
      getters.isEnrolledClassStarted
    );
  },
  requirements: (state) => state.course?.requirements ?? [],
  targets: (state) => state.course?.targets ?? [],
  goals: (state) => state.course?.goals ?? [],
  sections: (state) => state.course?.sections ?? [],
  pageHeader: (state) => {
    return {
      title: state.course?.title ?? '',
      meta: {
        description: state.course?.description?.replace(/(<([^>]+)>)/gi, '') ?? '',
        url: `https://jackwestin.com/courses/${state.course?.slug}`,
      },
    };
  },
  courseUpgrade: (state) => state.course?.service ?? null,
  isComponentLoadingLearnPrivate: (state) => state.isComponentLoadingLearnPrivate,

  courseLessons: (state) => state.courseLessons,
  nonCompletedLessons: (state) => state.courseLessons.filter((lesson) => !lesson.completed),
  nonCompletedLessonsDuration: (_, getters) => durationSum(getters.nonCompletedLessons),
  completedLessons: (state) => state.courseLessons.filter((lesson) => lesson.completed),
  weekMandatoryLessons: (state) => state.weekMandatoryLessons,
  hasCourseStudyPlan: (state) => state.course?.settings.has_study_plan ?? false,
  studyPlanEnabled: (state) => state.course?.enrollment?.study_plan_enabled ?? false,
  hasEnrollmentStudyPlan: (state) => state.course?.enrollment?.has_study_plan ?? false,
  topics: (state) => state.topics,
  // NEW
  isCourse: (state) => state.course?.type === 'course',
  isProgram: (state) => state.course?.type === 'program',
  isService: (state) => state.course?.type === 'service',
  flattenCourseLessons: (_, getters) => {
    const lessonsList = [];
    for (let i = 0; i < getters.course.sections.length; i++) {
      for (let j = 0; j < getters.course.sections[i].lessons.length; j++) {
        lessonsList.push(getters.course.sections[i].lessons[j]);
      }
    }
    return lessonsList;
  },
  hasAnyNonCompletedMandatoryLesson: (_, getters) => {
    return getters.flattenCourseLessons
      .filter((lesson) => lesson.study_level === 'mandatory')
      .some((lesson) => lesson.completed === false);
  },
  isDAT: (state) => {
    return state?.course?.field?.name === 'DAT';
  },
  areSectionsCategorized(_, getters) {
    return getters.course.sections.every((section) => section.field_subject);
  },
  standardClasses(state) {
    return state.classes.filter((classObject) => classObject.type === 'NORMAL');
  },
  hasStandardClasses(_, getters) {
    return getters.standardClasses.length > 0;
  },
  onlineClasses(state) {
    return state.classes.filter((classObject) => classObject.type === 'LIVE');
  },
  onlineClassesAvailableToEnroll(_, getters) {
    return getters.onlineClasses.filter(
      (classObject) =>
        !['close', 'full'].includes(classObject.availability.toLowerCase()) && classObject.is_user_able_to_enroll,
    );
  },
  hasLiveOnlineClass(_, getters) {
    // this condition checks if the course getter's value is updated or not. if not, then this value has initialized to undefined
    if (getters.classes.length < 1) {
      return;
    }
    return getters.onlineClassesAvailableToEnroll.length > 0;
  },
  courseLandingUrl: (state) => state.course?.landing_url,
  courseField: (state) => state.course?.field ?? {},
};
