export default ($axios) => ({
  fetchStudentCourses() {
    return $axios.get('/api/caap/user/courses');
  },

  fetchStudentTiers() {
    return $axios.get('/api/caap/user/tiers');
  },

  fetchStudentTier(tierId) {
    return $axios.get(`/api/caap/user/tiers/${tierId}`);
  },

  fetchStudentCourse(courseId) {
    return $axios.get(`/api/caap/user/courses/${courseId}`);
  },

  fetchIsUserWaitingForNextSession(courseId) {
    return $axios.get(`/api/caap/user/courses/${courseId}/is_user_waiting`);
  },

  fetchStudentEnrollment(classId) {
    return $axios.get(`/api/caap/user/enrollments?byCourse=${classId}`);
  },

  fetchStudentEnrollmentClass(classId) {
    return $axios.get(`/api/caap/user/enrollments?byClass=${classId}`);
  },

  updateProfile(params) {
    return $axios.put('/api/caap/user/update', params);
  },

  fetchRechargePayment(purchaseId) {
    return $axios.get(`/api/caap/user/purchases/${purchaseId}/recharge-secret`);
  },

  fetchUpcomingInvoice(enrollmentId) {
    return $axios.get(`/api/caap/user/enrollments/${enrollmentId}/upcoming-invoice`);
  },

  fetchStudentCards() {
    return $axios.get('/api/caap/user/payment_methods');
  },

  fetchStudentExtension(enrollmentId) {
    return $axios.get(`/api/caap/classes/${enrollmentId}/user-extension`);
  },

  fetchHasStudentExtendedCourse(courseId) {
    return $axios.get(`/api/caap/user/courses/${courseId}/is_user_extended`);
  },

  updateDefaultCard(paymentMethodId) {
    return $axios.put(`/api/caap/user/payment_methods/${paymentMethodId}`);
  },

  setupIntent() {
    return $axios.get('/api/caap/user/payment_methods/setup_intent');
  },

  deleteCard(paymentMethodId) {
    return $axios.delete(`/api/caap/user/payment_methods/${paymentMethodId}`);
  },

  addCard(paymentMethodId) {
    return $axios.post('/api/caap/user/payment_methods', {
      payment_method: paymentMethodId,
    });
  },

  disconnectFromSocial(provider) {
    return $axios.delete(`/api/caap/socials/${provider}`);
  },

  payRemainingBalance(enrollment) {
    return $axios.post(`/api/caap/user/enrollments/${enrollment}/pay_remaining_balance`);
  },

  switchSendyNotifications(settings) {
    return $axios.put('/api/caap/user/notifications/sendy', {
      subscribe: settings.subscribe,
      list: settings.name,
    });
  },

  switchCommentsNotifications(toggle) {
    return $axios.put('/api/caap/user/notifications/comments', {
      subscribe: toggle,
    });
  },

  connectedSocials() {
    return $axios.get('/api/caap/user/connected_socials');
  },

  paymentDetail(enrollmentId) {
    return $axios.get(`/api/caap/user/enrollments/${enrollmentId}/payment_details`);
  },

  sendOTPForEmailUpdating(email) {
    return $axios.patch('/api/caap/user/update/email', { email });
  },

  updateEmail(otp, request_id) {
    return $axios.post('/api/caap/user/update/email', { otp, request_id });
  },
});
