export default ($axios) => ({
  fetchDailyPassages(params) {
    return $axios.get('/api/daily/dailies/mcat-practice-passages', { params });
  },

  fetchTodayPassages() {
    return $axios.get('/api/daily/dailies/mcat-practice-passages/today');
  },

  resetDailyPassage(id) {
    return $axios.post('/api/daily/reset', {
      daily_id: id,
    });
  },

  subscribe({ app_slug, email }) {
    return $axios.post(`/api/daily/subscribe/${app_slug}`, { email });
  },

  openLesson(id) {
    return $axios.get(`/api/daily/dailies/${id}/video`);
  },
});
