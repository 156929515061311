export default () => ({
  isLoadingDailyPassages: false,
  isLoadingTodayPassages: false,
  isSubscribeBoxAvailable: true,
  daily_passages: [],
  daily_passages_meta: {},
  today_passages: [],
  daily_sections: [],
  streak: 0,
});
