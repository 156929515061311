
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
  props: {
    isComponentActive: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        name: '',
        email: '',
        phone_number: '',
        test_date: '',
      },
      errors: {
        name: '',
        email: '',
        phone_number: '',
        test_date: '',
      },
    };
  },

  computed: {
    tomorrowDate() {
      return moment().add(1, 'day').toDate();
    },
    isInAuthPages() {
      return ['login', 'register'].includes(this.$route.name);
    },
    isPopUp() {
      const refValue = this.$route.query.ref;
      return refValue === 'popup';
    },
  },

  watch: {
    isComponentActive: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.$refs?.registerNameInput?.focus();
          }, 100);
        }
      },
    },
  },

  methods: {
    ...mapActions('student', ['updateProfile']),
    ...mapActions('user', ['modifyUserMeta']),
    async register() {
      try {
        this.loading = true;
        const { data, meta } = await this.$axios.post('/api/caap/register', this.form);
        await this.$auth.setUser({ ...data, ...(meta || {}) });
        this.$store.dispatch('user/setUserMeta', meta || {});
        this.$gtmEvent.dispatch('sign_up', {
          sign_up_method: 'Email',
          successful: true,
        });

        await this.$auth.$storage.setUniversal('loggedIn', true);
        await this.$auth.$storage.setUniversal('_token.sanctum', false);

        // update is_trackable meta the user after login with the saved data in the cookie
        const isTrackable = this.$cookiz.get('is_trackable');
        if (isTrackable !== undefined) {
          await this.$services.user.choiceTrackingCookie(isTrackable);
          this.$cookiz.remove('is_trackable');
        }

        this.loading = false;
        this.$nuxt.$emit(this.$config.event.logged_in, true);
        this.$nuxt.$emit(this.$config.event.show_login, { value: false });

        if (this.isPopUp) {
          this.$emit('notifyAuthStatus', { success: true });
          return;
        }

        const redirectPath = this.$services.auth.getLoginRedirectPath();

        if (this.isInAuthPages) {
          this.$router.push({
            name: 'student-verify',
            query: {
              ...this.$route.query,
              type: 'register',
            },
          });
          return;
        }

        if (redirectPath) {
          try {
            await this.$router.replace(redirectPath);
            return;
          } catch {}
        }

        if (this.$route.query.redirect) {
          location.replace(this.$route.query.redirect);
          return;
        }

        if (this.redirect) {
          this.$router.push({ path: this.redirect });
        }
      } catch (e) {
        this.loading = false;
        this.errors = this.$getErrors(e) || this.errors;

        if (this.isPopUp) {
          this.$emit('notifyAuthStatus', { success: false });
        }

        this.$gtmEvent.dispatch('sign_up', {
          sign_up_method: 'Email',
          successful: false,
        });
      }
    },
  },
};
