export default async function ({ $axios, $auth, store }) {
  try {
    const { data, meta } = await $axios.get('/api/caap/guestable-me', {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    if (data) {
      await $auth.setUser({ ...(data || {}), ...(meta || {}) });
      await $auth.$storage.setUniversal('loggedIn', true);
    }
    store.dispatch('user/setUserMeta', meta || {});
  } catch (error) {}
}
