import types from './types';

export default {
  init({ commit }, router) {
    router.afterEach((to, _) => {
      // instructor preview mode
      if (to?.query?.previewMode === 'instructor') {
        commit(types.SET_INSTRUCTOR_MODE, true);
      }

      if (to?.query?.remove_from_waiting_list === 'true') {
        commit(types.SET_REMOVE_FROM_WAITLIST, true);
      }
    });
  },
};
