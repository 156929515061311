import Vue from 'vue';
import types from './types';
import { findTagById } from './getters';
import { DeckEntity } from '~/core/entities/flashcard/deck.entity';
import { SubdeckEntity, FlattenSubdeckEntity } from '~/core/entities/flashcard/subdeck.entity';
import { PaginateEntity } from '~/core/entities/paginate.entity';
import { CardEntity, CardModelEntity } from '~/core/entities/flashcard/card.entity';

export default {
  [types.SET_DECK](state, payload) {
    let deck = null;
    if (payload) {
      deck = new DeckEntity(payload);
    }
    Vue.set(state, 'deck', deck);
  },

  [types.SET_FLATTED_TAGS](state, payload) {
    let subDecks = [];
    if (payload) {
      subDecks = payload.map((subdeck) => new FlattenSubdeckEntity(subdeck));
    }
    state.tags.flatted = subDecks;
  },

  [types.SET_NESTED_TAGS](state, subDecks) {
    const convertToEntity = (subdeck) => {
      const _subdeck = new SubdeckEntity(subdeck);
      if (_subdeck.children) {
        _subdeck.children = _subdeck.children.map((child) => convertToEntity(child));
      }
      return _subdeck;
    };
    Vue.set(state.tags, 'nested', subDecks ? subDecks.map((subdeck) => convertToEntity(subdeck)) : []);
    state.tags.forceRerender += 1;
  },

  [types.CHANGE_CARD_FLAGGED_STATUS](state, cardId) {
    const card = state.cards?.data?.find((card) => card.id === cardId);
    if (card) {
      Vue.set(card, 'is_flagged', !card.is_flagged);
    }
  },

  [types.CHANGE_CARD_SUSPENDED_STATUS](_, card) {
    if (card) {
      Vue.set(card, 'is_suspended', !card.is_suspended);
    }
  },

  [types.UPDATE_CARD](state, { cardId, newCard }) {
    state.cards.data = state.cards.data.map((card) => {
      if (card.id === cardId) {
        return newCard;
      }
      return card;
    });
  },

  [types.DELETE_CARD](state, cardId) {
    state.cards.data = state.cards.data.filter((card) => card.id !== cardId);
  },

  [types.UPDATE_TAG](state, { id, key, value }) {
    const tag = findTagById(id, state.tags.nested);
    if (tag) {
      Vue.set(tag, key, value);
    }
  },

  [types.UPDATE_BULK_TAGS](state, { ids, key, value }) {
    ids.forEach((id) => {
      const tag = findTagById(id, state.tags.nested);
      if (tag) {
        Vue.set(tag, key, value);
      }
    });
  },

  [types.ADD_EMPTY_NEW_CARD](state) {
    const newCardInfo = {
      id: -1,
      publish_status: 'active',
      is_flagged: false,
      is_suspended: false,
      difficulty: null,
      is_copyable: 'copyable',
      tags: [],
      model_tmpl_ord: 0,
      card_note: {
        fields: [],
        card_model: state.cardTypes[0],
      },
    };
    if (state.cards.data.find((card) => card.id === -1)) {
      state.cards.data = state.cards.data.map((card) => {
        if (card.id === -1) {
          return newCardInfo;
        }
        return card;
      });
    } else {
      state.cards.data = [newCardInfo, ...state.cards.data];
    }
  },

  [types.SET_NEW_CARD_MODE](state, payload) {
    state.newCardMode = payload;
  },

  [types.REMOVE_CREATE_CARD](state) {
    if (state.cards) {
      state.cards.data = state.cards?.data?.filter((card) => card.id !== -1);
    }
  },

  [types.SET_DECK_CARDS](state, payload) {
    if (payload) {
      payload.data = payload.data.map((card) => new CardEntity(card));
      payload = new PaginateEntity(payload);
    }
    Vue.set(state, 'cards', payload);
  },

  [types.IS_LOADING_MODE](state, payload) {
    Vue.set(state, 'isLoadingMode', payload);
  },

  resetDeckStudyInfo(state) {
    Vue.set(state.deck, 'daily_goal', null);
  },

  [types.IS_IMPORT_MODE](state, payload) {
    Vue.set(state, 'isImportDeckMode', payload);
  },

  setCardTypes(state, payload) {
    let cardTypes = null;
    if (payload) {
      cardTypes = payload.map((cardType) => new CardModelEntity(cardType));
    }
    state.cardTypes = cardTypes;
  },

  addToCardTypes(state, payload) {
    state.cardTypes.push(payload);
  },

  updateCardType(state, type) {
    const index = state.cardTypes.findIndex((item) => item.name.toLowerCase() === type.name);
    state.cardTypes[index] = type;
  },

  updateFields(state, { fields, typeName }) {
    const field = state.cardTypes.find((item) => item.name.toLowerCase() === typeName);
    Vue.set(field, 'fields', fields);
  },

  // cardModel
  [types.UPDATE_CARD_MODEL](state, { cardId, cardModel }) {
    const cardIndex = state.cards.data.findIndex((card) => card.id === cardId);

    if (cardIndex > -1) {
      state.cards.data[cardIndex].card_note.card_model = cardModel;
    }
  },

  [types.SET_CARD_FILTER_OBJECT](state, object) {
    Vue.set(state, 'cardFilterObject', object);
  },

  [types.SET_ACTIVE_CARD_MODEL](state, cardModel) {
    Vue.set(state, 'activeCardModel', cardModel);
  },
};
