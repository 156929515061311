export default ({ $config, store }, inject) => {
  const scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.defer = true;
  scriptTag.type = 'text/javascript';
  scriptTag.id = 'jw-chatbot-script';
  scriptTag.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';

  const blackList = {
    regexes: [/dashboard/],
  };
  const shouldIncludeChatbotDefaultMessage = (routeName) => {
    return !blackList.regexes.some((blackRegex) => {
      return blackRegex.test(routeName);
    });
  };

  scriptTag.onload = () => {
    window.voiceflow.chat
      .load({
        verify: { projectID: $config.ALFRED_PROJECT_ID },
        url: 'https://general-runtime.voiceflow.com',
        versionID: $config.ALFRED_VERSION_ID,
      })
      .then(() => {});
  };

  let displayChatbotDefaultMessage = false;
  inject('chatbot', {
    toggleChatbotDefaultMessage: () => {
      setTimeout(() => {
        const isMobile = store.getters['ui/device'].isMobile;
        if (shouldIncludeChatbotDefaultMessage(window.location.pathname) && !isMobile) {
          if (!displayChatbotDefaultMessage) {
            window?.voiceflow?.chat?.proactive?.push({
              type: 'text',
              payload: {
                message:
                  "Welcome to Jack Westin! I'm here to help if you need anything just click on the chat any time...",
              },
            });
          }
          displayChatbotDefaultMessage = true;
        } else {
          window?.voiceflow?.chat?.proactive?.clear();
          displayChatbotDefaultMessage = false;
        }
      }, 1000);
    },
    setup: () => {
      if (document.getElementById(scriptTag.id)) {
        const chatbotContainer = document.getElementById('voiceflow-chat');
        if (chatbotContainer) {
          chatbotContainer.style.display = 'block';
        }
      } else {
        document.body.appendChild(scriptTag);
      }
    },
    remove: () => {
      const chatbotContainer = document.getElementById('voiceflow-chat');
      if (chatbotContainer) {
        chatbotContainer.style.display = 'none';
      }
    },
  });
};
