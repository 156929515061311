
import { mapGetters } from 'vuex';

export default {
  props: {
    hasSidebarToggled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('course', ['courseField']),
    ...mapGetters('ui', ['device']),
  },
};
