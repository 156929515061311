
import backgroundImagePath from '~/assets/images/checkout/bg-grey.png';
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: { CookieBannerWrapper },

  data() {
    return {
      backgroundImagePath,
    };
  },
};
