import { BREAK_POINTS } from '~/core/utils/constants';

export default {
  loading: (state) => state.loading,
  sidebar: (state) => state.sidebar,
  offsetTop: (state) => state.offsetTop,
  offsetBottom: (state) => state.offsetBottom,
  innerWidth: (state) => state.innerWidth,
  isMobileView: (state) => state.innerWidth <= 768,

  device: (state) => {
    const device = {
      isMobile: false, // only mobile
      isMobileOrTablet: false,
      isTablet: false, // only tablet
      isTabletOrDesktop: false,
      isDesktop: false, // only desktop (not wide)
      isDesktopOrWide: false,
      isWide: false, // only wide (not desktop)
    };

    if (state.innerWidth < BREAK_POINTS.MD) {
      device.isMobile = true;
      device.isMobileOrTablet = true;
      return device;
    }

    if (state.innerWidth < BREAK_POINTS.LARGE) {
      device.isTablet = true;
      device.isTabletOrDesktop = true;
      return device;
    }

    if (state.innerWidth < BREAK_POINTS.WIDE) {
      device.isDesktop = true;
      device.isDesktopOrWide = true;
      return device;
    } else {
      device.isWide = true;
      device.isDesktopOrWide = true;
      return device;
    }
  },
};
