import Vue from 'vue';
import gtmService from './gtm.service';
import gtmData from './gtm.data';
import gtmDirective from './gtm.directive';

export default ({ app }, inject) => {
  Vue.directive('gtm', gtmDirective(app, gtmData));
  const gtm = gtmService(app, gtmData);

  setTimeout(() => {
    if (app.$auth?.loggedIn) {
      gtm.dispatch('config', { user_id: app.$auth.user?.id });
    }
  }, 300);

  inject('gtmEvent', gtm);
};
