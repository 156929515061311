export default ({ store, $api }) => ({
  initializeRecentSearches() {
    store.commit('platform/initializeRecentSearches');
  },

  initialData() {
    return store.getters['platform/initialData'];
  },

  topBanners() {
    return store.getters['platform/initialData']?.banners.headers ?? [];
  },

  removeRecentlySearchItem(id) {
    store.commit('platform/removeRecentlySearchItem', id);
  },

  addSearchToRecentlySearchQueue(query) {
    store.commit('platform/addSearchToRecentlySearchQueue', query);
  },

  setSearchMode(mode) {
    store.commit('platform/setSearchMode', mode);
  },

  setPageLoadingMode(mode) {
    store.commit('platform/setPageLoadingMode', mode);
  },

  resetSections() {
    store.commit('platform/resetSections');
  },

  resetData() {
    store.commit('platform/resetData');
  },

  async unsubscribeDeck(deck) {
    await store.dispatch('platform/unsubscribeDeck', deck);
  },

  copyCard({ destinationDeckId, originDeckId, originCardId, tags }) {
    return $api.flashcard.copyCard({ destinationDeckId, originDeckId, originCardId, tags });
  },

  copyDeck(deckId) {
    return $api.flashcard.copyDeck(deckId);
  },
});
