const BASE_V1_API_PATH = '/api/v1/flashcard';
const BASE_V2_API_PATH = '/api/v2/flashcard';

export default (ctx) => ({
  async getSearchSuggestions(query) {
    const { $axios } = ctx;
    const params = { query };
    const { data } = await $axios.get(`${BASE_V1_API_PATH}/decks/search/suggestion`, { params });
    return data;
  },

  async getSectionDecks({ section, perPage = 12, query, page = 1 }) {
    const { $axios, store } = ctx;
    const params = { section, per_page: perPage, query, page };
    const response = await $axios.get(`${BASE_V2_API_PATH}/decks`, { params });

    store.dispatch('platform/setBrowseSections', { section, response });

    return response;
  },

  async searchDecks({ query, page }) {
    const { $axios } = ctx;
    const params = { query, page: page ?? 1, per_page: 12 };
    const response = await $axios.get(`${BASE_V2_API_PATH}/decks`, { params });

    return response;
  },

  async fetchInitialInformation() {
    const { $axios, store } = ctx;
    const { data } = await $axios.get(`${BASE_V1_API_PATH}/initial`);
    store.dispatch('platform/setInitialData', data);
    return data;
  },

  async initialSectionDecks({ section }) {
    const { store } = ctx;
    const sectionInfo = store.getters['platform/browseSections'][section];

    if (sectionInfo !== undefined) {
      return sectionInfo;
    }

    await this.getSectionDecks({ section });

    return store.getters['platform/browseSections'][section];
  },

  unsubscribeDeck(deck) {
    const { store } = ctx;
    deck.has_study_started = false;
    store.commit('platform/unsubscribeDeck', deck.id);
  },
});
