
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
    },
    hiddenOverflow: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    isPositionFixed: {
      type: Boolean,
      default: false,
    },
    widthClasses: {
      type: String,
      default: 'w-full sm:max-w-xs md:max-w-md xl:max-w-lg',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.showModal = value;
      },
    },
  },

  mounted() {
    window.addEventListener('keyup', this.onKeyup);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup);
  },

  methods: {
    closeIfShown() {
      this.$emit('closed');
      if (this.closeable && this.showModal) {
        this.showModal = !this.showModal;
      }
    },
    onKeyup(event) {
      if (!this.show) {
        return;
      }
      if (event.key === 'Escape') {
        this.closeIfShown();
      }
    },
  },
};
