
import { mapGetters } from 'vuex';
import styles from './navbar-menu.css?raw';
import styleInjector from '~/core/mixins/styleInjector';
import { BREAK_POINTS } from '~/core/utils/constants';

export default {
  mixins: [styleInjector(styles)],
  props: {
    isNavbarLinksVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isProgressEnabled: false,
      showHubSideBar: false,
      navHeight: null,
    };
  },
  computed: {
    ...mapGetters('player', ['completedLessonsProgress']),
    ...mapGetters('course', ['hasCourseContent']),
    ...mapGetters('ui', ['device', 'innerWidth']),
    breakPoints() {
      return BREAK_POINTS;
    },
    isEnvAffiliate() {
      return !!(process.env.APP_AFFILIATE === 'true' || process.env.APP_AFFILIATE === undefined);
    },
    isEnvStudentAffiliate() {
      return !!(process.env.APP_STUDENT_AFFILIATE === 'true' || process.env.APP_STUDENT_AFFILIATE === undefined);
    },
    phoneNumber() {
      return this.$config.phoneNumber;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.showHubSideBar = false;
      },
    },
    showHubSideBar: {
      immediate: true,
      handler(value) {
        if (value) {
          this.navHeight = this.$refs.mainNavbar?.clientHeight;
        }
      },
    },
  },
  created() {
    this.$nuxt.$on(this.$config.event.enable_player_progress, (isEnabled) => {
      this.isProgressEnabled = isEnabled;
    });
  },
  methods: {
    onCallOrText() {
      this.$gtmEvent.withUserStatus().dispatch('call_or_text');
    },
    toggleHubSideBar(status = !this.showHubSideBar) {
      this.showHubSideBar = status;
    },
  },
};
