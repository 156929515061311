export const scrollTransition = {
  data() {
    return {
      navbarHeigh: 50,
    };
  },
  methods: {
    onPageScroll() {
      this.$nextTick(() => {
        const pageY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        const el = document.getElementById('sidebar');
        const navbar = document.getElementById('navbar');
        if (el) {
          if (pageY > 50) {
            if (navbar) {
              if (window.getComputedStyle(navbar).getPropertyValue('position') === 'fixed') {
                el.style.top = `${this.navbarHeigh}px`;
              } else {
                el.style.top = '0px';
              }
            } else {
              el.style.top = `${this.navbarHeigh}px`;
            }
          } else {
            el.style.top = `${this.navbarHeigh - pageY}px`;
          }
        }
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onPageScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onPageScroll);
  },
};
