
import { mapGetters, mapActions } from 'vuex';
import { BREAK_POINTS } from '~/core/utils/constants';

export default {
  computed: {
    ...mapGetters('session', ['sessions']),
    ...mapGetters('passage', ['dailyPassages']),
    ...mapGetters('user', ['metaValue']),
    ...mapGetters('ui', ['device', 'innerWidth']),
    breakPoints() {
      return BREAK_POINTS;
    },
    extraNavs() {
      return [
        {
          title: 'Khan Academy',
          image: 'icon-khan-academy',
          to: '/khan-academy',
          gtm_event: null,
        },
        {
          title: 'Retake Calculator',
          image: 'icon-retake-calc',
          to: '/mcat-retake-calculator',
          gtm_event: null,
        },
        {
          title: 'AAMC Chrome Extension',
          image: 'icon-chrome-extension',
          to: 'https://chrome.google.com/webstore/detail/aamc-mcat-interface-by-ja/jgglfdpjpddcdaeapbcfgckfheabbdpi',
          gtm_event: 'aamc_chrome_extension__sidebar__navigation',
          target: '_blank',
        },
        {
          title: 'Reviews',
          image: 'icon-reviews',
          to: '/reviews',
          gtm_event: null,
        },
        {
          title: 'About Jack Westin',
          to: '/about',
          image: 'icon-about',
          gtm_event: 'about_jackwestin__sidebar__navigation',
          target: '_blank',
        },
        {
          title: 'Faq',
          to: '/faq',
          image: 'icon-faq',
          gtm_event: 'faq__sidebar__navigation',
          target: '_blank',
        },
        {
          title: 'Blog',
          to: '/resources/blog',
          image: 'icon-blog',
          gtm_event: null,
          target: '_blank',
        },
        {
          title: 'Contact',
          to: '/contact-jack',
          image: 'icon-contact',
          gtm_event: null,
          target: '_blank',
        },
        {
          title: 'Support',
          to: '/support',
          image: 'icon-support',
          gtm_event: null,
          target: '_blank',
        },
      ];
    },
  },
  methods: {
    ...mapActions('application', ['setForceShowHomePage']),
    getItemNavImage(itemNavImage) {
      return require(`~/assets/icons/fb/enrolled-navbar/${itemNavImage}.svg`);
    },
    redirectToHomepage() {
      this.setForceShowHomePage(true);
      this.$router.push({ name: 'index' });
    },
  },
};
