import types from './types';

export default {
  next({ commit, getters, rootState }) {
    if (getters.currentIndex === rootState.course.lessonsOrderSum) {
      return;
    }
    commit(types.INCREASE_INDEX);
  },

  prev({ commit, getters }) {
    if (getters.currentIndex === 1) {
      return;
    }
    commit(types.DECREASE_INDEX);
  },

  setCurrentSectionId({ commit }, sectionId) {
    commit(types.SET_CURRENT_SECTION_ID, sectionId);
  },

  setCurrentLesson({ commit }, lesson) {
    commit(types.SET_CURRENT_LESSON, lesson);
    commit(types.SET_CURRENT_INDEX, lesson.global_order_column);
  },

  setCompletedLessons({ commit }, course) {
    commit(types.SET_COMPLETED_LESSONS, course);
  },

  async setCompletedLesson({ commit, dispatch, getters }, lessonId) {
    try {
      if (getters.completedLessons.includes(lessonId)) {
        return;
      }
      await dispatch('toggleLessonComplete', lessonId);
      commit(types.PUSH_TO_COMPLETED_LESSONS, lessonId);
    } catch (error) {}
  },

  async toggleLessonComplete({ dispatch }, lessonId) {
    try {
      await this.$api.preview.toggleLessonComplete(lessonId);
      dispatch('course/toggleLessonComplete', lessonId, {
        root: true,
      });
      dispatch('course/toggleWeeklyLessonComplete', lessonId, {
        root: true,
      });
      dispatch('course/toggleClassifyAssignmentsLessonComplete', lessonId, {
        root: true,
      });
    } catch (error) {}
  },

  async playRecord(_, { recordId, payload }) {
    const res = await this.$api.preview.playRecord(recordId, payload);
    return res;
  },

  setCategorizedSections({ commit }, payload) {
    commit(types.SET_CATEGORIZED_SECTIONS, payload);
  },

  setCurrentCategorizedLessonLocation({ commit }, payload) {
    commit(types.SET_CURRENT_CATEGORIZED_LESSON_LOCATION, payload);
  },

  selectCategorizedLessonByLocation({ commit, dispatch }, lessonLocation) {
    commit(types.SET_CURRENT_CATEGORIZED_LESSON_LOCATION, lessonLocation);
    dispatch('expandForLesson', lessonLocation);
  },

  turnNextCategorizedLesson({ dispatch, getters }) {
    const nextCategorizedLessonLocation = getters.nextCategorizedLessonLocation;
    if (!nextCategorizedLessonLocation) {
      return;
    }
    dispatch('selectCategorizedLessonByLocation', nextCategorizedLessonLocation);
  },

  turnPreviousCategorizedLesson({ dispatch, getters }) {
    const previousCategorizedLessonLocation = getters.previousCategorizedLessonLocation;
    if (!previousCategorizedLessonLocation) {
      return;
    }
    dispatch('selectCategorizedLessonByLocation', previousCategorizedLessonLocation);
  },

  getCategorizedLessonLocation({ getters }, lesson) {
    const { section_id: sectionId, id: lessonId } = lesson;
    let location = {
      categoryIndex: -1,
      sectionIndex: -1,
      lessonIndex: -1,
    };
    const { categorizedSections } = getters;
    categorizedSections.forEach((category, categoryIndex) => {
      category.sections.forEach((section, sectionIndex) => {
        if (section.id === sectionId) {
          section.lessons.forEach((lesson, lessonIndex) => {
            if (lesson.id === lessonId) {
              location = {
                lessonIndex,
                sectionIndex,
                categoryIndex,
              };
            }
          });
        }
      });
    });
    return location;
  },

  async selectCategorizedLesson({ dispatch }, payload) {
    const location = await dispatch('getCategorizedLessonLocation', payload.lesson);
    if (payload.toggleSidebar) {
      dispatch('selectCategorizedLessonByLocation', location);
    }
  },

  toggleCategory({ commit, state, getters }, payload) {
    commit(types.TOGGLE_CATEGORY, { getters, payload });
    const { categoryIndex } = payload;
    const isExpanded = state.categorizedSections[categoryIndex].isExpanded;
    if (isExpanded) {
      // collapse other categories
      commit(types.COLLAPSE_CATEGORIES, { exceptCategoryIndex: categoryIndex });
    } else {
      // collapse all sections of this category
      state.categorizedSections[categoryIndex].sections.forEach((_, sectionIndex) =>
        commit(types.TOGGLE_SECTION, {
          getters,
          payload: {
            ...payload,
            sectionIndex,
            isExpanded: false,
          },
        }),
      );
    }
  },

  toggleSection({ state, commit, getters }, payload) {
    commit(types.TOGGLE_SECTION, { getters, payload });
    const { categoryIndex, sectionIndex } = payload;
    // collapse other sections of this category
    const isExpanded = state.categorizedSections[categoryIndex].sections[sectionIndex].isExpanded;
    if (isExpanded) {
      commit(types.COLLAPSE_SECTIONS, { categoryIndex, exceptSectionIndex: sectionIndex });
    }
  },

  expandForLesson({ dispatch }, payload) {
    const { categoryIndex, sectionIndex } = payload;
    const isExpanded = true;
    dispatch('toggleCategory', {
      categoryIndex,
      isExpanded,
    });
    dispatch('toggleSection', {
      categoryIndex,
      sectionIndex,
      isExpanded,
    });
  },

  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
};
