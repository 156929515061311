
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isRegisterMode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(isVisible) {
      document.body.style.overflowY = isVisible ? 'hidden' : 'auto';
    },
  },
  created() {
    this.$nuxt.$on(this.$config.event.logged_in, () => {
      this.$emit('success');
    });
  },
};
