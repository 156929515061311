
import { scrollTransition } from '~/plugins/mixins/scrollTransition';
import CookieBannerWrapper from '@/components/ui/banner/accept-cookie-banner-wrapper.vue';

export default {
  components: { CookieBannerWrapper },
  mixins: [scrollTransition],
  data() {
    return {
      navbarSidebar: 'index',
      isSidebarOpen: false,
    };
  },
  watch: {
    $route(to) {
      this.page = to.name;
    },
  },
  watchQuery: ['page'],
  created() {
    // Disable sidebar toggle for first
    this.$nuxt.$emit(this.$config.event.sidebar.enable_toggle, false);
    // Listen to sidebar open/close
    this.$nuxt.$on(this.$config.event.sidebar.toggle_sidebar, () => {
      this.isSidebarOpen = !this.isSidebarOpen;
    });
    this.$nuxt.$on('on-change-sidebar', (navbarSidebar) => {
      // Change sidebar menu
      this.navbarSidebar = navbarSidebar || 'index';
      // Enable sidebar toggle
      this.$nuxt.$emit(this.$config.event.sidebar.enable_toggle, true);
    });
  },
  methods: {
    closeIfShown() {
      this.isSidebarOpen = false;
    },
  },
};
