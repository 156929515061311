export default ($axios) => ({
  updateDefaultPaymentMethod(paymentMethod) {
    return $axios.put('/api/caap/user/card', {
      payment_method: paymentMethod,
    });
  },

  postEnroll(params) {
    return $axios.post(`/api/caap/classes/${params.classId}/enrollments`, {
      is_installment: params.isInstallment,
      payment_method: params.paymentMethod,
    });
  },
});
