
export default {
  data() {
    return {
      hasBanner: true,
      enrolledCourses: this.$hub.enrolledCourses,
      allCourses: this.$hub.allCourses,
      defaultFieldName: this.$hub.defaultField?.name,
    };
  },

  computed: {
    hasDivider() {
      return this.hasEnrolledCourses && this.hasAllCourses;
    },
    hasEnrolledCourses() {
      return this.enrolledCourses?.length;
    },
    hasAllCourses() {
      return this.allCourses?.length;
    },
    showBanner() {
      return this.defaultFieldName && this.defaultFieldName !== 'MCAT';
    },
  },
  watch: {
    enrolledCourses: {
      deep: true,
      immediate: true,
      handler() {
        this.defaultFieldName = this.$hub.defaultField?.name;
      },
    },
  },

  methods: {
    async resetDefaultCourse() {
      await this.$hub.resetDefaultCourse();
      this.$router.push({ name: 'index' });
    },
  },
};
