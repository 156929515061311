import types from './types';

export default {
  setBrowseSections({ commit }, { section, response }) {
    commit(types.SET_BROWSE_SECTION, { section, response });
  },
  setInitialData({ commit }, data) {
    commit(types.SET_INITIAL_DATA, data);
  },
  async unsubscribeDeck({ commit }, deck) {
    await this.$api.flashcard.resetDeck(deck.id);
    commit('unsubscribeDeck', deck);
  },
};
