
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import styles from './phone-number-with-counter-code.css?raw';
import { randomFixedInteger } from '~/core/utils/numbers';
import styleInjector from '~/core/mixins/styleInjector';

export default {
  mixins: [styleInjector(styles)],
  inheritAttrs: false,
  props: {
    error: {
      type: [String, null],
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: [String, Number],
      default: () => `input-${randomFixedInteger()}`,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iti: null,
    };
  },
  computed: {
    phoneNumber() {
      return this.value ?? '';
    },
    currentCode() {
      if (this.iti !== null) {
        return this.iti.getSelectedCountryData()?.dialCode ?? '1';
      }

      return '1';
    },
    currentCodeWithOutLastChar() {
      return this.currentCode.slice(0, this.currentCode.length - 1);
    },
  },
  watch: {
    phoneNumber() {
      if (!this.iti) {
        return;
      }

      if (this.phoneNumber.indexOf(this.currentCode) !== 1) {
        this.iti.setNumber(this.phoneNumber);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.initializeInput();
    }, 500);
  },
  methods: {
    initializeInput() {
      const input = document.getElementById(this.id);
      this.iti = intlTelInput(input, {
        initialCountry: 'us',
        separateDialCode: true,
        preferredCountries: [],
      });

      // On slow renders, the input is initialized with a value
      // without a country code. Using the below code to re-assign
      // the input's value with country code
      input.value = this.phoneNumber;

      input.addEventListener('blur', () => {
        if (!input.value.startsWith('+')) {
          input.value = this.phoneNumber;
          this.inputChange(input.value);
        }
      });
    },
    inputChange(value) {
      let newValue = value;
      if (!value.startsWith('+') && value !== '') {
        newValue = `+${value}`;
      }
      this.$emit('input', newValue);
    },
  },
};
