const BASE_API_PATH = '/api/v1/flashcard';
const BASE_V2_API_PATH = '/api/v2/flashcard';

export default ($axios) => ({
  fetchDeckBySlug(slug) {
    try {
      return $axios.get(`${BASE_API_PATH}/decks/${slug}`);
    } catch (err) {}
  },

  copyrightReport(deckId, cardId, params) {
    return $axios.post(`${BASE_API_PATH}/copyright/report`, {
      deck_id: deckId,
      card_id: cardId,
      full_name: params.fullName,
      email_address: params.emailAddress,
      physical_address: params.physicalAddress,
      phone_number: params.phoneNumber,
      description: params.description,
    });
  },

  updateDeck(data) {
    try {
      return $axios.put(`${BASE_API_PATH}/decks/${data.id}`, {
        title: data.title,
        description: data.description,
        publish_status: data.publish_status,
        access_status: data.access_status,
        is_unlisted: data.is_unlisted,
        is_copyable: data.is_copyable,
      });
    } catch (err) {}
  },

  fetchCardTimeline(deckId, cardId) {
    return $axios.get(`${BASE_API_PATH}/decks/${deckId}/cards/${cardId}/timeline`, {});
  },

  fetchFlattenTags(deckId) {
    return $axios.get(`${BASE_API_PATH}/decks/${deckId}/flatten-tags`);
  },

  fetchNestedTags(deckId, params = {}) {
    return $axios.get(`${BASE_API_PATH}/decks/${deckId}/tags`, { params });
  },

  updateDeckThumbnail(data) {
    return $axios.post(`${BASE_API_PATH}/decks/${data.id}/banner`, data.formData);
  },

  updateDeckStudyDetails({ deckId, dailyGoal, suspendedTags, insertionType }) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/study`, {
      daily_goal: dailyGoal,
      suspended_tags: suspendedTags,
      insertion_type: insertionType,
    });
  },

  fetchAllCards(deckId, params = {}) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/cards`, { params });
  },

  fetchSingleCard(deckId, cardId) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/cards/${cardId}`);
  },

  fetchSingleCardHash(deckId, cardId) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/cards/${cardId}/hash`);
  },

  flagCard(deckId, cardId, isFlagged) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/cards/${cardId}/flag`, { is_flagged: isFlagged });
  },

  suspendCard(deckId, cardId, isSuspended, source) {
    return $axios.post(
      `${BASE_API_PATH}/decks/${deckId}/cards/${cardId}/suspend`,
      {
        is_suspended: isSuspended,
      },
      {
        cancelToken: source?.token,
      },
    );
  },

  suspendTag(deckId, tagId, isSuspended) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/tags/${tagId}/suspend`, { is_suspended: isSuspended });
  },

  activateTag(deckId, tagId, isActivated) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/tags/${tagId}/active`, {
      publish_status: isActivated ? 1 : 0,
    });
  },

  updateCard(deckId, cardId, cardInfo) {
    try {
      return $axios.put(`${BASE_V2_API_PATH}/decks/${deckId}/cards/${cardId}`, cardInfo);
    } catch (err) {}
  },

  deleteCard(deckId, cardId) {
    return $axios.delete(`${BASE_API_PATH}/decks/${deckId}/cards/${cardId}`);
  },

  createTag(deckId, { title, parent_id = null }) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/tags`, {
      title,
      parent_id,
    });
  },

  fetchRoles() {
    return $axios.get(`${BASE_API_PATH}/roles`);
  },

  addTeamMember(data) {
    try {
      return $axios.post(`${BASE_API_PATH}/decks/${data.deckId}/team`, {
        email: data.email,
        role_id: data.role,
      });
    } catch (err) {}
  },

  deleteTeamMember(data) {
    return $axios.delete(`${BASE_API_PATH}/decks/${data.deckId}/team/${data.member.id}`);
  },

  updateMemberRole(deckId, teamId, roleId) {
    return $axios.put(`${BASE_API_PATH}/decks/${deckId}/team/${teamId}`, {
      role_id: roleId,
    });
  },

  resetDeck(deckId) {
    return $axios.post(`${BASE_API_PATH}/decks/${deckId}/reset`);
  },

  deleteDeck(deckId) {
    return $axios.delete(`${BASE_API_PATH}/decks/${deckId}`);
  },

  fetchDeckStatistics(deckId) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/statistics`);
  },

  fetchTeam(deckId) {
    return $axios.get(`${BASE_API_PATH}/decks/${deckId}/team`);
  },

  updateTag(deckId, tagId, data) {
    try {
      return $axios.put(`${BASE_API_PATH}/decks/${deckId}/tags/${tagId}`, data);
    } catch (err) {}
  },

  createCard(deckId, data) {
    try {
      return $axios.post(`${BASE_V2_API_PATH}/decks/${deckId}/cards`, data);
    } catch (err) {}
  },

  importDeck(formData, source, callback = () => {}) {
    const config = {
      cancelToken: source?.token,
      onUploadProgress(progressEvent) {
        callback(progressEvent);
      },
    };

    return $axios.post(`${BASE_API_PATH}/import-deck`, formData, config);
  },

  createDeck(title, description) {
    try {
      return $axios.post(`${BASE_API_PATH}/decks`, { title, description });
    } catch (err) {}
  },

  setStudyAnswer(deckId, cardId, difficulty, override) {
    try {
      return $axios.post(`${BASE_API_PATH}/decks/${deckId}/answer/${cardId}`, {
        difficulty,
        override,
      });
    } catch (err) {}
  },

  fetchStudyStatistics(deckId) {
    try {
      return $axios.get(`${BASE_API_PATH}/decks/${deckId}/statistics`);
    } catch (err) {}
  },

  copyCard({ destinationDeckId, originDeckId, originCardId, tags }) {
    try {
      return $axios.post(`${BASE_V2_API_PATH}/decks/${originDeckId}/cards/${originCardId}/copy`, {
        deck_id: destinationDeckId,
        tags,
      });
    } catch (err) {}
  },

  copyDeck(deckId) {
    try {
      return $axios.post(`${BASE_API_PATH}/decks/${deckId}/copy`);
    } catch (err) {}
  },

  fetchHomePageDecks() {
    try {
      return $axios.get(`${BASE_V2_API_PATH}/decks`, {
        params: {
          show_on_homepage: true,
          per_page: 3,
        },
      });
    } catch (err) {}
  },

  async getCardTypes(deckId) {
    try {
      const { data } = await $axios.get(`${BASE_API_PATH}/decks/${deckId}/types`);

      return data;
    } catch (err) {}
  },

  async createCardType(deckId, name, from) {
    try {
      const { data } = await $axios.post(`${BASE_API_PATH}/decks/${deckId}/types`, {
        name,
        from_id: from.id,
      });

      return data;
    } catch (err) {}
  },

  async updateFields({ fields, cardModel }) {
    return await $axios.put(`${BASE_API_PATH}/decks/${cardModel.deck_id}/types/${cardModel.id}`, {
      ...cardModel,
      type: +cardModel.type,
      tmpls: JSON.stringify(cardModel.tmpls),
      fields: JSON.stringify(fields),
      req: JSON.stringify({}),
    });
  },

  updateCardModel(cardModel, source) {
    const { name, type, tmpls, fields, css } = cardModel;

    return $axios.put(
      `${BASE_API_PATH}/decks/${cardModel.deck_id}/types/${cardModel.id}`,
      {
        name,
        type,
        sort_field: cardModel.sort_field,
        tmpls: JSON.stringify(tmpls),
        fields: JSON.stringify(fields),
        css,
        req: JSON.stringify({}),
      },
      {
        cancelToken: source?.token,
      },
    );
  },

  getDeckStudySessionCards(deckId) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/session`);
  },

  getSubdeckStudySessionCards(deckId, subdeckId) {
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/session/${subdeckId}`);
  },

  extendStudySession({ deckId, numberOfCards }) {
    return $axios.post(`${BASE_V2_API_PATH}/decks/${deckId}/session`, {
      total_cards: numberOfCards,
    });
  },

  fetchAvailableNewCards({ deckId, subdeckId }) {
    const restOfUrl = subdeckId ? `/${subdeckId}` : '';
    return $axios.get(`${BASE_V2_API_PATH}/decks/${deckId}/session/new-cards${restOfUrl}`);
  },
});
