export default {
  exam: (state) => state.exam,
  extendTimeRatio: (state) => state.extendTimeRatio,
  delay: (state) => state.delay,
  status: (state) => {
    const status = {
      is: { notStarted: false, completed: false, paused: false },
      label: '',
    };

    if (state.exam?.status === 'completed') {
      status.is.completed = true;
      status.label = 'Completed';
    } else if (state.exam?.status === 'paused') {
      status.is.paused = true;
      status.label = 'Paused';
    } else {
      status.is.notStarted = true;
      status.label = 'Not Started';
    }

    return status;
  },
  hasExtendedToInfinity: (state) => state.extendTimeRatio === Infinity,
  testType: (state) => state.exam?.test_type?.toLowerCase(),
  isSAT: (_, getters) => getters.testType === 'sat',
  isDAT: (_, getters) => getters.testType === 'dat',
  isMcat: (_, getters) => getters.testType === 'mcat',
  hasWithSolution: (state) => state.withSolution,
};
