import Vue from 'vue';
import types from './types';
import { defaultState } from './state';

export default {
  [types.SET_IS_READY](state, payload) {
    state.isReady = payload;
  },

  [types.SET_QUIZ](state, payload) {
    state.quiz = payload;
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },

  [types.SET_SECTION_INDEX](state, payload) {
    state.sectionIndex = payload;
  },

  [types.SET_QUESTION_INDEX](state, payload) {
    state.questionIndex = payload;
  },

  [types.SET_HIGHLIGHTS](state, payload) {
    state.highlights = payload;
  },

  [types.APPEND_NEW_HIGHLIGHT](state, { type, key, highlight }) {
    const highlights = { ...state.highlights };
    if (!highlights[type]) {
      highlights[type] = {};
    }
    if (!Array.isArray(highlights[type][key])) {
      highlights[type][key] = [];
    }
    highlights[type][key].push(highlight);
    state.highlights = highlights;
  },

  [types.MODIFY_QUESTION_PROPERTY](_, { question, key, value }) {
    Vue.set(question, key, value);
  },

  [types.ATTACH_QUESTION_FEATURES](state, payload) {
    state.questionFeatures.push(...payload);
  },

  [types.SYNC_SECTION_FEATURES](state, payload) {
    state.questionFeatures = payload;
  },

  [types.SYNC_QUESTION_FEATURES](state, payload) {
    state.sectionFeatures = payload;
  },

  [types.SET_IS_REVIEW_MODE](state, isReviewMode) {
    state.isReviewMode = isReviewMode;
  },

  [types.SET_FILTERED_QUESTIONS](state, filteredQuestions) {
    state.filteredQuestions = filteredQuestions;
  },

  [types.SET_TEST_ACTIVE](state, payload) {
    state.isTestActive = payload;
  },

  [types.SET_FINISHING_MODE](state, payload) {
    state.finishingMode = payload;
  },

  [types.SET_SECTION_TIME_ALLOTED](state, { activeSection, duration_in_secs }) {
    state.quiz.sections = state.quiz.sections.map((section) => {
      if (section.section_id === activeSection.section_id) {
        section.time_allotted = duration_in_secs;
      }
      return section;
    });
  },

  [types.SET_DELAY](state, delay) {
    state.quiz.test.metadata = {
      ...state.quiz.test.metadata,
      delay,
    };
  },

  [types.SET_DELAY_LOADING](state, payload) {
    state.delayLoading = payload;
  },

  [types.SET_ACTIVE_RANGE](state, payload) {
    state.activeRange = payload;
  },

  [types.SET_SHOW_SOLUTION](state, payload) {
    state.showSolution = payload;
  },
};
