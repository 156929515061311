export default ($axios) => ({
  verifyPhoneNumber(token) {
    return $axios.post('/api/v1/user/phone-number', { token });
  },

  lookupPhoneNumber(PhoneNumber) {
    return $axios.post('/api/v1/user/phone-numbers', { phone_number: PhoneNumber });
  },

  updateProfileInfo({ phone_number, test_date }) {
    return $axios.patch('/api/v1/user', { phone_number, test_date });
  },
});
