const API_VERSION = 'v1';
const BASE_API_PATH = `/api/${API_VERSION}/radar`;

export default ($axios) => ({
  fetchAllProjects(search, order, sort) {
    return $axios.get(`${BASE_API_PATH}/pages`, {
      params: {
        page_title: search,
        sort,
        order,
      },
    });
  },

  fetchProject(id, code = null) {
    return $axios.get(`${BASE_API_PATH}/pages/${id}`, { params: { code } });
  },

  fetchQRCodes(page_id) {
    return $axios.get(`${BASE_API_PATH}/pages/${page_id}/qrcodes`);
  },

  generateQRCode(page_id) {
    return $axios.post(`${BASE_API_PATH}/pages/${page_id}/qrcodes`);
  },

  fetchAttributeKeys() {
    return $axios.get(`${BASE_API_PATH}/attributes`, { params: { 'flat-response': false } });
  },

  fetchAttributeValueList(qrcode_id) {
    return $axios.get(`${BASE_API_PATH}/qrcode/${qrcode_id}/attributes`, { params: {} });
  },

  removeAttributeValue(attribute) {
    return $axios.delete(`${BASE_API_PATH}/attributes/${attribute.key_id}/values/${attribute.id}`);
  },

  addAttributeValue(attribute) {
    return $axios.post(`${BASE_API_PATH}/attributes/${attribute.key_id}/values`, {
      value: attribute.value,
      item_type: 'qrcode',
      item_id: attribute.item_id,
    });
  },

  updateAttributeValue(attribute) {
    return $axios.put(`${BASE_API_PATH}/attributes/${attribute.key_id}/values/${attribute.id}`, {
      value: attribute.value,
      item_type: 'qrcode',
      key_id: attribute.key_id,
      item_id: attribute.item_id,
    });
  },

  createPage(data) {
    try {
      return $axios.post(`${BASE_API_PATH}/pages`, data);
    } catch {}
  },

  updatePage(data, pageId) {
    try {
      return $axios.post(`${BASE_API_PATH}/pages/${pageId}`, data);
    } catch {}
  },

  fetchSessions(params) {
    return $axios.get('/api/caap/sessions', {
      params: {
        radar_page: params.pageId,
        radar_only: 1,
        ...params,
      },
    });
  },
});
