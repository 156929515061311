import { difference } from 'lodash';

export default (app, gtmData) => ({
  _withUserStatus: false,
  dispatch(eventName, payload = {}) {
    const event = gtmData.customEvents.find((event) => event.name === eventName);
    if (!event) {
      app.$log('Event not found!', eventName);
      app.$bugsnag.notify(`Event not found! ${eventName}.`);
      return;
    }

    const params = event.params || {};
    for (const paramKey in params) {
      params[paramKey] = payload[paramKey] || null;
    }

    const differenceBetweenPayloadAndExpectedParams = difference(Object.keys(payload), Object.keys(params));
    if (differenceBetweenPayloadAndExpectedParams.length) {
      const errorMessage = `Unexpected params added! ${JSON.stringify(differenceBetweenPayloadAndExpectedParams)}.`;
      app.$log(errorMessage);
      app.$bugsnag.notify(errorMessage);
    }

    if (this._withUserStatus) {
      params.user_status = app.$auth?.loggedIn ? 'logged_in' : 'guest';
    }

    app.$gtm.push({ event: event.name, ...params });
    this._withUserStatus = false;
  },
  withUserStatus() {
    this._withUserStatus = true;
    return this;
  },
});
