export default async function ({ $axios, $auth, store, $services }) {
  if ($auth.user) {
    return;
  }
  try {
    const { data, meta, utm_parameters } = await $axios.get('/api/caap/guestable-me', {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    if (data) {
      await $auth.setUser({ ...(data || {}), ...(meta || {}) });
      await $auth.$storage.setUniversal('loggedIn', true);
    }
    if (utm_parameters) {
      $services.utm.setUrlUtmCookie(utm_parameters);
    }
    store.dispatch('user/setUserMeta', meta || {});
  } catch (error) {}
}
