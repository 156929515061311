import Vue from 'vue';
import { randomFixedInteger } from '~/core/utils/numbers';

export default ({ store, $api, $config }) => ({
  async getCardTypes(deckId, forceReload = false) {
    let types = store.getters['flashcard/cardTypes'];

    if (types === null || forceReload) {
      types = await $api.flashcard.getCardTypes(deckId);
      store.commit('flashcard/setCardTypes', types);
    }

    return types;
  },

  async updateFields({ fields, deckId, typeName, cardModelId }) {
    const types = store.getters['flashcard/cardTypes'];
    const cardModel = types.find((type) => type.id === cardModelId);

    await $api.flashcard.updateFields({ fields, deckId, cardModel });
    store.commit('flashcard/updateFields', { fields, typeName });
  },

  appendAnkiPackageScript() {
    return new Promise((resolve) => {
      const hasAnki = document.getElementById('jw-renderer');
      if (hasAnki) {
        resolve(true);
      }

      const randomHash = randomFixedInteger(5);
      const src = `${$config.FLASHCARD_IFRAME_URL}/anki-renderer/main.js?id=${randomHash}`;
      const script = document.createElement('script');
      script.id = 'jw-renderer';
      script.src = src;
      document.head.appendChild(script);
      resolve(true);
    });
  },

  async createCardType({ deckId, name, from }) {
    const newType = await $api.flashcard.createCardType(deckId, name, from);
    store.commit('flashcard/addToCardTypes', newType);

    return newType;
  },

  async updateCardModel(cardId, cardModel, deckSlug, source) {
    const { data } = await $api.flashcard.updateCardModel(cardModel, source);
    await store.dispatch('flashcard/updateCardModel', {
      cardId,
      cardModel: data,
    });
    await store.dispatch('flashcard/fetchDeckBySlug', deckSlug);
    Vue.$jwToast.success('Template updated.');
  },
});
