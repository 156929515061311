export default () => ({
  studentPurchases: [],
  studentCourses: [],
  studentCourse: null,
  studentEnrollment: [],
  studentEnrollmentClass: [],
  studentCards: [],
  studentTiers: [],
  studentTier: null,
  studentPaymentReminds: 0,
  studentExtensions: null,
  isStudentInWaitingForNextSession: false,
  studentPaymentDetail: null,
  upcomingInvoice: null,
  user: {
    connected_to_google: false,
    connected_to_facebook: false,
  },
  hasStudentExtendedCourse: false,
  tierClassEnrollments: [],
  isThankyouPageAvailable: false,
  enabledDiscountCode: null,
});
