import types from './types';
import { DeckEntity } from '~/core/entities/flashcard/deck.entity';
import { CardEntity } from '~/core/entities/flashcard/card.entity';

export default {
  async fetchHomePageDecks() {
    try {
      const { data } = await this.$api.flashcard.fetchHomePageDecks();
      return data.map((deck) => new DeckEntity(deck));
    } catch {}
  },

  async sendCopyrightReport(_, { deckId, cardId, params }) {
    await this.$api.flashcard.copyrightReport(deckId, cardId, params);
  },

  resetData({ commit }) {
    try {
      commit(types.SET_DECK, null);
      commit(types.SET_FLATTED_TAGS, []);
      commit(types.SET_NESTED_TAGS, []);
      commit(types.SET_DECK_CARDS, null);
      commit('setCardTypes', null);
      commit(types.SET_ACTIVE_CARD_MODEL, null);
    } catch (err) {
      this.$log('err', err);
    }
  },

  resetAllCard({ commit }) {
    commit(types.SET_DECK_CARDS, null);
  },

  resetAllTags({ commit }) {
    commit(types.SET_FLATTED_TAGS, []);
    commit(types.SET_NESTED_TAGS, []);
  },

  async fetchDeckBySlug({ commit }, slug) {
    const { data } = await this.$api.flashcard.fetchDeckBySlug(slug);
    commit(types.SET_DECK, data);
    return data;
  },

  async fetchCardTimeline(_, { deckId, cardId }) {
    try {
      const { data } = await this.$api.flashcard.fetchCardTimeline(deckId, cardId);
      return data;
    } catch {}
  },

  async fetchFlattenTags({ commit }, deckId) {
    try {
      const { data } = await this.$api.flashcard.fetchFlattenTags(deckId);
      commit(types.SET_FLATTED_TAGS, data);
      return data;
    } catch {}
  },

  async fetchAllCards({ commit }, { deckId, params = {} }) {
    try {
      commit(types.IS_LOADING_MODE, true);
      const data = await this.$api.flashcard.fetchAllCards(deckId, params);
      commit(types.SET_DECK_CARDS, data);
      return data;
    } catch (err) {
    } finally {
      commit(types.IS_LOADING_MODE, false);
    }
  },

  async fetchSingleCard(_, { deckId, cardId }) {
    try {
      const { data } = await this.$api.flashcard.fetchSingleCard(deckId, cardId);
      return new CardEntity(data);
    } catch (err) {}
  },

  async fetchSingleCardHash(_, { deckId, cardId }) {
    try {
      const { data } = await this.$api.flashcard.fetchSingleCardHash(deckId, cardId);
      return data;
    } catch (err) {}
  },

  async updateDeck({ commit }, deckData) {
    const { data } = await this.$api.flashcard.updateDeck(deckData);
    commit(types.SET_DECK, data);
    return data;
  },

  async updateDeckThumbnail(_, data) {
    try {
      const response = await this.$api.flashcard.updateDeckThumbnail(data);
      return response?.data;
    } catch (err) {}
  },

  async updateDeckStudyDetails(
    { commit, getters },
    { deckId, dailyGoal, insertionType, suspendedTags, storeUpdate = true },
  ) {
    try {
      commit(types.IS_LOADING_MODE, true);
      const response = await this.$api.flashcard.updateDeckStudyDetails({
        deckId,
        dailyGoal,
        insertionType,
        suspendedTags,
      });

      const modifiedDeck = { ...getters.deck };
      if (storeUpdate) {
        if (dailyGoal) {
          modifiedDeck.daily_goal = dailyGoal;
        }
        if (suspendedTags) {
          modifiedDeck.suspended_tags = suspendedTags;
        }
        if (insertionType) {
          modifiedDeck.insertion_type = insertionType;
        }
        commit(types.SET_DECK, modifiedDeck);
      }

      commit(types.IS_LOADING_MODE, false);
      return response.data;
    } catch (err) {
      commit(types.IS_LOADING_MODE, false);
      throw err;
    }
  },

  async flagCard({ commit, getters }, { deckId, cardId, card = null }) {
    try {
      const cardBeforeUpdateFlag = card || getters.deckCards.find((_card) => _card.id === cardId);
      await this.$api.flashcard.flagCard(deckId, cardId, !cardBeforeUpdateFlag.is_flagged);
      if (!card) {
        commit(types.CHANGE_CARD_FLAGGED_STATUS, cardId);
      }
    } catch (err) {}
  },

  async suspendCard({ commit, getters }, { deckId, cardId, card = null, source }) {
    try {
      const cardInTheStore = getters.deckCards.find((_card) => _card.id === cardId);
      const cardBeforeUpdateSuspend = card || cardInTheStore;
      await this.$api.flashcard.suspendCard(deckId, cardId, !cardBeforeUpdateSuspend.is_suspended, source);
      if (cardBeforeUpdateSuspend.is_suspended) {
        // now is unsuspend
        this.$jwToast.success('Card Unsuspended.');
      } else {
        // now is suspended
        this.$jwToast.error('Card Suspended.', {
          icon: require('~/assets/icons/toast/suspend-error.svg'),
        });
      }
      if (cardInTheStore) {
        commit(types.CHANGE_CARD_SUSPENDED_STATUS, cardBeforeUpdateSuspend);
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateCard(_, { deckId, cardId, cardInfo }) {
    try {
      const { data } = await this.$api.flashcard.updateCard(deckId, cardId, cardInfo);
      const count = data.count;

      this.$jwToast.success(`${count} ${count > 1 ? 'cards' : 'card'} updated.`);
    } catch (err) {}
  },

  async deleteCard({ commit, getters }, { deckId, cardId }) {
    try {
      await this.$api.flashcard.deleteCard(deckId, cardId);
      commit(types.DELETE_CARD, cardId);
      commit(types.SET_DECK, {
        ...getters.deck,
        total_cards: getters.deck.totalCardsBasedOnPermission - 1,
      });
      this.$jwToast.success('Card Deleted');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) {}
  },

  async fetchNestedTags({ commit }, { deckId, params = {} }) {
    try {
      commit(types.IS_LOADING_MODE, true);
      const { data } = await this.$api.flashcard.fetchNestedTags(deckId, params);
      commit(types.SET_NESTED_TAGS, data);
      return data;
    } catch {
    } finally {
      commit(types.IS_LOADING_MODE, false);
    }
  },

  async suspendTag({ commit, getters }, { deckId, tagId }) {
    try {
      const tag = { ...getters.getTagById(tagId) };
      const { data } = await this.$api.flashcard.suspendTag(deckId, tagId, !tag.is_suspended);
      const hasUSerSuspendedNow = !tag.is_suspended;
      commit(types.UPDATE_BULK_TAGS, { ids: data, key: 'is_suspended', value: hasUSerSuspendedNow });
      if (tag.is_suspended) {
        this.$jwToast.success('Subdeck Unsuspended.');
      } else {
        this.$jwToast.error('Subdeck Suspended.', {
          icon: require('~/assets/icons/toast/suspend-error.svg'),
        });
      }
    } catch (err) {}
  },

  async activateTag({ commit, getters }, { deckId, tagId }) {
    try {
      const tag = { ...getters.getTagById(tagId) };
      const newPublishStatus = tag.publish_status === 'active' ? 'draft' : 'active';

      const { data } = await this.$api.flashcard.activateTag(deckId, tagId, newPublishStatus === 'active');

      commit(types.UPDATE_BULK_TAGS, { ids: data, key: 'publish_status', value: newPublishStatus });

      if (tag.publish_status === 'active') {
        this.$jwToast.success('Subdeck deactivated.');
      } else {
        this.$jwToast.success('Subdeck activated.');
      }
    } catch (err) {}
  },

  addEmptyCardForCreating({ commit }) {
    commit(types.ADD_EMPTY_NEW_CARD);
    commit(types.SET_NEW_CARD_MODE, true);
  },

  resetNewCardMode({ commit }) {
    commit(types.REMOVE_CREATE_CARD);
    commit(types.SET_NEW_CARD_MODE, false);
  },

  async createCard({ commit, getters }, { deckId, data }) {
    data.is_copyable = data.is_copyable || 'normal';

    const response = await this.$api.flashcard.createCard(deckId, data);
    const count = response.data.count;

    commit(types.SET_NEW_CARD_MODE, false);
    commit(types.SET_DECK, { ...getters.deck, total_cards: getters.deck.totalCardsBasedOnPermission + count });

    this.$jwToast.success(`${count} ${count > 1 ? 'cards' : 'card'} created.`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return response.data;
  },

  async extendStudySession(_, { numberOfCards, deckId }) {
    return await this.$api.flashcard.extendStudySession({ numberOfCards, deckId });
  },

  async createTag({ dispatch, getters, commit }, { deckId, form }) {
    try {
      await this.$api.flashcard.createTag(deckId, form);
      dispatch('fetchNestedTags', { deckId });
      dispatch('fetchFlattenTags', deckId);
      commit(types.SET_DECK, {
        ...getters.deck,
        total_tags: getters.deck.total_tags + 1,
      });
      this.$jwToast.success('Tag created successfully.');
    } catch (err) {}
  },

  async updateTag({ dispatch }, { deckId, tagId, data }) {
    const response = await this.$api.flashcard.updateTag(deckId, tagId, data);
    dispatch('fetchFlattenTags', deckId);
    return response?.data;
  },

  updateTagInfo({ commit }, { id, key, value }) {
    commit(types.UPDATE_TAG, { id, key, value });
  },

  async fetchRoles() {
    try {
      const { data } = await this.$api.flashcard.fetchRoles();
      return data;
    } catch (err) {}
  },

  async addTeamMember(_, data) {
    return await this.$api.flashcard.addTeamMember(data);
  },

  async deleteTeamMember(_, data) {
    return await this.$api.flashcard.deleteTeamMember(data);
  },

  async updateMemberRole(_, { deckId, teamId, roleId }) {
    try {
      await this.$api.flashcard.updateMemberRole(deckId, teamId, roleId);
    } catch (err) {}
  },

  async resetDeck(_, deckId) {
    try {
      await this.$api.flashcard.resetDeck(deckId);
      this.$jwToast.success('Deck Reset.');
    } catch (err) {}
  },

  async deleteDeck(_, deckId) {
    try {
      await this.$api.flashcard.deleteDeck(deckId);
      this.$jwToast.success('Deck Deleted.');
    } catch (err) {}
  },

  async fetchDeckStatistics(_, deckId) {
    try {
      const { data } = await this.$api.flashcard.fetchDeckStatistics(deckId);
      return data;
    } catch {}
  },

  async fetchTeam(_, deckId) {
    try {
      const { data } = await this.$api.flashcard.fetchTeam(deckId);
      return data;
    } catch (err) {}
  },

  async importDeck({ commit }, { formData, source, callback }) {
    try {
      commit(types.IS_IMPORT_MODE, true);
      await this.$api.flashcard.importDeck(formData, source, callback);
      commit(types.IS_IMPORT_MODE, false);
    } catch (err) {
      commit(types.IS_IMPORT_MODE, false);
      throw err;
    }
  },

  async createDeck({ commit }, { title, description }) {
    try {
      commit(types.IS_IMPORT_MODE, true);
      const response = await this.$api.flashcard.createDeck(title, description);
      commit(types.IS_IMPORT_MODE, false);
      return response;
    } catch (err) {
      commit(types.IS_IMPORT_MODE, false);
      throw err;
    }
  },

  async setStudyAnswer(_, { deckId, cardId, difficulty, shouldOverride = false }) {
    const { data } = await this.$api.flashcard.setStudyAnswer(deckId, cardId, difficulty, shouldOverride);
    return data;
  },

  async fetchStudyStatistics(_, deckId) {
    const { data } = await this.$api.flashcard.fetchStudyStatistics(deckId);
    return data;
  },

  updateCardModel({ commit }, payload) {
    commit(types.UPDATE_CARD_MODEL, payload);
  },

  async getDeckStudySessionCards(_, { deckId }) {
    const { data } = await this.$api.flashcard.getDeckStudySessionCards(deckId);
    return data;
  },

  async getSubdeckStudySessionCards(_, { deckId, subdeckId }) {
    const { data } = await this.$api.flashcard.getSubdeckStudySessionCards(deckId, subdeckId);
    return data;
  },

  async fetchAvailableNewCards(_, payload) {
    const { data } = await this.$api.flashcard.fetchAvailableNewCards(payload);
    return data;
  },

  cardFilterObject({ commit }, payload) {
    commit(types.SET_CARD_FILTER_OBJECT, payload);
  },

  setActiveCardModel({ commit }, payload) {
    commit(types.SET_ACTIVE_CARD_MODEL, payload);
  },
};
