import { TRUSTED_SITES_LOCAL_STORAGE_KEY } from '~/core/utils/constants';

export default () => ({
  isLoginRequired: false,
  meta: {},
  trustedSites: JSON.parse(localStorage.getItem(TRUSTED_SITES_LOCAL_STORAGE_KEY)) || [],
  cookieConfiguration: {
    accessCookie: null,
    defaultCookie: null,
  },
});
