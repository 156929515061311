export default ($axios) => ({
  addUserToWaittingList(courseId, headers) {
    return $axios.post(
      `/api/caap/courses/${courseId}/add-to-waiting`,
      {},
      {
        headers,
      },
    );
  },

  removeUserToWaittingList(courseId, params, headers) {
    return $axios.delete(
      `/api/caap/courses/${courseId}/remove-from-waiting`,
      {
        params,
      },
      {
        headers,
      },
    );
  },

  fetchCourses(source) {
    return $axios.get('/api/v3/caap/courses', {
      cancelToken: source?.token,
    });
  },

  fetchServices() {
    return $axios.get('/api/caap/courses?type=service');
  },

  fetchTestimonials() {
    return $axios.get('/api/caap/testimonials');
  },

  fetchCourseClasses(slug) {
    return $axios.get(`/api/caap/courses/${slug}/classes`);
  },

  fetchCourseTiers(params, source) {
    return $axios.get('/api/caap/tiers', {
      params,
      cancelToken: source?.token,
    });
  },

  fetchCourseViaContent(slug) {
    return $axios.get(`/api/caap/courses/${slug}/learning`);
  },

  fetchCourseForPreview(slug) {
    return $axios.get(`/api/caap/courses/${slug}/preview`);
  },

  fetchPublishedCourseForPublic(slug) {
    return $axios.get(`/api/caap/courses/${slug}/landing`);
  },

  fetchCourseViaContentForPreview(slug) {
    return $axios.get(`/api/caap/courses/${slug}/preview`);
  },

  fetchCourseLessons(params = {}) {
    const {
      course_id,
      study_level = undefined,
      weekly_non_completed_lessons = undefined,
      sort = 'global_order_column',
      sort_by = 'ASC',
    } = params;

    return $axios.get(`/api/v1/caap/courses/${course_id}/lessons`, {
      params: {
        study_level,
        weekly_non_completed_lessons,
        sort,
        sort_by,
      },
    });
  },

  fetchCourseTopics(access) {
    return $axios.get(`/api/topics?access=${access}`);
  },

  setStudyPlan(params) {
    return $axios.put('/api/caap/study_plan/setup', params);
  },

  setStudyContinue(params) {
    return $axios.put('/api/caap/study_plan/continue', params);
  },

  disableStudyPlan(enrollment_id) {
    return $axios.put(`/api/caap/enrollments/${enrollment_id}/study_plan/disable`);
  },

  getSendyStatus(sendy_name) {
    return $axios.get(`api/caap/user/sendy/status?sendy_list_name=${sendy_name}`);
  },

  subscribeToSendy(sendy_name, headers) {
    return $axios.post(
      '/api/caap/user/sendy/subscribe',
      {
        sendy_list_name: sendy_name,
      },
      {
        headers,
      },
    );
  },

  fetchDailyBySlug(slug) {
    return $axios.get(`/api/v3/daily/dailies/${slug}`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  },

  subscribeMarketingSendy(params, headers) {
    return $axios.post(
      '/api/v1/marketing/sendy/subscribe',
      {
        sendy_list_names: params.sendy_names,
        email: params.email,
        'g-recaptcha-response': params.recaptchaCode,
      },
      {
        headers,
      },
    );
  },

  fetchFields() {
    return $axios.get('/api/v1/caap/fields');
  },

  setLessonHistory(lessonId, params) {
    return $axios.post(`/api/caap/lessons/${lessonId}/history`, params);
  },

  fetchClassifyAssignments(params) {
    return $axios.get(`/api/v1/caap/courses/${params.courseId}/classify-assignments?over_due=${params.overdue}`);
  },
});
