import types from './types';

export default {
  async fetchAllProjects(_, { search, order, sort }) {
    try {
      const { data } = await this.$api.radar.fetchAllProjects(search, order, sort);
      return data;
    } catch {}
  },

  async fetchProject({ commit }, { id, code }) {
    try {
      const { data } = await this.$api.radar.fetchProject(id, code);
      commit(types.SET_USER_PERMISSIONS, data.permissions);
      return data;
    } catch {}
  },

  async fetchQRCodes(_, pageId) {
    try {
      const { data } = await this.$api.radar.fetchQRCodes(pageId);
      return data;
    } catch {}
  },

  async generateQRCode(_, pageId) {
    try {
      const { data } = await this.$api.radar.generateQRCode(pageId);
      return data;
    } catch {
      return false;
    }
  },

  async fetchAttributeKeys() {
    try {
      const { data } = await this.$api.radar.fetchAttributeKeys();
      return data;
    } catch {}
  },

  async fetchAttributeValueList(_, qrcodeId) {
    try {
      const { data } = await this.$api.radar.fetchAttributeValueList(qrcodeId);
      return data;
    } catch {}
  },

  async removeAttributeValue(_, attribute) {
    try {
      const { data } = await this.$api.radar.removeAttributeValue(attribute);
      return data;
    } catch {}
  },

  async addAttributeValue(_, attribute) {
    try {
      const { data } = await this.$api.radar.addAttributeValue(attribute);
      return data;
    } catch {}
  },

  async updateAttributeValue(_, attribute) {
    try {
      const { data } = await this.$api.radar.updateAttributeValue(attribute);
      return data;
    } catch {}
  },

  async fetchSessions({ dispatch }, pageId) {
    try {
      const { data } = await this.$api.radar.fetchSessions(pageId);
      dispatch('session/setSessions', data, { root: true });
      return data;
    } catch {}
  },

  async createPage(_, form) {
    const { data } = await this.$api.radar.createPage(form);
    return data;
  },

  async updatePage(_, { form, pageId }) {
    const { data } = await this.$api.radar.updatePage(form, pageId);
    return data;
  },
};
