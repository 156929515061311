/* eslint-disable */
// Calculate xpath from root element to selected element
const xpath = {
  getPath(from, root) {
    let path = '';
    let current = from;

    while (current && current != root) {
      const selector = current.nodeType == 1 ? current.tagName.toLowerCase() : 'text()';
      path = '/' + selector + '[' + this.getIndex(current) + ']' + path;
      current = current.parentNode;
    }

    path = '.' + path;
    return path;
  },
  getIndex(el) {
    let count = 1;
    let current = el.previousSibling;
    while (current) {
      if (current.tagName == el.tagName) {
        count += 1;
      }
      current = current.previousSibling;
    }
    return count;
  },
};

// Used to convert range from range to xpath object
export default function (base) {
  return {
    getBase() {
      return base instanceof HTMLElement ? base : document.querySelector(base);
    },
    toRange(obj) {
      const root = this.getBase();
      const range = {
        startContainer: document.evaluate(obj.start, root, null, XPathResult.ANY_TYPE, null).iterateNext(),
        startOffset: obj.startOffset,
        endContainer: document.evaluate(obj.end, root, null, XPathResult.ANY_TYPE, null).iterateNext(),
        endOffset: obj.endOffset,
        commonAncestorContainer: document
          .evaluate(obj.commonAncestorContainer, root, null, XPathResult.ANY_TYPE, null)
          .iterateNext(),
      };

      if (range.startContainer === null || range.endContainer === null || range.commonAncestorContainer === null) {
        return null;
      }

      return range;
    },
    toObject(rng) {
      const root = this.getBase();

      const object = {
        start: xpath.getPath(rng.startContainer, root),
        startOffset: rng.startOffset,
        end: xpath.getPath(rng.endContainer, root),
        endOffset: rng.endOffset,
        commonAncestorContainer: xpath.getPath(rng.commonAncestorContainer, root),
      };

      if (object.start === null || object.end === null || object.commonAncestorContainer === null) {
        debugger;
        return null;
      }
      return object;
    },
  };
}
