export default {
  SET_DAILY_PASSAGES: 'SET_DAILY_PASSAGES',
  SET_DAILY_PASSAGES_META: 'SET_DAILY_PASSAGES_META',
  SET_DAILY_PASSAGES_LOADING: 'SET_DAILY_PASSAGES_LOADING',
  SET_DAILY_SECTIONS: 'SET_DAILY_SECTIONS',
  SET_TODAY_PASSAGES: 'SET_TODAY_PASSAGES',
  SET_TODAY_PASSAGES_LOADING: 'SET_TODAY_PASSAGES_LOADING',
  SET_PASSAGES_STREAK: 'SET_PASSAGES_STREAK',
  RESET_DAILY_PASSAGE: 'RESET_DAILY_PASSAGE',
  SET_SUBSCRIBE_BOX_STATUS: 'SET_SUBSCRIBE_BOX_STATUS',
};
