import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faUserShield } from '@fortawesome/free-solid-svg-icons/faUserShield';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons/faSortAmountUp';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons/faArrowsAltH';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faHeadphonesAlt } from '@fortawesome/free-solid-svg-icons/faHeadphonesAlt';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons/faCalendarWeek';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons/faAlignJustify';

const solidIcons = [
  faFlag,
  faArrowRight,
  faMoneyBill,
  faBell,
  faMapMarkerAlt,
  faCalendarWeek,
  faInfo,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faComments,
  faHeadphonesAlt,
  faPercent,
  faCloudDownloadAlt,
  faQuestionCircle,
  faExpand,
  faArrowsAltH,
  faArrowCircleRight,
  faBookOpen,
  faSortAmountDown,
  faPlus,
  faBolt,
  faSortAmountUp,
  faMinus,
  faEdit,
  faSave,
  faTimesCircle,
  faMinusCircle,
  faPlay,
  faInfoCircle,
  faTrash,
  faCreditCard,
  faExternalLinkAlt,
  faSpinner,
  faCopy,
  faSignOutAlt,
  faArrowLeft,
  faUserShield,
  faCog,
  faChevronCircleRight,
  faCheckCircle,
  faLink,
  faClock,
  faAngleDown,
  faAngleUp,
  faFileAlt,
  faPlayCircle,
  faCheck,
  faTimes,
  faShare,
  faAlignJustify,
];

export default solidIcons;
