
import { randomFixedInteger } from '~/core/utils/numbers';

export default {
  inheritAttrs: false,
  props: {
    error: {
      type: [String, null],
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: [String, Number],
      default: () => `input-${randomFixedInteger()}`,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    focus() {
      this.$refs?.inputElement?.focus();
    },
  },
};
